import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Badge, Button } from 'react-bootstrap';
import { useGetCardQuery } from '../../graphql/generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Skeleton from '../layout/Skeleton';


const CardPageView: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { loading, error, data } = useGetCardQuery({
        variables: { id: id || '' },
    });

    const handleBack = () => {
        if (window.history.length > 2) {
            navigate(-1); // Go back if there's a previous page in history
        } else {
            navigate('/'); // Go to home page if there's no previous page
        }
    };

    if (loading) {
        return (
            <Container className="mt-4">
                <Row>
                    <Col md={5}>
                        <Skeleton height="400px" className="mb-4" />
                    </Col>
                    <Col md={7}>
                        <Skeleton height="32px" className="mb-2" />
                        <Skeleton height="24px" width="70%" className="mb-3" />
                        <Row className="mb-4">
                            {[...Array(6)].map((_, index) => (
                                <Col key={index} xs={6} md={4} className="mb-3">
                                    <Skeleton height="20px" />
                                </Col>
                            ))}
                        </Row>
                        <Skeleton height="20px" className="mb-2" />
                        <Skeleton height="20px" className="mb-2" />
                        <Skeleton height="20px" width="80%" className="mb-4" />
                        <Skeleton height="100px" className="mb-4" />
                    </Col>
                </Row>
            </Container>
        );
    }
    if (error) return <div className="alert alert-danger mt-5" role="alert">Error: {error.message}</div>;

    const card = data?.card;

    if (!card) return <div className="alert alert-warning mt-5" role="alert">Card not found</div>;

    return (
        <Container className="mt-5">
            <Button variant="outline-primary" className="mb-4" onClick={handleBack}>
                <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Back
            </Button>
            <Row>
                <Col md={5}>
                    <Card className="mb-4 shadow-sm hover-card">
                        <Card.Img
                            variant="top"
                            src={card.images.front.url || '/images/cards/placeholder.png'}
                            alt={card.name}
                            onError={(e) => {
                                e.currentTarget.src = '/images/cards/placeholder.png';
                            }}
                            className="img-fluid card-view-image"
                        />
                    </Card>
                </Col>
                <Col md={7}>
                    <Card className="mb-4 shadow-sm hover-card">
                        <div className="card-content">
                            <h1 className="card-title">{card.name}</h1>
                            {card.subtitle && <h3 className="card-subtitle">{card.subtitle}</h3>}
                            <Row className="mb-4">
                                <Col xs={6} md={4} className="mb-3">
                                    <strong>Type:</strong> <Badge bg="secondary">{card.cardType}</Badge>
                                </Col>
                                <Col xs={6} md={4} className="mb-3">
                                    <strong>Rarity:</strong> <Badge bg="info">{card.rarity}</Badge>
                                </Col>
                                <Col xs={6} md={4} className="mb-3">
                                    <strong>Cost:</strong> <Badge bg="primary">{card.cost}</Badge>
                                </Col>
                                {card.power && (
                                    <Col xs={6} md={4} className="mb-3">
                                        <strong>Power:</strong> <Badge bg="danger">{card.power}</Badge>
                                    </Col>
                                )}
                                {card.hp && (
                                    <Col xs={6} md={4} className="mb-3">
                                        <strong>HP:</strong> <Badge bg="success">{card.hp}</Badge>
                                    </Col>
                                )}
                                <Col xs={6} md={4} className="mb-3">
                                    <strong>Arena:</strong> <Badge bg="warning" text="dark">{card.arena}</Badge>
                                </Col>
                            </Row>
                            <div className="mb-4">
                                <strong>Aspects:</strong><br />
                                {card.aspects?.map((aspect, index) => (
                                    <Badge key={index} bg="secondary" className="me-2 mb-2">{aspect}</Badge>
                                )) || 'None'}
                            </div>
                            <div className="mb-4">
                                <strong>Traits:</strong><br />
                                {card.traits?.map((trait, index) => (
                                    <Badge key={index} bg="light" text="dark" className="me-2 mb-2">{trait}</Badge>
                                )) || 'None'}
                            </div>
                            <Card className="mb-4 card-text-box">
                                <Card.Body>
                                    <Card.Title>Card Text</Card.Title>
                                    <Card.Text>{card.frontText || 'None'}</Card.Text>
                                </Card.Body>
                            </Card>
                            <div>
                                <strong>Unique:</strong> {card.unique ? 'Yes' : 'No'}
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CardPageView;
