import React from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface MissingBadgeProps {
    count: number;
    className?: string;
}

const MissingBadge: React.FC<MissingBadgeProps> = ({ count, className = '' }) => {
    if (count <= 0) return null;

    return (
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={'missing'}>Missing</Tooltip>}
        >
            <Badge
                bg="missing"
                className={`missing-count ${className}`}
            >
                {count}
            </Badge>
        </OverlayTrigger>
    );
};

export default MissingBadge;
