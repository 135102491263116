import { useMemo, useState, useCallback } from 'react';
import { AspectTypeEnum, Card, RarityTypeEnum } from '../graphql/generated/graphql';
import { FilterOptions, FilterValue } from '../types/cardFilters';

type FilterFunction = (card: Card, filters: FilterOptions) => boolean;

const defaultFilters: FilterOptions = {
    searchText: '',
    selectedRarities: [],
    selectedCosts: [],
    selectedAspects: [],
    matchAllAspects: false,
};

export const useCardFilters = (cards: Card[], customFilters: FilterFunction[] = []) => {
    const [filters, setFilters] = useState<FilterOptions>(defaultFilters);

    const textFilter = useCallback((card: Card, searchText: string) => {
        const lowercaseText = searchText.toLowerCase();
        return !searchText ||
            card.name.toLowerCase().includes(lowercaseText) ||
            card.standardCardNumber?.toLowerCase().includes(lowercaseText) ||
            card.hyperspaceCardNumber?.toLowerCase().includes(lowercaseText) ||
            card.subtitle?.toLowerCase().includes(lowercaseText);
    }, []);

    const rarityFilter = useCallback((card: Card, selectedRarities: RarityTypeEnum[]) => {
        return selectedRarities.length === 0 || selectedRarities.includes(card.rarity);
    }, []);

    const costFilter = useCallback((card: Card, selectedCosts: number[]) => {
        return selectedCosts.length === 0 || selectedCosts.includes(card.cost);
    }, []);

    const aspectFilter = useCallback((card: Card, selectedAspects: AspectTypeEnum[], matchAllAspects: boolean) => {
        return selectedAspects.length === 0 || (
            matchAllAspects
                ? selectedAspects.every(aspect => card.aspects?.includes(aspect))
                : selectedAspects.some(aspect => card.aspects?.includes(aspect))
        );
    }, []);

    const filteredCards = useMemo(() => {
        return cards.filter(card => {
            const baseFiltersPass =
                textFilter(card, filters.searchText) &&
                rarityFilter(card, filters.selectedRarities) &&
                costFilter(card, filters.selectedCosts) &&
                aspectFilter(card, filters.selectedAspects, filters.matchAllAspects);

            return baseFiltersPass && customFilters.every(filter => filter(card, filters));
        });
    }, [cards, filters, textFilter, rarityFilter, costFilter, aspectFilter, customFilters]);

    const updateFilter = useCallback((key: keyof FilterOptions, value: FilterValue): void => {
        setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
    }, []);

    const calculateRarities = useMemo(() => {
        return Array.from(new Set(cards.map(card => card.rarity))).filter(Boolean) as RarityTypeEnum[];
    }, [cards]);

    const calculateCosts = useMemo(() => {
        return Array.from(new Set(cards.map(card => card.cost))).sort((a, b) => a - b);
    }, [cards]);

    return {
        filteredCards,
        filters,
        updateFilter,
        rarities: calculateRarities,
        costs: calculateCosts
    };
};
