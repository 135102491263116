import React from 'react';
import { AspectTypeEnum } from '../../graphql/generated/graphql';
import { useAspects } from "../../hooks/useAspects";
import { getFormattedImageUrl } from '../../utils/imageUtils';

interface AspectFilterProps {
    selected: AspectTypeEnum[];
    matchAll: boolean;
    onChange: (selected: AspectTypeEnum[], matchAll: boolean) => void;
    disabled?: boolean;
}

const AspectFilter: React.FC<AspectFilterProps> = ({ selected, matchAll, onChange, disabled }) => {
    const { aspects, loading: aspectsLoading } = useAspects();

    const handleAspectToggle = (aspectType: AspectTypeEnum) => {
        const newSelected = selected.includes(aspectType)
            ? selected.filter(a => a !== aspectType)
            : [...selected, aspectType];
        onChange(newSelected, matchAll);
    };

    const handleMatchAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(selected, event.target.checked);
    };

    return (
        <div className="card-filters__section aspect-filter">
            <label className="card-filters__section-title">Aspects</label>
            <div className="aspect-filter__grid">
                {aspects.map(aspect => (
                    <button
                        key={aspect.value}
                        onClick={() => handleAspectToggle(aspect.value as AspectTypeEnum)}
                        disabled={disabled || aspectsLoading}
                        className={`aspect-filter__button ${selected.includes(aspect.value as AspectTypeEnum) ? 'aspect-filter__button--selected' : ''}`}
                        title={aspect.label}
                    >
                        <img
                            src={getFormattedImageUrl(aspect.image, 40)}
                            alt={aspect.label}
                            className="aspect-filter__image"
                        />
                    </button>
                ))}
            </div>
            <div className="card-filters__checkbox">
                <input
                    type="checkbox"
                    id="matchAllAspects"
                    checked={matchAll}
                    onChange={handleMatchAllChange}
                    disabled={disabled}
                />
                <label htmlFor="matchAllAspects">Match all selected aspects</label>
            </div>
        </div>
    );
};

export default AspectFilter;
