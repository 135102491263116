import React from 'react';

interface SkeletonProps {
    width?: string;
    height?: string;
    className?: string;
}

const Skeleton: React.FC<SkeletonProps> = ({ width = '100%', height = '20px', className = '' }) => (
    <div
        className={`skeleton-loading ${className}`}
        style={{
            width,
            height,
            backgroundColor: '#2a2a2a',
            borderRadius: '4px',
            animation: 'skeleton-loading 1s linear infinite alternate'
        }}
    />
);

export default Skeleton;
