import React, { useState, useRef, useEffect, useMemo } from 'react';
import { CardImage as CardImageType, OrientationTypeEnum } from '../../graphql/generated/graphql';
import { getFormattedImageUrl } from '../../utils/imageUtils';

interface CardImageProps {
    image: CardImageType | null | undefined;
    name: string;
}

const CardImage: React.FC<CardImageProps> = ({ image, name }) => {
    const [imageError, setImageError] = useState(false);
    const [width, setWidth] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.offsetWidth);
            }
        };

        const resizeObserver = new ResizeObserver(updateWidth);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => resizeObserver.disconnect();
    }, []);

    const imageUrl = useMemo(() => {
        if (!image) return null;
        const url = image.url;
        if (!url) return null;

        return getFormattedImageUrl(url, width);
    }, [image, width]);

    const isLandscape = image?.orientation === OrientationTypeEnum.Landscape;
    const containerClass = isLandscape ? 'landscape' : 'portrait';

    const containerClasses = `card-image-container ${containerClass} mobile-friendly`;

    return (
        <div ref={containerRef} className={containerClasses}>
            {!imageUrl || imageError ? (
                <div className="card-image-placeholder">
                    <span>{name}</span>
                </div>
            ) : (
                <img
                    src={imageUrl}
                    alt={name}
                    onError={() => setImageError(true)}
                    className="card-image"
                    loading="lazy"
                />
            )}
        </div>
    );
};

export default CardImage;
