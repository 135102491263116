import React from "react"
import { Routes, Route } from "react-router-dom";
import Home from "../components/Home";
import CardSets from "../components/card_set/CardSets";
import CardSetView from "../components/card_set/CardSetView";
import CardPageView from "../components/card/CardPageView";
import TradingHub from "../components/TradingHub";
import UserProfile from "../components/profile/UserProfile";
import Login from "../components/auth/Login";
import ProtectedRoute from "../components/auth/ProtectedRoute";
import CollectionView from "../components/collection/CollectionView";
import WantList from "../components/collection/wanted/WantList";
import NotFound from "../components/layout/NotFound";
import Collections from "../components/collection/SharedCollections";
import SaleList from "../components/collection/sale/SaleList";
import Users from "../components/admin/user/Users";
import AdminLayout from "../components/admin/AdminLayout";
import AdminDashboard from "../components/admin/AdminDashboard";
import ContactUs from "../components/ContactUs";
import UserEdit from "../components/admin/user/UserEdit";
import OfferDetails from '../components/trade/OfferDetails';


const AppRoutes = () => (
    <Routes>
        <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="users" element={<Users />} />
            <Route path="users/:id/edit" element={<UserEdit />} />
        </Route>

        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/card-sets" element={<CardSets />} />
        <Route path="/card-sets/:cardSetCode" element={<CardSetView />} />
        <Route path="/cards/:id" element={<CardPageView />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/collections/:handle" element={<CollectionView />} />
        <Route path="/collections/:handle/want" element={<WantList />} />
        <Route path="/collections/:handle/sell" element={<SaleList />} />
        <Route path="/trading" element={<TradingHub />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route element={<ProtectedRoute />}>
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/collections/my" element={<CollectionView />} />
            <Route path="/collections/my/edit" element={<CollectionView />} />
            <Route path="/collections/my/want" element={<WantList />} />
            <Route path="/collections/my/want/edit" element={<WantList />} />
            <Route path="/collections/my/sell" element={<SaleList />} />
            <Route path="/collections/my/sell/edit" element={<SaleList />} />
            <Route path="/trading/offers/:offerId" element={<OfferDetails />} />
        </Route>
        <Route path="*" element={<NotFound />} />
    </Routes>
);

export default AppRoutes;
