export const fetchCSRFToken = async (): Promise<string> => {
    const response = await fetch('/api/csrf_token', { credentials: 'include' });
    const data: { csrf_token: string } = await response.json();
    return data.csrf_token;
};

export const initiateGoogleOAuth = async (from: string = '/') => {
    try {
        const csrfToken = await fetchCSRFToken();
        const response = await fetch('/auth/google_oauth2', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            credentials: 'include',
            body: JSON.stringify({ redirect_to: from })
        });
        if (response.ok) {
            const data = await response.json();
            if (data.redirect_url) {
                window.location.href = data.redirect_url;
            } else {
                throw new Error('No redirect URL received');
            }
        } else {
            throw new Error('Failed to initiate OAuth');
        }
    } catch (error) {
        console.error('Error initiating Google OAuth:', error);
        throw error;
    }
};
