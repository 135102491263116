import React, { useState, useMemo } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faDollarSign, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useCreateOfferMutation, CardVariantTypeEnum, OfferTypeEnum } from '../../graphql/generated/graphql';
import { getCardVariantTypeDisplay } from '../../utils/cardUtils';

interface CreateBuyOfferModalProps {
    show: boolean;
    onHide: () => void;
    recipientHandle: string;
    saleListItems: Array<{
        id: string;
        cardDetails: {
            name: string;
            standardFullCardNumber: string;
        };
        variants: Array<{
            variantId: string;
            variantType: string;
            priceInCents: number;
            quantity: number;
        }>;
    }>;
}

interface SelectedItem {
    cardId: string;
    cardName: string;
    cardNumber: string;
    variantId: string;
    variantType: CardVariantTypeEnum;
    quantity: number;
    priceInCents: number;
    maxQuantity: number;
}

const CreateBuyOfferModal: React.FC<CreateBuyOfferModalProps> = ({
    show,
    onHide,
    recipientHandle,
    saleListItems
}) => {
    const [createOffer] = useCreateOfferMutation();
    const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
    const [note, setNote] = useState<string>('');
    const [errors, setErrors] = useState<string[]>([]);

    const totalPrice = useMemo(() => {
        return selectedItems.reduce((sum, item) => sum + (item.priceInCents * item.quantity) / 100, 0);
    }, [selectedItems]);

    const handleAddItem = (
        cardId: string,
        cardName: string,
        cardNumber: string,
        variantId: string,
        variantType: CardVariantTypeEnum,
        priceInCents: number,
        maxQuantity: number
    ) => {
        const existingItem = selectedItems.find(item => item.variantId === variantId);
        if (existingItem) return;

        setSelectedItems([...selectedItems, {
            cardId,
            cardName,
            cardNumber,
            variantId,
            variantType,
            quantity: 1,
            priceInCents,
            maxQuantity
        }]);
    };

    const handleRemoveItem = (variantId: string) => {
        setSelectedItems(selectedItems.filter(item => item.variantId !== variantId));
    };

    const handleQuantityChange = (variantId: string, quantity: number) => {
        setSelectedItems(selectedItems.map(item => {
            if (item.variantId === variantId) {
                return { ...item, quantity: Math.min(quantity, item.maxQuantity) };
            }
            return item;
        }));
    };

    const handleSubmit = async () => {
        if (selectedItems.length === 0) {
            setErrors(['Please select at least one card']);
            return;
        }

        try {
            await createOffer({
                variables: {
                    input: {
                        recipientHandle,
                        offerType: OfferTypeEnum.Buy,
                        offerItems: selectedItems.map(item => ({
                            variantId: item.variantId,
                            quantity: item.quantity,
                            priceInCents: item.priceInCents
                        })),
                        note: note.trim() || undefined
                    }
                }
            });
            onHide();
            setSelectedItems([]);
            setNote('');
            setErrors([]);
        } catch (error) {
            setErrors(['Failed to create offer. Please try again.']);
            console.error('Error creating offer:', error);
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg" centered className="offer-modal">
            <Modal.Header closeButton>
                <Modal.Title className="text-space-gold">Create Buy Offer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex gap-4">
                    {/* Left Side - Available Cards */}
                    <div className="flex-1">
                        <h5 className="text-space-blue mb-3">Available Cards</h5>
                        <div className="scrollable-section">
                            {saleListItems.map(card => (
                                <div key={card.id} className="available-offer-card">
                                    <div className="available-offer-card-header">
                                        <span className="card-number">{card.cardDetails.standardFullCardNumber}</span>
                                        <h6 className="card-name">{card.cardDetails.name}</h6>
                                    </div>
                                    <div className="variant-counts d-flex flex-column gap-1">
                                        {card.variants.map(variant => (
                                            <div key={variant.variantId}
                                                className={`available-offer-card-variant ${selectedItems.some(item => item.variantId === variant.variantId) ? 'opacity-50' : ''}`}
                                            >
                                                <div className="variant-header">
                                                    <span className="variant-type">
                                                        {getCardVariantTypeDisplay(variant.variantType as CardVariantTypeEnum)}
                                                        {' - '}${(variant.priceInCents / 100).toFixed(2)}
                                                        {' ('}x{variant.quantity}{')'}
                                                    </span>
                                                    {!selectedItems.some(item => item.variantId === variant.variantId) && (
                                                        <Button
                                                            variant="outline-space-blue"
                                                            size="sm"
                                                            onClick={() => handleAddItem(
                                                                card.id,
                                                                card.cardDetails.name,
                                                                card.cardDetails.standardFullCardNumber,
                                                                variant.variantId,
                                                                variant.variantType as CardVariantTypeEnum,
                                                                variant.priceInCents,
                                                                variant.quantity
                                                            )}
                                                            className="btn-icon"
                                                        >
                                                            <FontAwesomeIcon icon={faArrowRight} />
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="border-end border-secondary-subtle"></div>

                    {/* Right Side - Selected Cards */}
                    <div className="flex-1">
                        <h5 className="text-space-blue mb-3">Selected Cards</h5>
                        <div className="scrollable-section right-section">
                            {selectedItems.map((item) => (
                                <div key={item.variantId} className="offer-card">
                                    <div className="offer-card-header">
                                        <span className="card-number">{item.cardNumber}</span>
                                        <h6 className="card-name">{item.cardName}</h6>
                                    </div>
                                    <div className="offer-card-variant">
                                        <div className="variant-controls">
                                            <div className="variant-detail-controls">
                                                <Form.Control
                                                    type="text"
                                                    value={getCardVariantTypeDisplay(item.variantType)}
                                                    readOnly
                                                    className="variant-type-input"
                                                />

                                                {item.maxQuantity === 1 ? (
                                                    <Form.Control
                                                        type="text"
                                                        value="1"
                                                        readOnly
                                                        className="quantity-input"
                                                    />
                                                ) : (
                                                    <Form.Select
                                                        value={item.quantity}
                                                        onChange={(e) => handleQuantityChange(item.variantId, parseInt(e.target.value))}
                                                        className="quantity-input"
                                                    >
                                                        {[...Array(item.maxQuantity)].map((_, i) => (
                                                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                                                        ))}
                                                    </Form.Select>
                                                )}

                                                <div className="price-input">
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faDollarSign} />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            value={(item.priceInCents / 100).toFixed(2)}
                                                            readOnly
                                                            className="form-control"
                                                        />
                                                    </InputGroup>
                                                </div>

                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    className="btn-icon"
                                                    onClick={() => handleRemoveItem(item.variantId)}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-column align-items-stretch">
                <Form.Group className="mb-3 w-100">
                    <Form.Label className="text-space-light-blue">Note</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Add a note to the offer..."
                        className="form-control textarea"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </Form.Group>

                {errors.length > 0 && (
                    <div className="alert alert-danger">
                        {errors.map((error, index) => (
                            <div key={index}>{error}</div>
                        ))}
                    </div>
                )}

                <div className="d-flex justify-content-between align-items-center">
                    <div className="text-space-blue">Total: ${totalPrice.toFixed(2)}</div>
                    <Button
                        variant="outline-space-blue"
                        onClick={handleSubmit}
                        disabled={selectedItems.length === 0}
                    >
                        Create Offer
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateBuyOfferModal;
