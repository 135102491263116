import React from 'react';
import { Button } from 'react-bootstrap';
import { initiateGoogleOAuth } from '../../utils/auth';

interface LoginButtonProps {
    from?: string;
}

const LoginButton: React.FC<LoginButtonProps> = ({ from = '/' }) => {
    const handleLogin = async () => {
        try {
            await initiateGoogleOAuth(from);
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    return (
        <Button onClick={handleLogin} variant="primary" className="w-full max-w-xs">
            Login with Google
        </Button>
    );
};

export default LoginButton;
