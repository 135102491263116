import React from 'react';
import { useGetCardSetsQuery, CardSetCodeEnum } from '../../../graphql/generated/graphql';
import CardSetTabs from '../../card_set/CardSetTabs';

interface CardSetSelectorProps {
    selectedSet: CardSetCodeEnum;
    onSelectSet: (set: CardSetCodeEnum) => void;
}

const CardSetSelector: React.FC<CardSetSelectorProps> = ({ selectedSet, onSelectSet }) => {
    const { data: setsData, loading: setsLoading } = useGetCardSetsQuery();

    const cardSets = setsData?.cardSets?.nodes
        ?.filter((set): set is NonNullable<typeof set> => set !== null && set.code !== null && set.name !== null)
        .map(set => ({ code: set.code as CardSetCodeEnum, name: set.name })) || [];

    return (
        <div className="card-set-tabs-container mb-4">
            <CardSetTabs
                cardSets={cardSets}
                selectedCardSet={selectedSet as CardSetCodeEnum}
                onSelectCardSet={onSelectSet}
                disabled={setsLoading}
            />
        </div>
    );
};

export default CardSetSelector;
