import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601DateTime: { input: string; output: string; }
};

/** Autogenerated input type of AddOfferNote */
export type AddOfferNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  offerId: Scalars['ID']['input'];
};

/** Autogenerated return type of AddOfferNote. */
export type AddOfferNotePayload = {
  __typename?: 'AddOfferNotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  offer?: Maybe<Offer>;
};

/** Types of arenas */
export enum ArenaTypeEnum {
  Ground = 'GROUND',
  Space = 'SPACE'
}

/** An aspect a card can have */
export type Aspect = {
  __typename?: 'Aspect';
  aspectType: AspectTypeEnum;
  image: AspectImage;
};

export type AspectImage = {
  __typename?: 'AspectImage';
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Types of aspects */
export enum AspectTypeEnum {
  Aggression = 'AGGRESSION',
  Command = 'COMMAND',
  Cunning = 'CUNNING',
  Heroism = 'HEROISM',
  Vigilance = 'VIGILANCE',
  Villainy = 'VILLAINY'
}

/** A card */
export type Card = {
  __typename?: 'Card';
  arena?: Maybe<ArenaTypeEnum>;
  /** Array of aspect types */
  aspects?: Maybe<Array<AspectTypeEnum>>;
  backText?: Maybe<Scalars['String']['output']>;
  cardSet: CardSet;
  cardType: CardTypeEnum;
  /** Array of card variant types */
  cardVariants?: Maybe<Array<CardVariant>>;
  cost: Scalars['Int']['output'];
  frontText: Scalars['String']['output'];
  hp?: Maybe<Scalars['Int']['output']>;
  hyperspaceCardNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images: CardImages;
  keywords?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  power?: Maybe<Scalars['Int']['output']>;
  rarity: RarityTypeEnum;
  standardCardNumber?: Maybe<Scalars['String']['output']>;
  standardFullCardNumber?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  traits?: Maybe<Array<Scalars['String']['output']>>;
  unique: Scalars['Boolean']['output'];
};

/** The connection type for Card. */
export type CardConnection = {
  __typename?: 'CardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CardEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Card>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CardDetails = {
  __typename?: 'CardDetails';
  cardNumber: Scalars['String']['output'];
  cardType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rarity: Scalars['String']['output'];
};

/** An edge in a connection. */
export type CardEdge = {
  __typename?: 'CardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Card>;
};

export type CardImage = {
  __typename?: 'CardImage';
  id: Scalars['ID']['output'];
  orientation: OrientationTypeEnum;
  url?: Maybe<Scalars['String']['output']>;
};

export type CardImages = {
  __typename?: 'CardImages';
  back?: Maybe<CardImage>;
  front: CardImage;
};

/** A card set */
export type CardSet = {
  __typename?: 'CardSet';
  cards: CardConnection;
  code: CardSetCodeEnum;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};


/** A card set */
export type CardSetCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum CardSetCodeEnum {
  Shd = 'SHD',
  Sor = 'SOR',
  Twi = 'TWI'
}

/** The connection type for CardSet. */
export type CardSetConnection = {
  __typename?: 'CardSetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CardSetEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CardSet>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CardSetEdge = {
  __typename?: 'CardSetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CardSet>;
};

/** Types of card types */
export enum CardTypeEnum {
  Base = 'BASE',
  Event = 'EVENT',
  Leader = 'LEADER',
  Unit = 'UNIT',
  Upgrade = 'UPGRADE'
}

/** A variant of a card */
export type CardVariant = {
  __typename?: 'CardVariant';
  card: Card;
  cardNumber: Scalars['String']['output'];
  fullCardNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images: CardImages;
  variantType: CardVariantTypeEnum;
};

/** Types of card variants */
export enum CardVariantTypeEnum {
  Foil = 'FOIL',
  Hyperspace = 'HYPERSPACE',
  HyperspaceFoil = 'HYPERSPACE_FOIL',
  Showcase = 'SHOWCASE',
  Standard = 'STANDARD'
}

export type CollectionItemInventory = {
  __typename?: 'CollectionItemInventory';
  extraCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  inventory: Scalars['Int']['output'];
  missing: MissingCount;
  variantId: Scalars['Int']['output'];
};

export type CollectionList = {
  __typename?: 'CollectionList';
  id: Scalars['ID']['output'];
  shareSettings?: Maybe<ListShareSettings>;
};

/** Autogenerated input type of CreateOffer */
export type CreateOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  offerItems: Array<OfferItemInput>;
  offerType: OfferTypeEnum;
  recipientHandle: Scalars['String']['input'];
};

/** Autogenerated return type of CreateOffer. */
export type CreateOfferPayload = {
  __typename?: 'CreateOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  offer?: Maybe<Offer>;
};

export type Error = {
  __typename?: 'Error';
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ListShareSettings = {
  __typename?: 'ListShareSettings';
  sharedWithAll: Scalars['Boolean']['output'];
  sharedWithUsers: Array<User>;
};

export type MissingCount = {
  __typename?: 'MissingCount';
  count: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addOfferNote?: Maybe<AddOfferNotePayload>;
  createOffer?: Maybe<CreateOfferPayload>;
  rescindOffer?: Maybe<RescindOfferPayload>;
  updateCollectionSharing?: Maybe<UpdateCollectionSharingPayload>;
  updateCollectionVariantInventory?: Maybe<UpdateCollectionVariantInventoryPayload>;
  updateSaleListSharing?: Maybe<UpdateSaleListSharingPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateUserProfile?: Maybe<UpdateUserProfilePayload>;
  updateWantListSharing?: Maybe<UpdateWantListSharingPayload>;
  updateWantListVariantCount?: Maybe<UpdateWantListVariantCountPayload>;
  upsertSaleListVariant?: Maybe<UpsertSaleListVariantPayload>;
};


export type MutationAddOfferNoteArgs = {
  input: AddOfferNoteInput;
};


export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};


export type MutationRescindOfferArgs = {
  input: RescindOfferInput;
};


export type MutationUpdateCollectionSharingArgs = {
  input: UpdateCollectionSharingInput;
};


export type MutationUpdateCollectionVariantInventoryArgs = {
  input: UpdateCollectionVariantInventoryInput;
};


export type MutationUpdateSaleListSharingArgs = {
  input: UpdateSaleListSharingInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationUpdateWantListSharingArgs = {
  input: UpdateWantListSharingInput;
};


export type MutationUpdateWantListVariantCountArgs = {
  input: UpdateWantListVariantCountInput;
};


export type MutationUpsertSaleListVariantArgs = {
  input: UpsertSaleListVariantInput;
};

export type Offer = {
  __typename?: 'Offer';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  initiator: User;
  notes: Array<OfferNote>;
  offerItems: Array<OfferItem>;
  offerType: OfferTypeEnum;
  recipient: User;
  status: OfferStatusEnum;
  totalPriceInCents: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type OfferItem = {
  __typename?: 'OfferItem';
  cardVariant: CardVariant;
  id: Scalars['ID']['output'];
  offer: Offer;
  priceInCents: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type OfferItemInput = {
  priceInCents: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  variantId: Scalars['ID']['input'];
};

export type OfferNote = {
  __typename?: 'OfferNote';
  content: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export enum OfferRoleEnum {
  /** All offers involving the user */
  All = 'ALL',
  /** Offers initiated by the user */
  Initiator = 'INITIATOR',
  /** Offers received by the user */
  Recipient = 'RECIPIENT'
}

export enum OfferStatusEnum {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Rescinded = 'RESCINDED'
}

export enum OfferTypeEnum {
  Buy = 'BUY',
  Sell = 'SELL'
}

/** Types of orientations */
export enum OrientationTypeEnum {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  aspects: Array<Aspect>;
  card: Card;
  cardSet: CardSet;
  cardSets: CardSetConnection;
  cards: CardConnection;
  offer?: Maybe<Offer>;
  saleList?: Maybe<SaleList>;
  sharedCollections: Array<SharedCollection>;
  sharedSaleLists: Array<SharedSaleList>;
  sharedWantLists: Array<SharedWantList>;
  user?: Maybe<User>;
  userCardSetCollection: UserCardInventoryConnection;
  userCollection?: Maybe<CollectionList>;
  userOffers: Array<Offer>;
  userSaleLists: Array<SaleList>;
  userWantLists: Array<WantList>;
  users: UserConnection;
  wantList?: Maybe<WantList>;
};


export type QueryCardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCardSetArgs = {
  cardSetCode: CardSetCodeEnum;
};


export type QueryCardSetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOfferArgs = {
  offerId: Scalars['ID']['input'];
};


export type QuerySaleListArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserCardSetCollectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cardSetCode: CardSetCodeEnum;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userHandle?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserOffersArgs = {
  offerType?: InputMaybe<OfferTypeEnum>;
  role?: InputMaybe<OfferRoleEnum>;
  status?: InputMaybe<OfferStatusEnum>;
};


export type QueryUserSaleListsArgs = {
  userHandle?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserWantListsArgs = {
  userHandle?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWantListArgs = {
  id: Scalars['ID']['input'];
};

/** Types of rarities */
export enum RarityTypeEnum {
  Common = 'COMMON',
  Legendary = 'LEGENDARY',
  Rare = 'RARE',
  Special = 'SPECIAL',
  Uncommon = 'UNCOMMON'
}

/** Autogenerated input type of RescindOffer */
export type RescindOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['ID']['input'];
};

/** Autogenerated return type of RescindOffer. */
export type RescindOfferPayload = {
  __typename?: 'RescindOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  offer?: Maybe<Offer>;
};

export enum RoleEnum {
  Admin = 'ADMIN',
  User = 'USER'
}

export type SaleList = {
  __typename?: 'SaleList';
  id: Scalars['ID']['output'];
  items: Array<SaleListItem>;
  name: Scalars['String']['output'];
  shareSettings?: Maybe<ListShareSettings>;
};

export type SaleListItem = {
  __typename?: 'SaleListItem';
  cardVariant: CardVariant;
  condition: SaleListItemConditionEnum;
  id: Scalars['ID']['output'];
  priceInCents: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  saleListId: Scalars['ID']['output'];
};

export enum SaleListItemConditionEnum {
  Excellent = 'EXCELLENT',
  Good = 'GOOD',
  LightlyPlayed = 'LIGHTLY_PLAYED',
  Mint = 'MINT',
  NearMint = 'NEAR_MINT',
  Played = 'PLAYED',
  Poor = 'POOR'
}

export type ShareTarget = {
  email?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
};

export type SharedCollection = {
  __typename?: 'SharedCollection';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  publiclyShared: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: SharedListOwner;
};

export type SharedListOwner = {
  __typename?: 'SharedListOwner';
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type SharedSaleList = {
  __typename?: 'SharedSaleList';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  publiclyShared: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: SharedListOwner;
};

export type SharedWantList = {
  __typename?: 'SharedWantList';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  publiclyShared: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: SharedListOwner;
};

/** Autogenerated input type of UpdateCollectionSharing */
export type UpdateCollectionSharingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shareWith?: InputMaybe<Array<ShareTarget>>;
  shareWithAll: Scalars['Boolean']['input'];
};

/** Autogenerated return type of UpdateCollectionSharing. */
export type UpdateCollectionSharingPayload = {
  __typename?: 'UpdateCollectionSharingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateCollectionVariantInventory */
export type UpdateCollectionVariantInventoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  inventory?: InputMaybe<Scalars['Int']['input']>;
  missingOverrideCount?: InputMaybe<Scalars['Int']['input']>;
  variantId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdateCollectionVariantInventory. */
export type UpdateCollectionVariantInventoryPayload = {
  __typename?: 'UpdateCollectionVariantInventoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  collectionItemInventory?: Maybe<CollectionItemInventory>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateSaleListSharing */
export type UpdateSaleListSharingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  saleListId: Scalars['ID']['input'];
  shareWith?: InputMaybe<Array<ShareTarget>>;
  shareWithAll: Scalars['Boolean']['input'];
};

/** Autogenerated return type of UpdateSaleListSharing. */
export type UpdateSaleListSharingPayload = {
  __typename?: 'UpdateSaleListSharingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<UpdateUserProfileError>;
  user?: Maybe<User>;
};

export type UpdateUserProfileError = {
  __typename?: 'UpdateUserProfileError';
  code: UpdateUserProfileErrorCode;
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export enum UpdateUserProfileErrorCode {
  /** You are not authorized to update this user */
  Forbidden = 'FORBIDDEN',
  /** Handle cannot be blank */
  HandleBlank = 'HANDLE_BLANK',
  /** Handle format is invalid */
  HandleInvalid = 'HANDLE_INVALID',
  /** Handle is reserved */
  HandleReserved = 'HANDLE_RESERVED',
  /** Handle is already taken */
  HandleTaken = 'HANDLE_TAKEN',
  /** Handle cannot be changed once set */
  Immutable = 'IMMUTABLE',
  /** User not found */
  NotFound = 'NOT_FOUND',
  /** An unknown error occurred */
  UnknownError = 'UNKNOWN_ERROR'
}

/** Autogenerated input type of UpdateUserProfile */
export type UpdateUserProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  handle: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateUserProfile. */
export type UpdateUserProfilePayload = {
  __typename?: 'UpdateUserProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<UpdateUserProfileError>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateWantListSharing */
export type UpdateWantListSharingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shareWith?: InputMaybe<Array<ShareTarget>>;
  shareWithAll: Scalars['Boolean']['input'];
  wantListId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateWantListSharing. */
export type UpdateWantListSharingPayload = {
  __typename?: 'UpdateWantListSharingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateWantListVariantCount */
export type UpdateWantListVariantCountInput = {
  cardVariantId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  wantListId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateWantListVariantCount. */
export type UpdateWantListVariantCountPayload = {
  __typename?: 'UpdateWantListVariantCountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wantListItem?: Maybe<WantListItem>;
};

/** Autogenerated input type of UpsertSaleListVariant */
export type UpsertSaleListVariantInput = {
  cardVariantId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  condition: SaleListItemConditionEnum;
  priceInCents: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  saleListId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpsertSaleListVariant. */
export type UpsertSaleListVariantPayload = {
  __typename?: 'UpsertSaleListVariantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  saleListItem?: Maybe<SaleListItem>;
  userErrors: Array<Error>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  handle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  roles: Array<RoleEnum>;
};

export type UserCardInventory = {
  __typename?: 'UserCardInventory';
  cardDetails: CardDetails;
  foilInventory?: Maybe<CollectionItemInventory>;
  hyperspaceFoilInventory?: Maybe<CollectionItemInventory>;
  hyperspaceInventory?: Maybe<CollectionItemInventory>;
  id: Scalars['ID']['output'];
  showcaseInventory?: Maybe<CollectionItemInventory>;
  standardInventory?: Maybe<CollectionItemInventory>;
};

/** The connection type for UserCardInventory. */
export type UserCardInventoryConnection = {
  __typename?: 'UserCardInventoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserCardInventoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserCardInventory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserCardInventoryEdge = {
  __typename?: 'UserCardInventoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserCardInventory>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type WantList = {
  __typename?: 'WantList';
  id: Scalars['ID']['output'];
  items: WantListItemConnection;
  name: Scalars['String']['output'];
  shareSettings?: Maybe<ListShareSettings>;
};


export type WantListItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type WantListItem = {
  __typename?: 'WantListItem';
  cardVariant: CardVariant;
  id: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  wantListId: Scalars['ID']['output'];
};

/** The connection type for WantListItem. */
export type WantListItemConnection = {
  __typename?: 'WantListItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WantListItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<WantListItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type WantListItemEdge = {
  __typename?: 'WantListItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<WantListItem>;
};

export type AddOfferNoteMutationVariables = Exact<{
  offerId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
}>;


export type AddOfferNoteMutation = { __typename?: 'Mutation', addOfferNote?: { __typename?: 'AddOfferNotePayload', offer?: { __typename?: 'Offer', id: string, status: OfferStatusEnum } | null, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type CreateOfferMutationVariables = Exact<{
  input: CreateOfferInput;
}>;


export type CreateOfferMutation = { __typename?: 'Mutation', createOffer?: { __typename?: 'CreateOfferPayload', offer?: { __typename?: 'Offer', id: string, offerType: OfferTypeEnum, totalPriceInCents: number, status: OfferStatusEnum, createdAt: string, initiator: { __typename?: 'User', id: number, handle?: string | null }, recipient: { __typename?: 'User', id: number, handle?: string | null }, offerItems: Array<{ __typename?: 'OfferItem', id: string, quantity: number, priceInCents: number, cardVariant: { __typename?: 'CardVariant', id: string, variantType: CardVariantTypeEnum, card: { __typename?: 'Card', id: string, name: string } } }>, notes: Array<{ __typename?: 'OfferNote', id: string, content: string, createdAt: string, user: { __typename?: 'User', id: number, handle?: string | null } }> } | null, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type RescindOfferMutationVariables = Exact<{
  offerId: Scalars['ID']['input'];
}>;


export type RescindOfferMutation = { __typename?: 'Mutation', rescindOffer?: { __typename?: 'RescindOfferPayload', offer?: { __typename?: 'Offer', id: string, status: OfferStatusEnum } | null, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type UpdateCollectionSharingMutationVariables = Exact<{
  input: UpdateCollectionSharingInput;
}>;


export type UpdateCollectionSharingMutation = { __typename?: 'Mutation', updateCollectionSharing?: { __typename?: 'UpdateCollectionSharingPayload', success: boolean, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type UpdateCollectionVariantInventoryMutationVariables = Exact<{
  variantId: Scalars['Int']['input'];
  inventory?: InputMaybe<Scalars['Int']['input']>;
  missingOverrideCount?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateCollectionVariantInventoryMutation = { __typename?: 'Mutation', updateCollectionVariantInventory?: { __typename?: 'UpdateCollectionVariantInventoryPayload', collectionItemInventory?: { __typename?: 'CollectionItemInventory', id: number, inventory: number, variantId: number, missing: { __typename?: 'MissingCount', count: number, isDefault: boolean } } | null, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type UpdateSaleListSharingMutationVariables = Exact<{
  input: UpdateSaleListSharingInput;
}>;


export type UpdateSaleListSharingMutation = { __typename?: 'Mutation', updateSaleListSharing?: { __typename?: 'UpdateSaleListSharingPayload', success: boolean, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'UpdateUserPayload', user?: { __typename?: 'User', id: number, handle?: string | null, email: string } | null, errors: Array<{ __typename?: 'UpdateUserProfileError', field: string, code: UpdateUserProfileErrorCode, message: string }> } | null };

export type UpdateUserProfileMutationVariables = Exact<{
  input: UpdateUserProfileInput;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateUserProfile?: { __typename?: 'UpdateUserProfilePayload', user?: { __typename?: 'User', id: number, handle?: string | null } | null, errors: Array<{ __typename?: 'UpdateUserProfileError', field: string, code: UpdateUserProfileErrorCode, message: string }> } | null };

export type UpdateWantListSharingMutationVariables = Exact<{
  input: UpdateWantListSharingInput;
}>;


export type UpdateWantListSharingMutation = { __typename?: 'Mutation', updateWantListSharing?: { __typename?: 'UpdateWantListSharingPayload', success: boolean, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type UpdateWantListVariantCountMutationVariables = Exact<{
  wantListId: Scalars['ID']['input'];
  cardVariantId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
}>;


export type UpdateWantListVariantCountMutation = { __typename?: 'Mutation', updateWantListVariantCount?: { __typename?: 'UpdateWantListVariantCountPayload', wantListItem?: { __typename?: 'WantListItem', id: string, wantListId: string, quantity: number, cardVariant: { __typename?: 'CardVariant', id: string } } | null, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type UpsertSaleListVariantMutationVariables = Exact<{
  saleListId: Scalars['ID']['input'];
  cardVariantId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  priceInCents: Scalars['Int']['input'];
  condition: SaleListItemConditionEnum;
}>;


export type UpsertSaleListVariantMutation = { __typename?: 'Mutation', upsertSaleListVariant?: { __typename?: 'UpsertSaleListVariantPayload', saleListItem?: { __typename?: 'SaleListItem', id: string, saleListId: string, quantity: number, priceInCents: number, condition: SaleListItemConditionEnum, cardVariant: { __typename?: 'CardVariant', id: string } } | null, userErrors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type GetAspectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAspectsQuery = { __typename?: 'Query', aspects: Array<{ __typename?: 'Aspect', aspectType: AspectTypeEnum, image: { __typename?: 'AspectImage', id: string, url: string } }> };

export type GetCardQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCardQuery = { __typename?: 'Query', card: { __typename?: 'Card', id: string, name: string, subtitle?: string | null, cardType: CardTypeEnum, rarity: RarityTypeEnum, cost: number, power?: number | null, hp?: number | null, arena?: ArenaTypeEnum | null, aspects?: Array<AspectTypeEnum> | null, traits?: Array<string> | null, frontText: string, backText?: string | null, unique: boolean, keywords?: Array<string> | null, images: { __typename?: 'CardImages', front: { __typename?: 'CardImage', id: string, url?: string | null, orientation: OrientationTypeEnum }, back?: { __typename?: 'CardImage', id: string, url?: string | null, orientation: OrientationTypeEnum } | null }, cardVariants?: Array<{ __typename?: 'CardVariant', id: string, variantType: CardVariantTypeEnum, images: { __typename?: 'CardImages', front: { __typename?: 'CardImage', id: string, url?: string | null, orientation: OrientationTypeEnum }, back?: { __typename?: 'CardImage', id: string, url?: string | null, orientation: OrientationTypeEnum } | null } }> | null } };

export type GetCardSetQueryVariables = Exact<{
  cardSetCode: CardSetCodeEnum;
}>;


export type GetCardSetQuery = { __typename?: 'Query', cardSet: { __typename?: 'CardSet', id: string, name: string, code: CardSetCodeEnum, cards: { __typename?: 'CardConnection', nodes?: Array<{ __typename?: 'Card', id: string, name: string, subtitle?: string | null, standardCardNumber?: string | null, hyperspaceCardNumber?: string | null, cost: number, rarity: RarityTypeEnum, power?: number | null, hp?: number | null, cardType: CardTypeEnum, arena?: ArenaTypeEnum | null, aspects?: Array<AspectTypeEnum> | null, traits?: Array<string> | null, keywords?: Array<string> | null, images: { __typename?: 'CardImages', front: { __typename?: 'CardImage', id: string, url?: string | null, orientation: OrientationTypeEnum }, back?: { __typename?: 'CardImage', id: string, url?: string | null, orientation: OrientationTypeEnum } | null } } | null> | null } } };

export type GetCardSetVariantsQueryVariables = Exact<{
  cardSetCode: CardSetCodeEnum;
}>;


export type GetCardSetVariantsQuery = { __typename?: 'Query', cardSet: { __typename?: 'CardSet', id: string, name: string, code: CardSetCodeEnum, cards: { __typename?: 'CardConnection', nodes?: Array<{ __typename?: 'Card', id: string, name: string, subtitle?: string | null, standardCardNumber?: string | null, hyperspaceCardNumber?: string | null, cost: number, rarity: RarityTypeEnum, power?: number | null, hp?: number | null, cardType: CardTypeEnum, arena?: ArenaTypeEnum | null, aspects?: Array<AspectTypeEnum> | null, traits?: Array<string> | null, keywords?: Array<string> | null, images: { __typename?: 'CardImages', front: { __typename?: 'CardImage', id: string, url?: string | null, orientation: OrientationTypeEnum }, back?: { __typename?: 'CardImage', id: string, url?: string | null, orientation: OrientationTypeEnum } | null }, cardVariants?: Array<{ __typename?: 'CardVariant', id: string, variantType: CardVariantTypeEnum, images: { __typename?: 'CardImages', front: { __typename?: 'CardImage', id: string, url?: string | null, orientation: OrientationTypeEnum }, back?: { __typename?: 'CardImage', id: string, url?: string | null, orientation: OrientationTypeEnum } | null } }> | null } | null> | null } } };

export type GetCardSetsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCardSetsQuery = { __typename?: 'Query', cardSets: { __typename?: 'CardSetConnection', nodes?: Array<{ __typename?: 'CardSet', id: string, code: CardSetCodeEnum, name: string, description?: string | null, imageUrl?: string | null } | null> | null } };

export type GetCollectionShareSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCollectionShareSettingsQuery = { __typename?: 'Query', userCollection?: { __typename?: 'CollectionList', id: string, shareSettings?: { __typename?: 'ListShareSettings', sharedWithAll: boolean, sharedWithUsers: Array<{ __typename?: 'User', id: number, handle?: string | null, email: string }> } | null } | null };

export type GetOfferDetailsQueryVariables = Exact<{
  offerId: Scalars['ID']['input'];
}>;


export type GetOfferDetailsQuery = { __typename?: 'Query', offer?: { __typename?: 'Offer', id: string, status: OfferStatusEnum, offerType: OfferTypeEnum, createdAt: string, updatedAt: string, totalPriceInCents: number, initiator: { __typename?: 'User', id: number, handle?: string | null }, recipient: { __typename?: 'User', id: number, handle?: string | null }, offerItems: Array<{ __typename?: 'OfferItem', id: string, quantity: number, priceInCents: number, cardVariant: { __typename?: 'CardVariant', variantType: CardVariantTypeEnum, card: { __typename?: 'Card', id: string, name: string, standardCardNumber?: string | null } } }>, notes: Array<{ __typename?: 'OfferNote', id: string, content: string, createdAt: string, user: { __typename?: 'User', id: number, handle?: string | null } }> } | null };

export type GetSharedCollectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSharedCollectionsQuery = { __typename?: 'Query', sharedCollections: Array<{ __typename?: 'SharedCollection', id: string, publiclyShared: boolean, createdAt: string, updatedAt: string, user: { __typename?: 'SharedListOwner', handle: string } }> };

export type GetSharedSaleListsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSharedSaleListsQuery = { __typename?: 'Query', sharedSaleLists: Array<{ __typename?: 'SharedSaleList', id: string, publiclyShared: boolean, createdAt: string, updatedAt: string, user: { __typename?: 'SharedListOwner', handle: string } }> };

export type GetSharedWantListsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSharedWantListsQuery = { __typename?: 'Query', sharedWantLists: Array<{ __typename?: 'SharedWantList', id: string, publiclyShared: boolean, createdAt: string, updatedAt: string, user: { __typename?: 'SharedListOwner', handle: string } }> };

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: number, email: string, handle?: string | null, roles: Array<RoleEnum> } | null };

export type GetUserCardSetCollectionQueryVariables = Exact<{
  cardSetCode: CardSetCodeEnum;
  userHandle?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserCardSetCollectionQuery = { __typename?: 'Query', userCardSetCollection: { __typename?: 'UserCardInventoryConnection', nodes?: Array<{ __typename?: 'UserCardInventory', id: string, cardDetails: { __typename?: 'CardDetails', name: string, cardNumber: string, rarity: string, cardType: string }, standardInventory?: { __typename?: 'CollectionItemInventory', id: number, variantId: number, inventory: number, extraCount: number, missing: { __typename?: 'MissingCount', count: number, isDefault: boolean } } | null, foilInventory?: { __typename?: 'CollectionItemInventory', id: number, variantId: number, inventory: number, extraCount: number, missing: { __typename?: 'MissingCount', count: number, isDefault: boolean } } | null, hyperspaceInventory?: { __typename?: 'CollectionItemInventory', id: number, variantId: number, inventory: number, extraCount: number, missing: { __typename?: 'MissingCount', count: number, isDefault: boolean } } | null, hyperspaceFoilInventory?: { __typename?: 'CollectionItemInventory', id: number, variantId: number, inventory: number, extraCount: number, missing: { __typename?: 'MissingCount', count: number, isDefault: boolean } } | null, showcaseInventory?: { __typename?: 'CollectionItemInventory', id: number, variantId: number, inventory: number, extraCount: number, missing: { __typename?: 'MissingCount', count: number, isDefault: boolean } } | null } | null> | null } };

export type GetUserOffersQueryVariables = Exact<{
  offerType?: InputMaybe<OfferTypeEnum>;
  status?: InputMaybe<OfferStatusEnum>;
  role?: InputMaybe<OfferRoleEnum>;
}>;


export type GetUserOffersQuery = { __typename?: 'Query', userOffers: Array<{ __typename?: 'Offer', id: string, status: OfferStatusEnum, offerType: OfferTypeEnum, totalPriceInCents: number, createdAt: string, updatedAt: string, initiator: { __typename?: 'User', id: number, handle?: string | null }, recipient: { __typename?: 'User', id: number, handle?: string | null }, offerItems: Array<{ __typename?: 'OfferItem', id: string, quantity: number, priceInCents: number, cardVariant: { __typename?: 'CardVariant', id: string, variantType: CardVariantTypeEnum, card: { __typename?: 'Card', id: string, name: string } } }>, notes: Array<{ __typename?: 'OfferNote', id: string, content: string, createdAt: string, user: { __typename?: 'User', id: number, handle?: string | null } }> }> };

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfileQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: number, name: string, handle?: string | null, email: string, roles: Array<RoleEnum> } | null };

export type GetSaleListShareSettingsQueryVariables = Exact<{
  saleListId: Scalars['ID']['input'];
}>;


export type GetSaleListShareSettingsQuery = { __typename?: 'Query', saleList?: { __typename?: 'SaleList', id: string, shareSettings?: { __typename?: 'ListShareSettings', sharedWithAll: boolean, sharedWithUsers: Array<{ __typename?: 'User', id: number, handle?: string | null, email: string }> } | null } | null };

export type GetUserSaleListsForDisplayQueryVariables = Exact<{
  userHandle?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserSaleListsForDisplayQuery = { __typename?: 'Query', userSaleLists: Array<{ __typename?: 'SaleList', id: string, name: string, items: Array<{ __typename?: 'SaleListItem', id: string, quantity: number, priceInCents: number, condition: SaleListItemConditionEnum, cardVariant: { __typename?: 'CardVariant', id: string, variantType: CardVariantTypeEnum, fullCardNumber: string, card: { __typename?: 'Card', id: string, name: string, aspects?: Array<AspectTypeEnum> | null, standardFullCardNumber?: string | null, cardType: CardTypeEnum, images: { __typename?: 'CardImages', front: { __typename?: 'CardImage', id: string, url?: string | null, orientation: OrientationTypeEnum } } } } }> }> };

export type GetUserSaleListsForEditQueryVariables = Exact<{
  userHandle?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserSaleListsForEditQuery = { __typename?: 'Query', userSaleLists: Array<{ __typename?: 'SaleList', id: string, name: string, items: Array<{ __typename?: 'SaleListItem', id: string, quantity: number, priceInCents: number, condition: SaleListItemConditionEnum, cardVariant: { __typename?: 'CardVariant', id: string, variantType: CardVariantTypeEnum, cardNumber: string, card: { __typename?: 'Card', id: string, name: string, standardFullCardNumber?: string | null, aspects?: Array<AspectTypeEnum> | null } } }> }> };

export type GetUserWantListsForDisplayQueryVariables = Exact<{
  userHandle?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserWantListsForDisplayQuery = { __typename?: 'Query', userWantLists: Array<{ __typename?: 'WantList', id: string, name: string, items: { __typename?: 'WantListItemConnection', nodes?: Array<{ __typename?: 'WantListItem', id: string, quantity: number, cardVariant: { __typename?: 'CardVariant', id: string, variantType: CardVariantTypeEnum, fullCardNumber: string, card: { __typename?: 'Card', id: string, name: string, aspects?: Array<AspectTypeEnum> | null, standardFullCardNumber?: string | null, cardType: CardTypeEnum, images: { __typename?: 'CardImages', front: { __typename?: 'CardImage', id: string, url?: string | null, orientation: OrientationTypeEnum } } } } } | null> | null } }> };

export type GetUserWantListsForEditQueryVariables = Exact<{
  userHandle?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserWantListsForEditQuery = { __typename?: 'Query', userWantLists: Array<{ __typename?: 'WantList', id: string, name: string, items: { __typename?: 'WantListItemConnection', nodes?: Array<{ __typename?: 'WantListItem', id: string, quantity: number, cardVariant: { __typename?: 'CardVariant', id: string, variantType: CardVariantTypeEnum, cardNumber: string, card: { __typename?: 'Card', id: string, name: string, standardFullCardNumber?: string | null, aspects?: Array<AspectTypeEnum> | null } } } | null> | null } }> };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', nodes?: Array<{ __typename?: 'User', id: number, email: string, handle?: string | null, roles: Array<RoleEnum> } | null> | null } };

export type GetWantListShareSettingsQueryVariables = Exact<{
  wantListId: Scalars['ID']['input'];
}>;


export type GetWantListShareSettingsQuery = { __typename?: 'Query', wantList?: { __typename?: 'WantList', id: string, shareSettings?: { __typename?: 'ListShareSettings', sharedWithAll: boolean, sharedWithUsers: Array<{ __typename?: 'User', id: number, handle?: string | null, email: string }> } | null } | null };


export const AddOfferNoteDocument = gql`
    mutation AddOfferNote($offerId: ID!, $content: String!) {
  addOfferNote(input: {offerId: $offerId, content: $content}) {
    offer {
      id
      status
    }
    errors {
      field
      message
    }
  }
}
    `;
export type AddOfferNoteMutationFn = Apollo.MutationFunction<AddOfferNoteMutation, AddOfferNoteMutationVariables>;

/**
 * __useAddOfferNoteMutation__
 *
 * To run a mutation, you first call `useAddOfferNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOfferNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOfferNoteMutation, { data, loading, error }] = useAddOfferNoteMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useAddOfferNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddOfferNoteMutation, AddOfferNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOfferNoteMutation, AddOfferNoteMutationVariables>(AddOfferNoteDocument, options);
      }
export type AddOfferNoteMutationHookResult = ReturnType<typeof useAddOfferNoteMutation>;
export type AddOfferNoteMutationResult = Apollo.MutationResult<AddOfferNoteMutation>;
export type AddOfferNoteMutationOptions = Apollo.BaseMutationOptions<AddOfferNoteMutation, AddOfferNoteMutationVariables>;
export const CreateOfferDocument = gql`
    mutation CreateOffer($input: CreateOfferInput!) {
  createOffer(input: $input) {
    offer {
      id
      initiator {
        id
        handle
      }
      recipient {
        id
        handle
      }
      offerType
      offerItems {
        id
        cardVariant {
          id
          variantType
          card {
            id
            name
          }
        }
        quantity
        priceInCents
      }
      totalPriceInCents
      status
      createdAt
      notes {
        id
        content
        createdAt
        user {
          id
          handle
        }
      }
    }
    errors {
      field
      message
    }
  }
}
    `;
export type CreateOfferMutationFn = Apollo.MutationFunction<CreateOfferMutation, CreateOfferMutationVariables>;

/**
 * __useCreateOfferMutation__
 *
 * To run a mutation, you first call `useCreateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferMutation, { data, loading, error }] = useCreateOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOfferMutation(baseOptions?: Apollo.MutationHookOptions<CreateOfferMutation, CreateOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOfferMutation, CreateOfferMutationVariables>(CreateOfferDocument, options);
      }
export type CreateOfferMutationHookResult = ReturnType<typeof useCreateOfferMutation>;
export type CreateOfferMutationResult = Apollo.MutationResult<CreateOfferMutation>;
export type CreateOfferMutationOptions = Apollo.BaseMutationOptions<CreateOfferMutation, CreateOfferMutationVariables>;
export const RescindOfferDocument = gql`
    mutation RescindOffer($offerId: ID!) {
  rescindOffer(input: {offerId: $offerId}) {
    offer {
      id
      status
    }
    errors {
      field
      message
    }
  }
}
    `;
export type RescindOfferMutationFn = Apollo.MutationFunction<RescindOfferMutation, RescindOfferMutationVariables>;

/**
 * __useRescindOfferMutation__
 *
 * To run a mutation, you first call `useRescindOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescindOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescindOfferMutation, { data, loading, error }] = useRescindOfferMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useRescindOfferMutation(baseOptions?: Apollo.MutationHookOptions<RescindOfferMutation, RescindOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescindOfferMutation, RescindOfferMutationVariables>(RescindOfferDocument, options);
      }
export type RescindOfferMutationHookResult = ReturnType<typeof useRescindOfferMutation>;
export type RescindOfferMutationResult = Apollo.MutationResult<RescindOfferMutation>;
export type RescindOfferMutationOptions = Apollo.BaseMutationOptions<RescindOfferMutation, RescindOfferMutationVariables>;
export const UpdateCollectionSharingDocument = gql`
    mutation UpdateCollectionSharing($input: UpdateCollectionSharingInput!) {
  updateCollectionSharing(input: $input) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateCollectionSharingMutationFn = Apollo.MutationFunction<UpdateCollectionSharingMutation, UpdateCollectionSharingMutationVariables>;

/**
 * __useUpdateCollectionSharingMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionSharingMutation, { data, loading, error }] = useUpdateCollectionSharingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionSharingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCollectionSharingMutation, UpdateCollectionSharingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCollectionSharingMutation, UpdateCollectionSharingMutationVariables>(UpdateCollectionSharingDocument, options);
      }
export type UpdateCollectionSharingMutationHookResult = ReturnType<typeof useUpdateCollectionSharingMutation>;
export type UpdateCollectionSharingMutationResult = Apollo.MutationResult<UpdateCollectionSharingMutation>;
export type UpdateCollectionSharingMutationOptions = Apollo.BaseMutationOptions<UpdateCollectionSharingMutation, UpdateCollectionSharingMutationVariables>;
export const UpdateCollectionVariantInventoryDocument = gql`
    mutation UpdateCollectionVariantInventory($variantId: Int!, $inventory: Int, $missingOverrideCount: Int) {
  updateCollectionVariantInventory(
    input: {variantId: $variantId, inventory: $inventory, missingOverrideCount: $missingOverrideCount}
  ) {
    collectionItemInventory {
      id
      inventory
      variantId
      missing {
        count
        isDefault
      }
    }
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateCollectionVariantInventoryMutationFn = Apollo.MutationFunction<UpdateCollectionVariantInventoryMutation, UpdateCollectionVariantInventoryMutationVariables>;

/**
 * __useUpdateCollectionVariantInventoryMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionVariantInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionVariantInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionVariantInventoryMutation, { data, loading, error }] = useUpdateCollectionVariantInventoryMutation({
 *   variables: {
 *      variantId: // value for 'variantId'
 *      inventory: // value for 'inventory'
 *      missingOverrideCount: // value for 'missingOverrideCount'
 *   },
 * });
 */
export function useUpdateCollectionVariantInventoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCollectionVariantInventoryMutation, UpdateCollectionVariantInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCollectionVariantInventoryMutation, UpdateCollectionVariantInventoryMutationVariables>(UpdateCollectionVariantInventoryDocument, options);
      }
export type UpdateCollectionVariantInventoryMutationHookResult = ReturnType<typeof useUpdateCollectionVariantInventoryMutation>;
export type UpdateCollectionVariantInventoryMutationResult = Apollo.MutationResult<UpdateCollectionVariantInventoryMutation>;
export type UpdateCollectionVariantInventoryMutationOptions = Apollo.BaseMutationOptions<UpdateCollectionVariantInventoryMutation, UpdateCollectionVariantInventoryMutationVariables>;
export const UpdateSaleListSharingDocument = gql`
    mutation UpdateSaleListSharing($input: UpdateSaleListSharingInput!) {
  updateSaleListSharing(input: $input) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateSaleListSharingMutationFn = Apollo.MutationFunction<UpdateSaleListSharingMutation, UpdateSaleListSharingMutationVariables>;

/**
 * __useUpdateSaleListSharingMutation__
 *
 * To run a mutation, you first call `useUpdateSaleListSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleListSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleListSharingMutation, { data, loading, error }] = useUpdateSaleListSharingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSaleListSharingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSaleListSharingMutation, UpdateSaleListSharingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSaleListSharingMutation, UpdateSaleListSharingMutationVariables>(UpdateSaleListSharingDocument, options);
      }
export type UpdateSaleListSharingMutationHookResult = ReturnType<typeof useUpdateSaleListSharingMutation>;
export type UpdateSaleListSharingMutationResult = Apollo.MutationResult<UpdateSaleListSharingMutation>;
export type UpdateSaleListSharingMutationOptions = Apollo.BaseMutationOptions<UpdateSaleListSharingMutation, UpdateSaleListSharingMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      id
      handle
      email
    }
    errors {
      field
      code
      message
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    user {
      id
      handle
    }
    errors {
      field
      code
      message
    }
  }
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const UpdateWantListSharingDocument = gql`
    mutation UpdateWantListSharing($input: UpdateWantListSharingInput!) {
  updateWantListSharing(input: $input) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateWantListSharingMutationFn = Apollo.MutationFunction<UpdateWantListSharingMutation, UpdateWantListSharingMutationVariables>;

/**
 * __useUpdateWantListSharingMutation__
 *
 * To run a mutation, you first call `useUpdateWantListSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWantListSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWantListSharingMutation, { data, loading, error }] = useUpdateWantListSharingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWantListSharingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWantListSharingMutation, UpdateWantListSharingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWantListSharingMutation, UpdateWantListSharingMutationVariables>(UpdateWantListSharingDocument, options);
      }
export type UpdateWantListSharingMutationHookResult = ReturnType<typeof useUpdateWantListSharingMutation>;
export type UpdateWantListSharingMutationResult = Apollo.MutationResult<UpdateWantListSharingMutation>;
export type UpdateWantListSharingMutationOptions = Apollo.BaseMutationOptions<UpdateWantListSharingMutation, UpdateWantListSharingMutationVariables>;
export const UpdateWantListVariantCountDocument = gql`
    mutation UpdateWantListVariantCount($wantListId: ID!, $cardVariantId: ID!, $quantity: Int!) {
  updateWantListVariantCount(
    input: {wantListId: $wantListId, cardVariantId: $cardVariantId, quantity: $quantity}
  ) {
    wantListItem {
      id
      wantListId
      cardVariant {
        id
      }
      quantity
    }
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateWantListVariantCountMutationFn = Apollo.MutationFunction<UpdateWantListVariantCountMutation, UpdateWantListVariantCountMutationVariables>;

/**
 * __useUpdateWantListVariantCountMutation__
 *
 * To run a mutation, you first call `useUpdateWantListVariantCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWantListVariantCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWantListVariantCountMutation, { data, loading, error }] = useUpdateWantListVariantCountMutation({
 *   variables: {
 *      wantListId: // value for 'wantListId'
 *      cardVariantId: // value for 'cardVariantId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateWantListVariantCountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWantListVariantCountMutation, UpdateWantListVariantCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWantListVariantCountMutation, UpdateWantListVariantCountMutationVariables>(UpdateWantListVariantCountDocument, options);
      }
export type UpdateWantListVariantCountMutationHookResult = ReturnType<typeof useUpdateWantListVariantCountMutation>;
export type UpdateWantListVariantCountMutationResult = Apollo.MutationResult<UpdateWantListVariantCountMutation>;
export type UpdateWantListVariantCountMutationOptions = Apollo.BaseMutationOptions<UpdateWantListVariantCountMutation, UpdateWantListVariantCountMutationVariables>;
export const UpsertSaleListVariantDocument = gql`
    mutation UpsertSaleListVariant($saleListId: ID!, $cardVariantId: ID!, $quantity: Int!, $priceInCents: Int!, $condition: SaleListItemConditionEnum!) {
  upsertSaleListVariant(
    input: {saleListId: $saleListId, cardVariantId: $cardVariantId, quantity: $quantity, priceInCents: $priceInCents, condition: $condition}
  ) {
    saleListItem {
      id
      saleListId
      cardVariant {
        id
      }
      quantity
      priceInCents
      condition
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpsertSaleListVariantMutationFn = Apollo.MutationFunction<UpsertSaleListVariantMutation, UpsertSaleListVariantMutationVariables>;

/**
 * __useUpsertSaleListVariantMutation__
 *
 * To run a mutation, you first call `useUpsertSaleListVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSaleListVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSaleListVariantMutation, { data, loading, error }] = useUpsertSaleListVariantMutation({
 *   variables: {
 *      saleListId: // value for 'saleListId'
 *      cardVariantId: // value for 'cardVariantId'
 *      quantity: // value for 'quantity'
 *      priceInCents: // value for 'priceInCents'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useUpsertSaleListVariantMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSaleListVariantMutation, UpsertSaleListVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSaleListVariantMutation, UpsertSaleListVariantMutationVariables>(UpsertSaleListVariantDocument, options);
      }
export type UpsertSaleListVariantMutationHookResult = ReturnType<typeof useUpsertSaleListVariantMutation>;
export type UpsertSaleListVariantMutationResult = Apollo.MutationResult<UpsertSaleListVariantMutation>;
export type UpsertSaleListVariantMutationOptions = Apollo.BaseMutationOptions<UpsertSaleListVariantMutation, UpsertSaleListVariantMutationVariables>;
export const GetAspectsDocument = gql`
    query GetAspects {
  aspects {
    aspectType
    image {
      id
      url
    }
  }
}
    `;

/**
 * __useGetAspectsQuery__
 *
 * To run a query within a React component, call `useGetAspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAspectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAspectsQuery(baseOptions?: Apollo.QueryHookOptions<GetAspectsQuery, GetAspectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAspectsQuery, GetAspectsQueryVariables>(GetAspectsDocument, options);
      }
export function useGetAspectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAspectsQuery, GetAspectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAspectsQuery, GetAspectsQueryVariables>(GetAspectsDocument, options);
        }
export function useGetAspectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAspectsQuery, GetAspectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAspectsQuery, GetAspectsQueryVariables>(GetAspectsDocument, options);
        }
export type GetAspectsQueryHookResult = ReturnType<typeof useGetAspectsQuery>;
export type GetAspectsLazyQueryHookResult = ReturnType<typeof useGetAspectsLazyQuery>;
export type GetAspectsSuspenseQueryHookResult = ReturnType<typeof useGetAspectsSuspenseQuery>;
export type GetAspectsQueryResult = Apollo.QueryResult<GetAspectsQuery, GetAspectsQueryVariables>;
export const GetCardDocument = gql`
    query GetCard($id: ID!) {
  card(id: $id) {
    id
    name
    subtitle
    cardType
    rarity
    cost
    power
    hp
    arena
    aspects
    traits
    frontText
    backText
    unique
    keywords
    images {
      front {
        id
        url
        orientation
      }
      back {
        id
        url
        orientation
      }
    }
    cardVariants {
      id
      variantType
      images {
        front {
          id
          url
          orientation
        }
        back {
          id
          url
          orientation
        }
      }
    }
  }
}
    `;

/**
 * __useGetCardQuery__
 *
 * To run a query within a React component, call `useGetCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCardQuery(baseOptions: Apollo.QueryHookOptions<GetCardQuery, GetCardQueryVariables> & ({ variables: GetCardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
      }
export function useGetCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
        }
export function useGetCardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
        }
export type GetCardQueryHookResult = ReturnType<typeof useGetCardQuery>;
export type GetCardLazyQueryHookResult = ReturnType<typeof useGetCardLazyQuery>;
export type GetCardSuspenseQueryHookResult = ReturnType<typeof useGetCardSuspenseQuery>;
export type GetCardQueryResult = Apollo.QueryResult<GetCardQuery, GetCardQueryVariables>;
export const GetCardSetDocument = gql`
    query GetCardSet($cardSetCode: CardSetCodeEnum!) {
  cardSet(cardSetCode: $cardSetCode) {
    id
    name
    code
    cards {
      nodes {
        id
        name
        subtitle
        standardCardNumber
        hyperspaceCardNumber
        cost
        rarity
        power
        hp
        cardType
        arena
        aspects
        traits
        keywords
        images {
          front {
            id
            url
            orientation
          }
          back {
            id
            url
            orientation
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCardSetQuery__
 *
 * To run a query within a React component, call `useGetCardSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardSetQuery({
 *   variables: {
 *      cardSetCode: // value for 'cardSetCode'
 *   },
 * });
 */
export function useGetCardSetQuery(baseOptions: Apollo.QueryHookOptions<GetCardSetQuery, GetCardSetQueryVariables> & ({ variables: GetCardSetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardSetQuery, GetCardSetQueryVariables>(GetCardSetDocument, options);
      }
export function useGetCardSetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardSetQuery, GetCardSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardSetQuery, GetCardSetQueryVariables>(GetCardSetDocument, options);
        }
export function useGetCardSetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCardSetQuery, GetCardSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCardSetQuery, GetCardSetQueryVariables>(GetCardSetDocument, options);
        }
export type GetCardSetQueryHookResult = ReturnType<typeof useGetCardSetQuery>;
export type GetCardSetLazyQueryHookResult = ReturnType<typeof useGetCardSetLazyQuery>;
export type GetCardSetSuspenseQueryHookResult = ReturnType<typeof useGetCardSetSuspenseQuery>;
export type GetCardSetQueryResult = Apollo.QueryResult<GetCardSetQuery, GetCardSetQueryVariables>;
export const GetCardSetVariantsDocument = gql`
    query GetCardSetVariants($cardSetCode: CardSetCodeEnum!) {
  cardSet(cardSetCode: $cardSetCode) {
    id
    name
    code
    cards {
      nodes {
        id
        name
        subtitle
        standardCardNumber
        hyperspaceCardNumber
        cost
        rarity
        power
        hp
        cardType
        arena
        aspects
        traits
        keywords
        images {
          front {
            id
            url
            orientation
          }
          back {
            id
            url
            orientation
          }
        }
        cardVariants {
          id
          variantType
          images {
            front {
              id
              url
              orientation
            }
            back {
              id
              url
              orientation
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCardSetVariantsQuery__
 *
 * To run a query within a React component, call `useGetCardSetVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardSetVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardSetVariantsQuery({
 *   variables: {
 *      cardSetCode: // value for 'cardSetCode'
 *   },
 * });
 */
export function useGetCardSetVariantsQuery(baseOptions: Apollo.QueryHookOptions<GetCardSetVariantsQuery, GetCardSetVariantsQueryVariables> & ({ variables: GetCardSetVariantsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardSetVariantsQuery, GetCardSetVariantsQueryVariables>(GetCardSetVariantsDocument, options);
      }
export function useGetCardSetVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardSetVariantsQuery, GetCardSetVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardSetVariantsQuery, GetCardSetVariantsQueryVariables>(GetCardSetVariantsDocument, options);
        }
export function useGetCardSetVariantsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCardSetVariantsQuery, GetCardSetVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCardSetVariantsQuery, GetCardSetVariantsQueryVariables>(GetCardSetVariantsDocument, options);
        }
export type GetCardSetVariantsQueryHookResult = ReturnType<typeof useGetCardSetVariantsQuery>;
export type GetCardSetVariantsLazyQueryHookResult = ReturnType<typeof useGetCardSetVariantsLazyQuery>;
export type GetCardSetVariantsSuspenseQueryHookResult = ReturnType<typeof useGetCardSetVariantsSuspenseQuery>;
export type GetCardSetVariantsQueryResult = Apollo.QueryResult<GetCardSetVariantsQuery, GetCardSetVariantsQueryVariables>;
export const GetCardSetsDocument = gql`
    query GetCardSets {
  cardSets {
    nodes {
      id
      code
      name
      description
      imageUrl
    }
  }
}
    `;

/**
 * __useGetCardSetsQuery__
 *
 * To run a query within a React component, call `useGetCardSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardSetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCardSetsQuery(baseOptions?: Apollo.QueryHookOptions<GetCardSetsQuery, GetCardSetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardSetsQuery, GetCardSetsQueryVariables>(GetCardSetsDocument, options);
      }
export function useGetCardSetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardSetsQuery, GetCardSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardSetsQuery, GetCardSetsQueryVariables>(GetCardSetsDocument, options);
        }
export function useGetCardSetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCardSetsQuery, GetCardSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCardSetsQuery, GetCardSetsQueryVariables>(GetCardSetsDocument, options);
        }
export type GetCardSetsQueryHookResult = ReturnType<typeof useGetCardSetsQuery>;
export type GetCardSetsLazyQueryHookResult = ReturnType<typeof useGetCardSetsLazyQuery>;
export type GetCardSetsSuspenseQueryHookResult = ReturnType<typeof useGetCardSetsSuspenseQuery>;
export type GetCardSetsQueryResult = Apollo.QueryResult<GetCardSetsQuery, GetCardSetsQueryVariables>;
export const GetCollectionShareSettingsDocument = gql`
    query GetCollectionShareSettings {
  userCollection {
    id
    shareSettings {
      sharedWithAll
      sharedWithUsers {
        id
        handle
        email
      }
    }
  }
}
    `;

/**
 * __useGetCollectionShareSettingsQuery__
 *
 * To run a query within a React component, call `useGetCollectionShareSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionShareSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionShareSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCollectionShareSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetCollectionShareSettingsQuery, GetCollectionShareSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionShareSettingsQuery, GetCollectionShareSettingsQueryVariables>(GetCollectionShareSettingsDocument, options);
      }
export function useGetCollectionShareSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionShareSettingsQuery, GetCollectionShareSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionShareSettingsQuery, GetCollectionShareSettingsQueryVariables>(GetCollectionShareSettingsDocument, options);
        }
export function useGetCollectionShareSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCollectionShareSettingsQuery, GetCollectionShareSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCollectionShareSettingsQuery, GetCollectionShareSettingsQueryVariables>(GetCollectionShareSettingsDocument, options);
        }
export type GetCollectionShareSettingsQueryHookResult = ReturnType<typeof useGetCollectionShareSettingsQuery>;
export type GetCollectionShareSettingsLazyQueryHookResult = ReturnType<typeof useGetCollectionShareSettingsLazyQuery>;
export type GetCollectionShareSettingsSuspenseQueryHookResult = ReturnType<typeof useGetCollectionShareSettingsSuspenseQuery>;
export type GetCollectionShareSettingsQueryResult = Apollo.QueryResult<GetCollectionShareSettingsQuery, GetCollectionShareSettingsQueryVariables>;
export const GetOfferDetailsDocument = gql`
    query GetOfferDetails($offerId: ID!) {
  offer(offerId: $offerId) {
    id
    status
    offerType
    initiator {
      id
      handle
    }
    recipient {
      id
      handle
    }
    offerItems {
      id
      cardVariant {
        card {
          id
          name
          standardCardNumber
        }
        variantType
      }
      quantity
      priceInCents
    }
    createdAt
    updatedAt
    totalPriceInCents
    notes {
      id
      content
      createdAt
      user {
        id
        handle
      }
    }
  }
}
    `;

/**
 * __useGetOfferDetailsQuery__
 *
 * To run a query within a React component, call `useGetOfferDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferDetailsQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useGetOfferDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetOfferDetailsQuery, GetOfferDetailsQueryVariables> & ({ variables: GetOfferDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOfferDetailsQuery, GetOfferDetailsQueryVariables>(GetOfferDetailsDocument, options);
      }
export function useGetOfferDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfferDetailsQuery, GetOfferDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOfferDetailsQuery, GetOfferDetailsQueryVariables>(GetOfferDetailsDocument, options);
        }
export function useGetOfferDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOfferDetailsQuery, GetOfferDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOfferDetailsQuery, GetOfferDetailsQueryVariables>(GetOfferDetailsDocument, options);
        }
export type GetOfferDetailsQueryHookResult = ReturnType<typeof useGetOfferDetailsQuery>;
export type GetOfferDetailsLazyQueryHookResult = ReturnType<typeof useGetOfferDetailsLazyQuery>;
export type GetOfferDetailsSuspenseQueryHookResult = ReturnType<typeof useGetOfferDetailsSuspenseQuery>;
export type GetOfferDetailsQueryResult = Apollo.QueryResult<GetOfferDetailsQuery, GetOfferDetailsQueryVariables>;
export const GetSharedCollectionsDocument = gql`
    query GetSharedCollections {
  sharedCollections {
    id
    publiclyShared
    user {
      handle
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetSharedCollectionsQuery__
 *
 * To run a query within a React component, call `useGetSharedCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSharedCollectionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSharedCollectionsQuery, GetSharedCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSharedCollectionsQuery, GetSharedCollectionsQueryVariables>(GetSharedCollectionsDocument, options);
      }
export function useGetSharedCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSharedCollectionsQuery, GetSharedCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSharedCollectionsQuery, GetSharedCollectionsQueryVariables>(GetSharedCollectionsDocument, options);
        }
export function useGetSharedCollectionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSharedCollectionsQuery, GetSharedCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSharedCollectionsQuery, GetSharedCollectionsQueryVariables>(GetSharedCollectionsDocument, options);
        }
export type GetSharedCollectionsQueryHookResult = ReturnType<typeof useGetSharedCollectionsQuery>;
export type GetSharedCollectionsLazyQueryHookResult = ReturnType<typeof useGetSharedCollectionsLazyQuery>;
export type GetSharedCollectionsSuspenseQueryHookResult = ReturnType<typeof useGetSharedCollectionsSuspenseQuery>;
export type GetSharedCollectionsQueryResult = Apollo.QueryResult<GetSharedCollectionsQuery, GetSharedCollectionsQueryVariables>;
export const GetSharedSaleListsDocument = gql`
    query GetSharedSaleLists {
  sharedSaleLists {
    id
    publiclyShared
    user {
      handle
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetSharedSaleListsQuery__
 *
 * To run a query within a React component, call `useGetSharedSaleListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedSaleListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedSaleListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSharedSaleListsQuery(baseOptions?: Apollo.QueryHookOptions<GetSharedSaleListsQuery, GetSharedSaleListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSharedSaleListsQuery, GetSharedSaleListsQueryVariables>(GetSharedSaleListsDocument, options);
      }
export function useGetSharedSaleListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSharedSaleListsQuery, GetSharedSaleListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSharedSaleListsQuery, GetSharedSaleListsQueryVariables>(GetSharedSaleListsDocument, options);
        }
export function useGetSharedSaleListsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSharedSaleListsQuery, GetSharedSaleListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSharedSaleListsQuery, GetSharedSaleListsQueryVariables>(GetSharedSaleListsDocument, options);
        }
export type GetSharedSaleListsQueryHookResult = ReturnType<typeof useGetSharedSaleListsQuery>;
export type GetSharedSaleListsLazyQueryHookResult = ReturnType<typeof useGetSharedSaleListsLazyQuery>;
export type GetSharedSaleListsSuspenseQueryHookResult = ReturnType<typeof useGetSharedSaleListsSuspenseQuery>;
export type GetSharedSaleListsQueryResult = Apollo.QueryResult<GetSharedSaleListsQuery, GetSharedSaleListsQueryVariables>;
export const GetSharedWantListsDocument = gql`
    query GetSharedWantLists {
  sharedWantLists {
    id
    publiclyShared
    user {
      handle
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetSharedWantListsQuery__
 *
 * To run a query within a React component, call `useGetSharedWantListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedWantListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedWantListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSharedWantListsQuery(baseOptions?: Apollo.QueryHookOptions<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>(GetSharedWantListsDocument, options);
      }
export function useGetSharedWantListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>(GetSharedWantListsDocument, options);
        }
export function useGetSharedWantListsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>(GetSharedWantListsDocument, options);
        }
export type GetSharedWantListsQueryHookResult = ReturnType<typeof useGetSharedWantListsQuery>;
export type GetSharedWantListsLazyQueryHookResult = ReturnType<typeof useGetSharedWantListsLazyQuery>;
export type GetSharedWantListsSuspenseQueryHookResult = ReturnType<typeof useGetSharedWantListsSuspenseQuery>;
export type GetSharedWantListsQueryResult = Apollo.QueryResult<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
  user(id: $id) {
    id
    email
    handle
    roles
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> & ({ variables: GetUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export function useGetUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserCardSetCollectionDocument = gql`
    query GetUserCardSetCollection($cardSetCode: CardSetCodeEnum!, $userHandle: String) {
  userCardSetCollection(cardSetCode: $cardSetCode, userHandle: $userHandle) {
    nodes {
      id
      cardDetails {
        name
        cardNumber
        rarity
        cardType
      }
      standardInventory {
        id
        variantId
        inventory
        missing {
          count
          isDefault
        }
        extraCount
      }
      foilInventory {
        id
        variantId
        inventory
        missing {
          count
          isDefault
        }
        extraCount
      }
      hyperspaceInventory {
        id
        variantId
        inventory
        missing {
          count
          isDefault
        }
        extraCount
      }
      hyperspaceFoilInventory {
        id
        variantId
        inventory
        missing {
          count
          isDefault
        }
        extraCount
      }
      showcaseInventory {
        id
        variantId
        inventory
        missing {
          count
          isDefault
        }
        extraCount
      }
    }
  }
}
    `;

/**
 * __useGetUserCardSetCollectionQuery__
 *
 * To run a query within a React component, call `useGetUserCardSetCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCardSetCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCardSetCollectionQuery({
 *   variables: {
 *      cardSetCode: // value for 'cardSetCode'
 *      userHandle: // value for 'userHandle'
 *   },
 * });
 */
export function useGetUserCardSetCollectionQuery(baseOptions: Apollo.QueryHookOptions<GetUserCardSetCollectionQuery, GetUserCardSetCollectionQueryVariables> & ({ variables: GetUserCardSetCollectionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCardSetCollectionQuery, GetUserCardSetCollectionQueryVariables>(GetUserCardSetCollectionDocument, options);
      }
export function useGetUserCardSetCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCardSetCollectionQuery, GetUserCardSetCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCardSetCollectionQuery, GetUserCardSetCollectionQueryVariables>(GetUserCardSetCollectionDocument, options);
        }
export function useGetUserCardSetCollectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserCardSetCollectionQuery, GetUserCardSetCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserCardSetCollectionQuery, GetUserCardSetCollectionQueryVariables>(GetUserCardSetCollectionDocument, options);
        }
export type GetUserCardSetCollectionQueryHookResult = ReturnType<typeof useGetUserCardSetCollectionQuery>;
export type GetUserCardSetCollectionLazyQueryHookResult = ReturnType<typeof useGetUserCardSetCollectionLazyQuery>;
export type GetUserCardSetCollectionSuspenseQueryHookResult = ReturnType<typeof useGetUserCardSetCollectionSuspenseQuery>;
export type GetUserCardSetCollectionQueryResult = Apollo.QueryResult<GetUserCardSetCollectionQuery, GetUserCardSetCollectionQueryVariables>;
export const GetUserOffersDocument = gql`
    query GetUserOffers($offerType: OfferTypeEnum, $status: OfferStatusEnum, $role: OfferRoleEnum) {
  userOffers(offerType: $offerType, status: $status, role: $role) {
    id
    status
    offerType
    totalPriceInCents
    createdAt
    updatedAt
    initiator {
      id
      handle
    }
    recipient {
      id
      handle
    }
    offerItems {
      id
      quantity
      priceInCents
      cardVariant {
        id
        variantType
        card {
          id
          name
        }
      }
    }
    notes {
      id
      content
      createdAt
      user {
        id
        handle
      }
    }
  }
}
    `;

/**
 * __useGetUserOffersQuery__
 *
 * To run a query within a React component, call `useGetUserOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOffersQuery({
 *   variables: {
 *      offerType: // value for 'offerType'
 *      status: // value for 'status'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useGetUserOffersQuery(baseOptions?: Apollo.QueryHookOptions<GetUserOffersQuery, GetUserOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOffersQuery, GetUserOffersQueryVariables>(GetUserOffersDocument, options);
      }
export function useGetUserOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOffersQuery, GetUserOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOffersQuery, GetUserOffersQueryVariables>(GetUserOffersDocument, options);
        }
export function useGetUserOffersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserOffersQuery, GetUserOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserOffersQuery, GetUserOffersQueryVariables>(GetUserOffersDocument, options);
        }
export type GetUserOffersQueryHookResult = ReturnType<typeof useGetUserOffersQuery>;
export type GetUserOffersLazyQueryHookResult = ReturnType<typeof useGetUserOffersLazyQuery>;
export type GetUserOffersSuspenseQueryHookResult = ReturnType<typeof useGetUserOffersSuspenseQuery>;
export type GetUserOffersQueryResult = Apollo.QueryResult<GetUserOffersQuery, GetUserOffersQueryVariables>;
export const GetUserProfileDocument = gql`
    query GetUserProfile {
  user {
    id
    name
    handle
    email
    roles
  }
}
    `;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export function useGetUserProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileSuspenseQueryHookResult = ReturnType<typeof useGetUserProfileSuspenseQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const GetSaleListShareSettingsDocument = gql`
    query GetSaleListShareSettings($saleListId: ID!) {
  saleList(id: $saleListId) {
    id
    shareSettings {
      sharedWithAll
      sharedWithUsers {
        id
        handle
        email
      }
    }
  }
}
    `;

/**
 * __useGetSaleListShareSettingsQuery__
 *
 * To run a query within a React component, call `useGetSaleListShareSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleListShareSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaleListShareSettingsQuery({
 *   variables: {
 *      saleListId: // value for 'saleListId'
 *   },
 * });
 */
export function useGetSaleListShareSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetSaleListShareSettingsQuery, GetSaleListShareSettingsQueryVariables> & ({ variables: GetSaleListShareSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSaleListShareSettingsQuery, GetSaleListShareSettingsQueryVariables>(GetSaleListShareSettingsDocument, options);
      }
export function useGetSaleListShareSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSaleListShareSettingsQuery, GetSaleListShareSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSaleListShareSettingsQuery, GetSaleListShareSettingsQueryVariables>(GetSaleListShareSettingsDocument, options);
        }
export function useGetSaleListShareSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSaleListShareSettingsQuery, GetSaleListShareSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSaleListShareSettingsQuery, GetSaleListShareSettingsQueryVariables>(GetSaleListShareSettingsDocument, options);
        }
export type GetSaleListShareSettingsQueryHookResult = ReturnType<typeof useGetSaleListShareSettingsQuery>;
export type GetSaleListShareSettingsLazyQueryHookResult = ReturnType<typeof useGetSaleListShareSettingsLazyQuery>;
export type GetSaleListShareSettingsSuspenseQueryHookResult = ReturnType<typeof useGetSaleListShareSettingsSuspenseQuery>;
export type GetSaleListShareSettingsQueryResult = Apollo.QueryResult<GetSaleListShareSettingsQuery, GetSaleListShareSettingsQueryVariables>;
export const GetUserSaleListsForDisplayDocument = gql`
    query GetUserSaleListsForDisplay($userHandle: String) {
  userSaleLists(userHandle: $userHandle) {
    id
    name
    items {
      id
      cardVariant {
        id
        variantType
        fullCardNumber
        card {
          id
          name
          aspects
          images {
            front {
              id
              url
              orientation
            }
          }
          standardFullCardNumber
          cardType
        }
      }
      quantity
      priceInCents
      condition
    }
  }
}
    `;

/**
 * __useGetUserSaleListsForDisplayQuery__
 *
 * To run a query within a React component, call `useGetUserSaleListsForDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSaleListsForDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSaleListsForDisplayQuery({
 *   variables: {
 *      userHandle: // value for 'userHandle'
 *   },
 * });
 */
export function useGetUserSaleListsForDisplayQuery(baseOptions?: Apollo.QueryHookOptions<GetUserSaleListsForDisplayQuery, GetUserSaleListsForDisplayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSaleListsForDisplayQuery, GetUserSaleListsForDisplayQueryVariables>(GetUserSaleListsForDisplayDocument, options);
      }
export function useGetUserSaleListsForDisplayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSaleListsForDisplayQuery, GetUserSaleListsForDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSaleListsForDisplayQuery, GetUserSaleListsForDisplayQueryVariables>(GetUserSaleListsForDisplayDocument, options);
        }
export function useGetUserSaleListsForDisplaySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserSaleListsForDisplayQuery, GetUserSaleListsForDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserSaleListsForDisplayQuery, GetUserSaleListsForDisplayQueryVariables>(GetUserSaleListsForDisplayDocument, options);
        }
export type GetUserSaleListsForDisplayQueryHookResult = ReturnType<typeof useGetUserSaleListsForDisplayQuery>;
export type GetUserSaleListsForDisplayLazyQueryHookResult = ReturnType<typeof useGetUserSaleListsForDisplayLazyQuery>;
export type GetUserSaleListsForDisplaySuspenseQueryHookResult = ReturnType<typeof useGetUserSaleListsForDisplaySuspenseQuery>;
export type GetUserSaleListsForDisplayQueryResult = Apollo.QueryResult<GetUserSaleListsForDisplayQuery, GetUserSaleListsForDisplayQueryVariables>;
export const GetUserSaleListsForEditDocument = gql`
    query GetUserSaleListsForEdit($userHandle: String) {
  userSaleLists(userHandle: $userHandle) {
    id
    name
    items {
      id
      quantity
      priceInCents
      condition
      cardVariant {
        id
        variantType
        cardNumber
        card {
          id
          name
          standardFullCardNumber
          aspects
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserSaleListsForEditQuery__
 *
 * To run a query within a React component, call `useGetUserSaleListsForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSaleListsForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSaleListsForEditQuery({
 *   variables: {
 *      userHandle: // value for 'userHandle'
 *   },
 * });
 */
export function useGetUserSaleListsForEditQuery(baseOptions?: Apollo.QueryHookOptions<GetUserSaleListsForEditQuery, GetUserSaleListsForEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSaleListsForEditQuery, GetUserSaleListsForEditQueryVariables>(GetUserSaleListsForEditDocument, options);
      }
export function useGetUserSaleListsForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSaleListsForEditQuery, GetUserSaleListsForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSaleListsForEditQuery, GetUserSaleListsForEditQueryVariables>(GetUserSaleListsForEditDocument, options);
        }
export function useGetUserSaleListsForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserSaleListsForEditQuery, GetUserSaleListsForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserSaleListsForEditQuery, GetUserSaleListsForEditQueryVariables>(GetUserSaleListsForEditDocument, options);
        }
export type GetUserSaleListsForEditQueryHookResult = ReturnType<typeof useGetUserSaleListsForEditQuery>;
export type GetUserSaleListsForEditLazyQueryHookResult = ReturnType<typeof useGetUserSaleListsForEditLazyQuery>;
export type GetUserSaleListsForEditSuspenseQueryHookResult = ReturnType<typeof useGetUserSaleListsForEditSuspenseQuery>;
export type GetUserSaleListsForEditQueryResult = Apollo.QueryResult<GetUserSaleListsForEditQuery, GetUserSaleListsForEditQueryVariables>;
export const GetUserWantListsForDisplayDocument = gql`
    query GetUserWantListsForDisplay($userHandle: String) {
  userWantLists(userHandle: $userHandle) {
    id
    name
    items {
      nodes {
        id
        cardVariant {
          id
          variantType
          fullCardNumber
          card {
            id
            name
            aspects
            images {
              front {
                id
                url
                orientation
              }
            }
            standardFullCardNumber
            cardType
          }
        }
        quantity
      }
    }
  }
}
    `;

/**
 * __useGetUserWantListsForDisplayQuery__
 *
 * To run a query within a React component, call `useGetUserWantListsForDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWantListsForDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWantListsForDisplayQuery({
 *   variables: {
 *      userHandle: // value for 'userHandle'
 *   },
 * });
 */
export function useGetUserWantListsForDisplayQuery(baseOptions?: Apollo.QueryHookOptions<GetUserWantListsForDisplayQuery, GetUserWantListsForDisplayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWantListsForDisplayQuery, GetUserWantListsForDisplayQueryVariables>(GetUserWantListsForDisplayDocument, options);
      }
export function useGetUserWantListsForDisplayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWantListsForDisplayQuery, GetUserWantListsForDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWantListsForDisplayQuery, GetUserWantListsForDisplayQueryVariables>(GetUserWantListsForDisplayDocument, options);
        }
export function useGetUserWantListsForDisplaySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserWantListsForDisplayQuery, GetUserWantListsForDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserWantListsForDisplayQuery, GetUserWantListsForDisplayQueryVariables>(GetUserWantListsForDisplayDocument, options);
        }
export type GetUserWantListsForDisplayQueryHookResult = ReturnType<typeof useGetUserWantListsForDisplayQuery>;
export type GetUserWantListsForDisplayLazyQueryHookResult = ReturnType<typeof useGetUserWantListsForDisplayLazyQuery>;
export type GetUserWantListsForDisplaySuspenseQueryHookResult = ReturnType<typeof useGetUserWantListsForDisplaySuspenseQuery>;
export type GetUserWantListsForDisplayQueryResult = Apollo.QueryResult<GetUserWantListsForDisplayQuery, GetUserWantListsForDisplayQueryVariables>;
export const GetUserWantListsForEditDocument = gql`
    query GetUserWantListsForEdit($userHandle: String) {
  userWantLists(userHandle: $userHandle) {
    id
    name
    items {
      nodes {
        id
        quantity
        cardVariant {
          id
          variantType
          cardNumber
          card {
            id
            name
            standardFullCardNumber
            aspects
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserWantListsForEditQuery__
 *
 * To run a query within a React component, call `useGetUserWantListsForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWantListsForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWantListsForEditQuery({
 *   variables: {
 *      userHandle: // value for 'userHandle'
 *   },
 * });
 */
export function useGetUserWantListsForEditQuery(baseOptions?: Apollo.QueryHookOptions<GetUserWantListsForEditQuery, GetUserWantListsForEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWantListsForEditQuery, GetUserWantListsForEditQueryVariables>(GetUserWantListsForEditDocument, options);
      }
export function useGetUserWantListsForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWantListsForEditQuery, GetUserWantListsForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWantListsForEditQuery, GetUserWantListsForEditQueryVariables>(GetUserWantListsForEditDocument, options);
        }
export function useGetUserWantListsForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserWantListsForEditQuery, GetUserWantListsForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserWantListsForEditQuery, GetUserWantListsForEditQueryVariables>(GetUserWantListsForEditDocument, options);
        }
export type GetUserWantListsForEditQueryHookResult = ReturnType<typeof useGetUserWantListsForEditQuery>;
export type GetUserWantListsForEditLazyQueryHookResult = ReturnType<typeof useGetUserWantListsForEditLazyQuery>;
export type GetUserWantListsForEditSuspenseQueryHookResult = ReturnType<typeof useGetUserWantListsForEditSuspenseQuery>;
export type GetUserWantListsForEditQueryResult = Apollo.QueryResult<GetUserWantListsForEditQuery, GetUserWantListsForEditQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    nodes {
      id
      email
      handle
      roles
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetWantListShareSettingsDocument = gql`
    query GetWantListShareSettings($wantListId: ID!) {
  wantList(id: $wantListId) {
    id
    shareSettings {
      sharedWithAll
      sharedWithUsers {
        id
        handle
        email
      }
    }
  }
}
    `;

/**
 * __useGetWantListShareSettingsQuery__
 *
 * To run a query within a React component, call `useGetWantListShareSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWantListShareSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWantListShareSettingsQuery({
 *   variables: {
 *      wantListId: // value for 'wantListId'
 *   },
 * });
 */
export function useGetWantListShareSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetWantListShareSettingsQuery, GetWantListShareSettingsQueryVariables> & ({ variables: GetWantListShareSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWantListShareSettingsQuery, GetWantListShareSettingsQueryVariables>(GetWantListShareSettingsDocument, options);
      }
export function useGetWantListShareSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWantListShareSettingsQuery, GetWantListShareSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWantListShareSettingsQuery, GetWantListShareSettingsQueryVariables>(GetWantListShareSettingsDocument, options);
        }
export function useGetWantListShareSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWantListShareSettingsQuery, GetWantListShareSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWantListShareSettingsQuery, GetWantListShareSettingsQueryVariables>(GetWantListShareSettingsDocument, options);
        }
export type GetWantListShareSettingsQueryHookResult = ReturnType<typeof useGetWantListShareSettingsQuery>;
export type GetWantListShareSettingsLazyQueryHookResult = ReturnType<typeof useGetWantListShareSettingsLazyQuery>;
export type GetWantListShareSettingsSuspenseQueryHookResult = ReturnType<typeof useGetWantListShareSettingsSuspenseQuery>;
export type GetWantListShareSettingsQueryResult = Apollo.QueryResult<GetWantListShareSettingsQuery, GetWantListShareSettingsQueryVariables>;