import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Skeleton from '../layout/Skeleton';

interface CardSetSkeletonProps {
    viewMode: 'list' | 'image';
}

const CardSetSkeleton: React.FC<CardSetSkeletonProps> = ({ viewMode }) => {
    if (viewMode === 'list') {
        return (
            <div className="space-table-container">
                <table className="space-table">
                    <thead className="space-table-header">
                        <tr>
                            {['ID', 'Name', 'Rarity', 'Cost', 'Power', 'HP', 'Type', 'Arena', 'Aspects', 'Traits', 'Keywords'].map((header, index) => (
                                <th key={index}><Skeleton height="24px" /></th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(20)].map((_, index) => (
                            <tr key={index}>
                                {[...Array(11)].map((_, cellIndex) => (
                                    <td key={cellIndex}><Skeleton height="20px" /></td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    const placeholderCount = 24; // Multiple of 6 (xl breakpoint columns)

    return (
        <Row className="g-2 card-image-grid">
            {[...Array(placeholderCount)].map((_, index) => (
                <Col
                    key={index}
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                    className="mb-2"
                >
                    <div className="card-image-container">
                        <div className="card-image-skeleton landscape">
                            <div className="card-image-placeholder"></div>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

export default CardSetSkeleton;
