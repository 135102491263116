import React from 'react';
import { Button } from 'react-bootstrap';
import { initiateGoogleOAuth } from '../../utils/auth';

interface NavLoginButtonProps {
    from: string;
}

const NavLoginButton: React.FC<NavLoginButtonProps> = ({ from }) => {
    const handleLogin = async () => {
        try {
            await initiateGoogleOAuth(from);
        } catch (error) {
            console.error('Login failed:', error);
            // You might want to show an error message to the user here
        }
    };

    return (
        <Button variant="outline-light" onClick={handleLogin}>
            Login
        </Button>
    );
};

export default NavLoginButton;
