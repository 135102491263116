import { useGetAspectsQuery, Aspect } from '../graphql/generated/graphql';
import { useMemo } from 'react';

export const useAspects = () => {
    const { data, loading, error } = useGetAspectsQuery();

    const aspects = useMemo(() => {
        return data?.aspects.map((aspect: Aspect) => ({
            value: aspect.aspectType,
            label: aspect.aspectType,
            image: aspect.image.url
        })) || [];
    }, [data]);

    return { aspects, loading, error };
};
