import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

interface CardSearchProps {
    searchTerm: string;
    onSearchChange: (term: string) => void;
    showExtrasOnly: boolean;
    onShowExtrasOnlyChange: (show: boolean) => void;
    selectedSet: string;
}

const CardSearch: React.FC<CardSearchProps> = ({
    searchTerm,
    onSearchChange,
    showExtrasOnly,
    onShowExtrasOnlyChange,
    selectedSet
}) => {
    const filtersDisabled = !selectedSet;

    return (
        <Row className="mb-4">
            <Col md={6}>
                <Form.Group>
                    <Form.Label className="text-space-white">Search Cards</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Search for cards..."
                        value={searchTerm}
                        onChange={(e) => onSearchChange(e.target.value)}
                        className={`bg-space-dark text-space-white border-space-blue search-input ${filtersDisabled ? 'disabled' : ''}`}
                        disabled={filtersDisabled}
                    />
                </Form.Group>
            </Col>
            <Col md={6} className="d-flex align-items-end justify-content-end">
                <Form.Check
                    type="switch"
                    id="extras-only-switch"
                    label="Show Extras Only"
                    checked={showExtrasOnly}
                    onChange={(e) => onShowExtrasOnlyChange(e.target.checked)}
                    className="text-space-white"
                    disabled={filtersDisabled}
                />
            </Col>
        </Row>
    );
};

export default CardSearch;
