import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import {
    CardSetCodeEnum,
    CardVariantTypeEnum,
    SaleList,
    useGetUserCardSetCollectionQuery,
} from '../../../graphql/generated/graphql';
import CardTableHeader from './CardTableHeader';
import CardTableRow from './CardTableRow';
import { CardData, CardVariantData } from '../../../types/editSaleListTypes';

interface CardTableProps {
    selectedSet: CardSetCodeEnum | null;
    searchTerm: string;
    showExtrasOnly: boolean;
    effectiveHandle: string | undefined;
    onItemUpdated: () => void;
    existingSaleList: SaleList;
}

const CardTable: React.FC<CardTableProps> = ({
    selectedSet,
    searchTerm,
    showExtrasOnly,
    effectiveHandle,
    onItemUpdated,
    existingSaleList
}) => {
    const { data, loading, error } = useGetUserCardSetCollectionQuery({
        variables: {
            userHandle: effectiveHandle,
            cardSetCode: selectedSet!
        },
        skip: !effectiveHandle || !selectedSet
    });

    const cardsData = useMemo(() => {
        if (!data?.userCardSetCollection?.nodes) return [];

        const cards: CardData[] = data.userCardSetCollection.nodes.map(node => {
            if (!node) return null;

            const variantMapping = {
                [CardVariantTypeEnum.Standard]: node.standardInventory,
                [CardVariantTypeEnum.Foil]: node.foilInventory,
                [CardVariantTypeEnum.Hyperspace]: node.hyperspaceInventory,
                [CardVariantTypeEnum.HyperspaceFoil]: node.hyperspaceFoilInventory,
                [CardVariantTypeEnum.Showcase]: node.showcaseInventory,
            };

            const variants: Partial<Record<CardVariantTypeEnum, CardVariantData>> = {};

            Object.entries(variantMapping).forEach(([type, inventory]) => {
                if (inventory?.variantId) {
                    variants[type as CardVariantTypeEnum] = {
                        id: inventory.variantId.toString(),
                        extraCount: inventory.extraCount || 0,
                        saleCount: 0,
                    };
                }
            });

            return {
                id: node.id,
                name: node.cardDetails.name,
                standardCardNumber: node.cardDetails.cardNumber,
                variants,
            };
        }).filter((card): card is CardData => card !== null);

        // Add sale list information
        const saleListItems = existingSaleList?.items || [];
        saleListItems.forEach(item => {
            if (item?.cardVariant?.card?.id && item.cardVariant.variantType) {
                const card = cards.find(c => c.id === item.cardVariant.card.id);
                if (card && card.variants[item.cardVariant.variantType]) {
                    card.variants[item.cardVariant.variantType].saleCount = item.quantity;
                }
            }
        });

        return cards;
    }, [data?.userCardSetCollection, existingSaleList]);

    const filteredCards = useMemo(() => {
        return cardsData.filter(card => {
            const nameMatch = card.name.toLowerCase().includes(searchTerm.toLowerCase());
            if (showExtrasOnly) {
                const isExtra = Object.values(card.variants).some(variant => variant.extraCount > 0);
                return nameMatch && isExtra;
            }
            return nameMatch;
        });
    }, [cardsData, searchTerm, showExtrasOnly]);

    const variantColumns = [
        CardVariantTypeEnum.Standard,
        CardVariantTypeEnum.Foil,
        CardVariantTypeEnum.Hyperspace,
        CardVariantTypeEnum.HyperspaceFoil,
        CardVariantTypeEnum.Showcase
    ];

    if (!selectedSet) {
        return (
            <div className="text-center text-space-white p-5">
                <h3>Please select a card set to begin.</h3>
            </div>
        );
    }

    if (loading) return <Spinner animation="border" variant="primary" />;
    if (error) return <div>Error: {error.message}</div>;

    const saleListId = existingSaleList?.id;

    return (
        <div className="space-table-wrapper">
            <table className="space-table">
                <CardTableHeader variantColumns={variantColumns} />
                <tbody>
                    {filteredCards.map((card: CardData) => (
                        <CardTableRow
                            key={card.id}
                            card={card}
                            variantColumns={variantColumns}
                            saleListId={saleListId || ''}
                            showExtrasOnly={showExtrasOnly}
                            onItemUpdated={onItemUpdated}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CardTable;
