import React, { useState } from 'react';
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTrash, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { CardVariantTypeEnum, SaleListItemConditionEnum, useUpsertSaleListVariantMutation } from '../../../graphql/generated/graphql';
import { getCardVariantTypeDisplay } from '../../../utils/cardUtils';
import { CardData } from '../../../types/editSaleListTypes';

interface EditableSaleCardProps {
    saleCard: CardData;
    saleListId: string;
    onItemUpdated: () => void;
}

const EditableSaleCard: React.FC<EditableSaleCardProps> = ({ saleCard, saleListId, onItemUpdated }) => {
    const [updateSaleListVariantCount] = useUpsertSaleListVariantMutation();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [variantToRemove, setVariantToRemove] = useState<{ id: string; type: string } | null>(null);
    const [localVariants, setLocalVariants] = useState(() => {
        const variants: Record<string, {
            saleCount: number;
            priceInCents: number;
            condition: SaleListItemConditionEnum;
        }> = {};

        Object.entries(saleCard.variants).forEach(([, variantData]) => {
            variants[variantData.id] = {
                saleCount: variantData.saleCount,
                priceInCents: variantData.priceInCents,
                condition: variantData.condition
            };
        });
        return variants;
    });

    const getAspectClasses = (aspects: string[] | null | undefined): string => {
        if (!aspects || aspects.length === 0) return 'aspect-none';
        const priorityAspects = aspects.filter(aspect => !['villainy', 'heroism'].includes(aspect.toLowerCase()));
        if (priorityAspects.length > 0) {
            return `aspect-${priorityAspects[0].toLowerCase()}`;
        }
        return `aspect-${aspects[0].toLowerCase()}`;
    };

    const handleUpdate = async (variantId: string, newQuantity: number, priceInCents: number, condition: SaleListItemConditionEnum) => {
        try {
            if (newQuantity < 1) return;

            setLocalVariants(prev => ({
                ...prev,
                [variantId]: {
                    saleCount: newQuantity,
                    priceInCents,
                    condition
                }
            }));

            await updateSaleListVariantCount({
                variables: {
                    saleListId,
                    cardVariantId: variantId,
                    quantity: newQuantity,
                    priceInCents,
                    condition
                }
            });
            onItemUpdated();
        } catch (error) {
            console.error('Failed to update sale list item:', error);
        }
    };

    const handleRemoveClick = (variantId: string, variantType: string) => {
        setVariantToRemove({ id: variantId, type: variantType });
        setShowConfirmModal(true);
    };

    const handleConfirmRemove = async () => {
        if (!variantToRemove) return;

        try {
            await updateSaleListVariantCount({
                variables: {
                    saleListId,
                    cardVariantId: variantToRemove.id,
                    quantity: 0,
                    priceInCents: localVariants[variantToRemove.id].priceInCents,
                    condition: localVariants[variantToRemove.id].condition
                }
            });
            onItemUpdated();
            setShowConfirmModal(false);
        } catch (error) {
            console.error('Failed to remove variant:', error);
        }
    };

    return (
        <>
            <div className={`sale-card ${getAspectClasses(saleCard.aspects)}`}>
                <div className="sale-card-header">
                    <span className="sale-card-number">{saleCard.standardFullCardNumber}</span>
                    <h3 className="sale-card-name">{saleCard.name}</h3>
                </div>
                <div className="sale-card-variants">
                    {Object.entries(saleCard.variants).map(([variantType, variantData]) => {
                        const localData = localVariants[variantData.id];
                        return (
                            <div key={variantData.id} className="sale-card-variant">
                                <div className="variant-header">
                                    <span className="variant-type">
                                        {getCardVariantTypeDisplay(variantType as CardVariantTypeEnum)}
                                    </span>
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => handleRemoveClick(variantData.id, variantType)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </div>
                                <div className="variant-controls">
                                    <div className="quantity-controls">
                                        <Button
                                            variant="outline-space-blue"
                                            size="sm"
                                            onClick={() => handleUpdate(
                                                variantData.id,
                                                localData.saleCount - 1,
                                                localData.priceInCents,
                                                localData.condition
                                            )}
                                            disabled={localData.saleCount <= 1}
                                        >
                                            <FontAwesomeIcon icon={faMinus} />
                                        </Button>
                                        <span>{localData.saleCount}</span>
                                        <Button
                                            variant="outline-space-blue"
                                            size="sm"
                                            onClick={() => handleUpdate(
                                                variantData.id,
                                                localData.saleCount + 1,
                                                localData.priceInCents,
                                                localData.condition
                                            )}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </div>
                                    <div className="price-condition-controls">
                                        <InputGroup size="sm" className="price-input">
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faDollarSign} />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                step="0.01"
                                                min="0"
                                                value={(localData.priceInCents / 100).toFixed(2)}
                                                onChange={(e) => handleUpdate(
                                                    variantData.id,
                                                    localData.saleCount,
                                                    Math.round(parseFloat(e.target.value) * 100) || 0,
                                                    localData.condition
                                                )}
                                                className="space-input"
                                            />
                                        </InputGroup>
                                        <Form.Select
                                            size="sm"
                                            className="condition-select"
                                            value={localData.condition}
                                            onChange={(e) => handleUpdate(
                                                variantData.id,
                                                localData.saleCount,
                                                localData.priceInCents,
                                                e.target.value as SaleListItemConditionEnum
                                            )}
                                        >
                                            {Object.values(SaleListItemConditionEnum).map((condition) => (
                                                <option key={condition} value={condition}>
                                                    {condition.replace('_', ' ')}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                className="custom-modal"
            >
                <Modal.Header closeButton className="bg-space-darker border-space">
                    <Modal.Title className="text-space-white">Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-space-darker text-space-white">
                    Are you sure you want to remove the {variantToRemove && getCardVariantTypeDisplay(variantToRemove.type as CardVariantTypeEnum)} variant of {saleCard.name} from your sale list?
                </Modal.Body>
                <Modal.Footer className="bg-space-darker border-space">
                    <Button variant="outline-space-blue" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="outline-danger" onClick={handleConfirmRemove}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditableSaleCard;
