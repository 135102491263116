import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner, Nav } from 'react-bootstrap';
import { CardVariantTypeEnum, OrientationTypeEnum } from '../../graphql/generated/graphql';
import CardImage from './CardImage';
import { uniqBy, capitalize } from 'lodash';

interface CardImage {
    id: string;
    url?: string | null;
    orientation: OrientationTypeEnum;
}

interface CardVariant {
    id: string;
    variantType?: CardVariantTypeEnum | null;
    images: {
        front: CardImage;
        back?: CardImage | null;
    };
}

interface CardModalData {
    id: string;
    name: string;
    images: {
        front: CardImage;
        back?: CardImage | null;
    };
    cardVariants?: CardVariant[];
}

interface CardModalProps {
    card: CardModalData | null | undefined;
    loading: boolean;
    onClose: () => void;
}

const filterVariants = (variants: CardVariant[]): CardVariant[] => {
    const uniqueVariants = uniqBy(variants, 'images.front.url');
    return uniqueVariants.map(variant => {
        const allVariantsForImage = variants.filter(v => v.images.front.url === variant.images.front.url);
        const nonFoilVariant = allVariantsForImage.find(v =>
            v.variantType === CardVariantTypeEnum.Standard ||
            v.variantType === CardVariantTypeEnum.Hyperspace
        );
        return nonFoilVariant || variant;
    });
};

const CardModal: React.FC<CardModalProps> = ({ card, loading, onClose }) => {
    const [activeVariant, setActiveVariant] = useState(0);
    const [filteredVariants, setFilteredVariants] = useState<CardVariant[]>([]);
    const [showBackImage, setShowBackImage] = useState(false);

    const handleFlip = () => {
        setShowBackImage(!showBackImage);
    };

    useEffect(() => {
        if (card && card.cardVariants) {
            const filtered = filterVariants(card.cardVariants);
            setFilteredVariants(filtered);
        }
    }, [card]);

    if (loading) {
        return (
            <Modal show={true} onHide={onClose} dialogClassName="card-modal" centered>
                <Modal.Header closeButton className="bg-space-dark-blue text-space-white">
                    <Modal.Title>Loading...</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center bg-body-bg">
                    <Spinner animation="border" variant="primary" />
                </Modal.Body>
            </Modal>
        );
    }

    if (!card) {
        return (
            <Modal show={true} onHide={onClose} dialogClassName="card-modal" centered>
                <Modal.Header closeButton className="bg-space-dark-blue text-space-white">
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-body-bg text-text">
                    <p>No card data available</p>
                </Modal.Body>
                <Modal.Footer className="bg-space-dark-blue">
                    <Button variant="outline-space-gold" onClick={onClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }


    const hasBackImage = card.images.back !== null;
    const renderCardImage = (variant: CardVariant) => {
        const frontIsLandscape = variant.images.front.orientation === OrientationTypeEnum.Landscape;
        const backIsLandscape = variant.images.back?.orientation === OrientationTypeEnum.Landscape;
        const sameOrientation = frontIsLandscape === backIsLandscape;

        // Determine the current orientation based on which side is showing
        const currentOrientation = showBackImage && variant.images.back
            ? (backIsLandscape ? 'landscape' : 'portrait')
            : (frontIsLandscape ? 'landscape' : 'portrait');

        const containerClass = `card-image-container-modal ${currentOrientation}`;
        const flipperClass = `card-flipper ${showBackImage ? 'flipped' : ''} ${sameOrientation ? 'same-orientation' : 'different-orientation'}`;

        return (
            <div className={containerClass}>
                <div className={flipperClass}>
                    <div className="card-face front">
                        <CardImage
                            image={variant.images.front}
                            name={card.name}
                        />
                    </div>
                    {variant.images.back && (
                        <div className="card-face back">
                            <CardImage
                                image={variant.images.back}
                                name={`${card.name} (Back)`}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <Modal show={true} onHide={onClose} dialogClassName="card-modal" centered>
            <Modal.Header closeButton closeVariant="white" className="bg-space-dark-blue text-space-white">
                <Modal.Title>{card?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-bg">
                {filteredVariants.length > 1 ? (
                    <>
                        <Nav variant="tabs" className="custom-tabs mb-3">
                            {filteredVariants.map((variant, index) => (
                                <Nav.Item key={index}>
                                    <Nav.Link
                                        active={activeVariant === index}
                                        onClick={() => setActiveVariant(index)}
                                        className={`custom-tab ${activeVariant === index ? 'active' : ''}`}
                                    >
                                        {capitalize(variant.variantType || '')}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        {renderCardImage(filteredVariants[activeVariant])}
                    </>
                ) : (
                    renderCardImage(filteredVariants[0] || card?.cardVariants?.[0])
                )}
                {hasBackImage && (
                    <div className="text-center mt-3">
                        <Button
                            variant="outline-space-blue"
                            size="sm"
                            onClick={handleFlip}
                            className="toggle-image-btn"
                        >
                            {showBackImage ? "Show Front" : "Show Back"}
                        </Button>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default CardModal;
