import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDollarSign, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useCreateOfferMutation, CardVariantTypeEnum, OfferTypeEnum } from '../../graphql/generated/graphql';
import { getCardVariantTypeDisplay } from '../../utils/cardUtils';

interface CreateSellOfferModalProps {
    show: boolean;
    onHide: () => void;
    recipientHandle: string;
    wantListItems: Array<{
        id: string;
        cardDetails: {
            name: string;
            standardFullCardNumber: string;
        };
        variantCounts: Array<{
            variantId: string;
            variantType: string;
            count: number;
        }>;
    }>;
}

interface OfferItem {
    cardId: string;
    cardName: string;
    cardNumber: string;
    variantId: string;
    variantType: CardVariantTypeEnum;
    quantity: number;
    price: number;
    maxQuantity: number;
}

const CreateSellOfferModal: React.FC<CreateSellOfferModalProps> = ({
    show,
    onHide,
    recipientHandle,
    wantListItems
}) => {
    const [createOffer] = useCreateOfferMutation();
    const [selectedItems, setSelectedItems] = useState<OfferItem[]>([]);
    const [note, setNote] = useState<string>('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const isVariantSelected = (variantId: string) => {
        return selectedItems.some(item => item.variantId === variantId);
    };

    const areAllVariantsSelected = (item: typeof wantListItems[0]) => {
        return item.variantCounts.every(variant => isVariantSelected(variant.variantId));
    };

    const getAvailableVariants = (cardId: string, currentVariantId: string) => {
        const cardItem = wantListItems.find(w => w.id === cardId);
        if (!cardItem) return [];

        const selectedVariantIds = selectedItems
            .filter(item => item.cardId === cardId)
            .map(item => item.variantId);

        return cardItem.variantCounts.filter(v =>
            v.variantId === currentVariantId || !selectedVariantIds.includes(v.variantId)
        );
    };

    const handleAddVariant = (item: typeof wantListItems[0], variant: typeof item.variantCounts[0]) => {
        setSelectedItems(prev => [...prev, {
            cardId: item.id,
            cardName: item.cardDetails.name,
            cardNumber: item.cardDetails.standardFullCardNumber,
            variantId: variant.variantId,
            variantType: variant.variantType as CardVariantTypeEnum,
            quantity: 1,
            price: 0,
            maxQuantity: variant.count
        }]);
    };

    const handleRemoveCard = (index: number) => {
        setSelectedItems(prev => prev.filter((_, i) => i !== index));
    };

    const groupSelectedItemsByCard = (items: OfferItem[]) => {
        return Object.values(items.reduce((acc, item) => {
            if (!acc[item.cardId]) {
                acc[item.cardId] = {
                    cardId: item.cardId,
                    cardName: item.cardName,
                    cardNumber: item.cardNumber,
                    variants: []
                };
            }
            acc[item.cardId].variants.push(item);
            return acc;
        }, {} as Record<string, { cardId: string; cardName: string; cardNumber: string; variants: OfferItem[] }>));
    };

    const handleVariantChange = (index: number, variantId: string) => {
        const item = wantListItems.find(item =>
            item.variantCounts.some(v => v.variantId === variantId)
        );
        const variant = item?.variantCounts.find(v => v.variantId === variantId);

        if (!variant) return;

        setSelectedItems(prev => prev.map((item, i) =>
            i === index ? {
                ...item,
                variantId,
                variantType: variant.variantType as CardVariantTypeEnum,
                maxQuantity: variant.count,
                quantity: Math.min(item.quantity, variant.count)
            } : item
        ));
    };

    const handlePriceChange = (index: number, value: string) => {
        const price = parseFloat(value);
        const newErrors = { ...errors };

        if (isNaN(price) || price < 1 || price > 2000) {
            newErrors[`price-${index}`] = 'Price must be between $1 and $2000';
        } else {
            delete newErrors[`price-${index}`];
        }

        setErrors(newErrors);
        setSelectedItems(prev =>
            prev.map((i, idx) =>
                idx === index ? { ...i, price } : i
            )
        );
    };

    const handleSubmit = async () => {
        try {
            await createOffer({
                variables: {
                    input: {
                        recipientHandle,
                        offerItems: selectedItems.map(item => ({
                            variantId: item.variantId,
                            quantity: item.quantity,
                            priceInCents: item.price * 100
                        })),
                        offerType: OfferTypeEnum.Sell,
                        note
                    }
                }
            });
            onHide();
        } catch (error) {
            console.error('Error creating offer:', error);
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg" centered className="offer-modal">
            <Modal.Header closeButton>
                <Modal.Title className="text-space-gold">Create Offer to Sell</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex gap-4">
                    {/* Left Side Section */}
                    <div className="flex-1">
                        <h5 className="text-space-blue mb-3">Available Cards</h5>
                        <div className="scrollable-section">
                            {wantListItems.map(item => (
                                <div key={item.id} className={`available-offer-card ${areAllVariantsSelected(item) ? 'opacity-50' : ''}`}>
                                    <div className="available-offer-card-header">
                                        <span className="card-number">{item.cardDetails.standardFullCardNumber}</span>
                                        <h6 className="card-name">{item.cardDetails.name}</h6>
                                    </div>
                                    <div className="variant-counts d-flex flex-column gap-1">
                                        {item.variantCounts.map(variant => (
                                            <div key={variant.variantId}
                                                className={`available-offer-card-variant ${isVariantSelected(variant.variantId) ? 'opacity-50' : ''}`}
                                            >
                                                <div className="variant-header">
                                                    <span className="variant-type">
                                                        {getCardVariantTypeDisplay(variant.variantType as CardVariantTypeEnum)}: {variant.count}
                                                    </span>
                                                    {!isVariantSelected(variant.variantId) && (
                                                        <Button
                                                            variant="outline-space-blue"
                                                            size="sm"
                                                            onClick={() => handleAddVariant(item, variant)}
                                                            className="btn-icon"
                                                        >
                                                            <FontAwesomeIcon icon={faArrowRight} />
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="border-end border-secondary-subtle"></div>

                    {/* Right Side Section */}
                    <div className="flex-1">
                        <h5 className="text-space-blue mb-3">Offer Items</h5>
                        <div className="scrollable-section right-section">
                            {groupSelectedItemsByCard(selectedItems).map(groupedItem => (
                                <div key={groupedItem.cardId} className="offer-card">
                                    <div className="offer-card-header">
                                        <span className="card-number">{groupedItem.cardNumber}</span>
                                        <h6 className="card-name">{groupedItem.cardName}</h6>
                                    </div>
                                    <div className="offer-card-variants">
                                        {groupedItem.variants
                                            .sort((a, b) => {
                                                const variantOrder = [
                                                    CardVariantTypeEnum.Standard,
                                                    CardVariantTypeEnum.Foil,
                                                    CardVariantTypeEnum.Hyperspace,
                                                    CardVariantTypeEnum.HyperspaceFoil
                                                ];
                                                return variantOrder.indexOf(a.variantType) - variantOrder.indexOf(b.variantType);
                                            })
                                            .map((item) => {
                                                const index = selectedItems.findIndex(i =>
                                                    i.variantId === item.variantId && i.cardId === groupedItem.cardId
                                                );

                                                return (
                                                    <div key={item.variantId} className="offer-card-variant">
                                                        <div className="variant-controls">
                                                            <div className="variant-detail-controls">
                                                                {getAvailableVariants(item.cardId, item.variantId).length === 1 ? (
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={getCardVariantTypeDisplay(item.variantType)}
                                                                        readOnly
                                                                        className="variant-type-input"
                                                                    />
                                                                ) : (
                                                                    <Form.Select
                                                                        value={item.variantId}
                                                                        onChange={(e) => handleVariantChange(index, e.target.value)}
                                                                        className="variant-type-select"
                                                                    >
                                                                        {getAvailableVariants(item.cardId, item.variantId).map(v => (
                                                                            <option key={v.variantId} value={v.variantId}>
                                                                                {getCardVariantTypeDisplay(v.variantType as CardVariantTypeEnum)}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                )}

                                                                {item.maxQuantity === 1 ? (
                                                                    <Form.Control
                                                                        type="text"
                                                                        value="1"
                                                                        readOnly
                                                                        className="quantity-input"
                                                                    />
                                                                ) : (
                                                                    <Form.Select
                                                                        value={item.quantity}
                                                                        onChange={(e) => setSelectedItems(prev =>
                                                                            prev.map((i, idx) =>
                                                                                idx === index ? { ...i, quantity: parseInt(e.target.value) } : i
                                                                            )
                                                                        )}
                                                                        className="quantity-input"
                                                                    >
                                                                        {[...Array(item.maxQuantity)].map((_, i) => (
                                                                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                )}

                                                                <div className="price-input">
                                                                    <InputGroup>
                                                                        <InputGroup.Text>
                                                                            <FontAwesomeIcon icon={faDollarSign} />
                                                                        </InputGroup.Text>
                                                                        <Form.Control
                                                                            type="number"
                                                                            min={0.01}
                                                                            max={2000}
                                                                            step="0.01"
                                                                            value={item.price || ''}
                                                                            onChange={(e) => handlePriceChange(index, e.target.value)}
                                                                            isInvalid={!!errors[`price-${index}`]}
                                                                        />
                                                                    </InputGroup>
                                                                </div>
                                                                <Button
                                                                    variant="outline-danger"
                                                                    size="sm"
                                                                    className="btn-icon"
                                                                    onClick={() => handleRemoveCard(index)}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </Button>
                                                            </div>
                                                            {errors[`price-${index}`] && (
                                                                <div className="text-danger mt-1">
                                                                    {errors[`price-${index}`]}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-column align-items-stretch">
                <Form.Group className="mb-3 w-100">
                    <Form.Label className="text-space-light-blue">Note</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Add a note to the offer. For example, shipping costs, offer to meet locally, bundle deals,etc..."
                        className="form-control textarea"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </Form.Group>
                <div className="d-flex justify-content-end">
                    <Button
                        variant="outline-space-blue"
                        onClick={handleSubmit}
                        disabled={selectedItems.length === 0 || selectedItems.some(item => !item.price)}
                    >
                        Create Offer
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateSellOfferModal;

