import React from 'react';
import Select from 'react-select';

interface CostFilterProps {
    costs: number[];
    selectedCosts: number[];
    onChange: (selectedCosts: number[]) => void;
    disabled?: boolean;
}

const CostFilter: React.FC<CostFilterProps> = ({ costs, selectedCosts, onChange, disabled }) => {
    const costOptions = costs.map(cost => ({ value: cost, label: cost.toString() }));
    const value = selectedCosts.map(cost => ({ value: cost, label: cost.toString() }));

    const handleChange = (selected: readonly { value: number; label: string }[]) => {
        onChange(selected.map(option => option.value));
    };

    return (
        <div className="card-filters__section">
            <label className="card-filters__section-title">Cost</label>
            <Select
                isMulti
                options={costOptions}
                value={value}
                onChange={handleChange}
                placeholder="Select costs..."
                className="card-filters__select"
                classNamePrefix="card-filters__select"
                isDisabled={disabled}
            />
        </div>
    );
};

export default CostFilter;
