import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

interface EmptyWantListProps {
    isOwnProfile: boolean;
    user: string | undefined;
    isAuthenticated: boolean;
}

const EmptyWantList: React.FC<EmptyWantListProps> = ({ isOwnProfile, user, isAuthenticated }) => {
    return (
        <Container className="empty-want-list text-center py-5">
            <Row className="justify-content-center">
                <Col md={10} lg={8}>
                    <div className="empty-want-list__content bg-space-dark p-5 rounded-lg shadow-lg">
                        <FontAwesomeIcon icon={faSearch} size="4x" className="text-space-blue mb-4" />
                        {isOwnProfile ? (
                            <>
                                <h2 className="text-space-gold mb-3">You don&apos;t have a want list yet</h2>
                                <p className="text-space-light mb-4">
                                    Start building your want list by adding cards you&apos;re looking for.
                                    This helps others know what you&apos;re interested in trading or acquiring!
                                </p>
                                {isAuthenticated && (
                                    <div className="empty-want-list__actions d-flex flex-column flex-md-row justify-content-center align-items-center">
                                        <Link to="/collections/my/want/edit" className="text-decoration-none mb-3 mb-md-0 me-md-3">
                                            <Button variant="outline-space-blue">
                                                <FontAwesomeIcon icon={faPlus} className="me-2" />
                                                Add Cards to Your Want List
                                            </Button>
                                        </Link>
                                        <Link to="/collections/my" className="text-decoration-none">
                                            <Button variant="outline-space-gold">
                                                Go to Your Collection
                                                <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                                            </Button>
                                        </Link>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <h2 className="text-space-gold mb-3">{user} doesn&apos;t have a want list yet</h2>
                                <p className="text-space-light">Check back later to see what cards they&apos;re looking for!</p>
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default EmptyWantList;
