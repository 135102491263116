import React, { useState } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CardSetCodeEnum, useGetUserSaleListsForEditQuery } from '../../../graphql/generated/graphql';
import CardSetSelector from './CardSetSelector';
import CardSearch from './CardSearch';
import CardTable from './CardTable';
import SaleListItemsTable from './SaleListItemsTable';

interface SaleListEditorProps {
    effectiveHandle: string | undefined;
    onItemsUpdated?: () => void;
}

const SaleListEditor: React.FC<SaleListEditorProps> = ({
    effectiveHandle,
    onItemsUpdated
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedSet, setSelectedSet] = useState<CardSetCodeEnum | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showExtrasOnly, setShowExtrasOnly] = useState(false);

    const { data, loading, refetch } = useGetUserSaleListsForEditQuery({
        variables: { userHandle: effectiveHandle || '' },
        skip: !effectiveHandle
    });

    const handleItemsUpdated = () => {
        refetch();
        onItemsUpdated?.();
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedSet(null);
        setSearchTerm('');
        setShowExtrasOnly(false);
    };

    if (loading || !data?.userSaleLists?.[0]) return null;

    const saleList = data.userSaleLists[0];

    return (
        <Container className="sale-list-editor">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <Button
                    variant="outline-space-blue"
                    onClick={() => setShowModal(true)}
                >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add Cards
                </Button>
            </div>

            <SaleListItemsTable
                items={(saleList.items?.filter((item): item is NonNullable<typeof item> => item !== null) ?? [])
                    .map(item => ({
                        id: item.id,
                        saleListId: saleList.id,
                        quantity: item.quantity,
                        priceInCents: item.priceInCents,
                        condition: item.condition,
                        cardVariant: {
                            id: item.cardVariant.id,
                            cardNumber: item.cardVariant.cardNumber,
                            variantType: item.cardVariant.variantType,
                            card: {
                                id: item.cardVariant.card.id,
                                name: item.cardVariant.card.name,
                                standardFullCardNumber: item.cardVariant.card.standardFullCardNumber,
                                aspects: item.cardVariant.card.aspects || []
                            }
                        }
                    }))}
                saleListId={saleList.id}
                onItemUpdated={handleItemsUpdated}
            />

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size="xl"
                className="custom-modal"
            >
                <Modal.Header closeButton className="bg-space-darker border-space">
                    <Modal.Title className="text-space-white">Add Cards to Sale List</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-space-dark p-4">
                    <div className="mb-4">
                        <CardSetSelector
                            selectedSet={selectedSet ?? CardSetCodeEnum.Sor}
                            onSelectSet={setSelectedSet}
                        />
                    </div>
                    <div className="mb-4">
                        <CardSearch
                            selectedSet={selectedSet ?? CardSetCodeEnum.Sor}
                            searchTerm={searchTerm}
                            onSearchChange={setSearchTerm}
                            showExtrasOnly={showExtrasOnly}
                            onShowExtrasOnlyChange={setShowExtrasOnly}
                        />
                    </div>
                    <div className="space-table-container">
                        <CardTable
                            selectedSet={selectedSet ?? CardSetCodeEnum.Sor}
                            searchTerm={searchTerm}
                            showExtrasOnly={showExtrasOnly}
                            effectiveHandle={effectiveHandle}
                            onItemUpdated={handleItemsUpdated}
                            existingSaleList={saleList}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default SaleListEditor;
