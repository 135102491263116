import React from 'react';
import { Container, Alert } from 'react-bootstrap';

interface NotFoundProps {
    message?: string;
}

const NotFound: React.FC<NotFoundProps> = ({ message = "Page not found" }) => {
    return (
        <Container className="mt-5">
            <Alert variant="warning">
                <Alert.Heading>404 - Not Found</Alert.Heading>
                <p>{message}</p>
            </Alert>
        </Container>
    );
};

export default NotFound;
