import React, { useState } from 'react';
import { Container, Nav, Button, Card } from 'react-bootstrap';
import OfferList from './trade/OfferList';
import SharedWantLists from './trade/SharedWantLists';
import SharedSaleLists from './trade/SharedSaleLists';
import { useUser } from '../hooks/useUser';
import HomeBannerCard from './HomeBannerCard';
import { OfferTypeEnum, OfferRoleEnum } from '../graphql/generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

enum TradingHubTab {
    Offers = 'Offers',  // Changed to combine Sent/Received into one tab
    SharedWantLists = 'SharedWantLists',
    SharedSaleLists = 'SharedSaleLists',
}

const TradingHub: React.FC = () => {
    const { isAuthenticated, hasHandle } = useUser();
    const [activeTab, setActiveTab] = useState<TradingHubTab>(TradingHubTab.SharedWantLists);
    const [activeRole, setActiveRole] = useState<OfferRoleEnum>(OfferRoleEnum.Recipient);
    const [typeFilter, setTypeFilter] = useState<OfferTypeEnum | undefined>();

    const canTrade = isAuthenticated && hasHandle;

    const renderTab = (tab: TradingHubTab, label: string) => {
        const isActive = activeTab === tab;
        const isDisabled = tab !== TradingHubTab.SharedSaleLists &&
            tab !== TradingHubTab.SharedWantLists &&
            !canTrade;

        return (
            <Nav.Item>
                <Nav.Link
                    className={`custom-tab ${isActive ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
                    onClick={() => !isDisabled && setActiveTab(tab)}
                    disabled={isDisabled}
                >
                    {label}
                </Nav.Link>
            </Nav.Item>
        );
    };

    const renderOfferControls = () => (
        <div className="mb-3 d-flex gap-3 align-items-center">
            <div className="btn-group">
                <Button
                    variant={activeRole === OfferRoleEnum.Recipient ? 'primary' : 'outline-primary'}
                    onClick={() => setActiveRole(OfferRoleEnum.Recipient)}
                >
                    Received
                </Button>
                <Button
                    variant={activeRole === OfferRoleEnum.Initiator ? 'primary' : 'outline-primary'}
                    onClick={() => setActiveRole(OfferRoleEnum.Initiator)}
                >
                    Sent
                </Button>
            </div>

            <select
                className="form-select w-auto"
                value={typeFilter || ''}
                onChange={(e) => setTypeFilter(e.target.value ? e.target.value as OfferTypeEnum : undefined)}
            >
                <option value="">All Offers</option>
                <option value={OfferTypeEnum.Buy}>Buy Offers</option>
                <option value={OfferTypeEnum.Sell}>Sell Offers</option>
            </select>
        </div>
    );

    return (
        <Container className="mt-5">
            <h1 className="mb-4 text-center">Trading Hub</h1>
            {!canTrade && (
                <HomeBannerCard>
                    <Card.Text className="text-white">
                        {!isAuthenticated ? (
                            <>
                                <FontAwesomeIcon icon={faSignInAlt} className="me-2" />
                                <Link to="/login" className="text-space-blue hover-text-space-gold">Log in</Link> to send or receive offers.
                            </>
                        ) : !hasHandle && (
                            <>
                                <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                                <Link to="/profile" className="text-space-blue hover-text-space-gold">Set up your profile</Link> to start trading.
                            </>
                        )}
                    </Card.Text>
                </HomeBannerCard>
            )}
            <Nav variant="tabs" className="custom-tabs mb-3">
                {renderTab(TradingHubTab.SharedWantLists, 'Shared Want Lists')}
                {renderTab(TradingHubTab.SharedSaleLists, 'Shared Sale Lists')}
                {renderTab(TradingHubTab.Offers, 'Offers')}
            </Nav>

            {activeTab === TradingHubTab.Offers && canTrade && (
                <>
                    {renderOfferControls()}
                    <OfferList role={activeRole} filterByType={typeFilter} />
                </>
            )}
            {activeTab === TradingHubTab.SharedWantLists && <SharedWantLists />}
            {activeTab === TradingHubTab.SharedSaleLists && <SharedSaleLists />}
        </Container>
    );
};

export default TradingHub;
