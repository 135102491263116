import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CardVariantTypeEnum, useUpdateWantListVariantCountMutation } from '../../../graphql/generated/graphql';
import { getCardVariantTypeDisplay } from '../../../utils/cardUtils';
import { CardData } from '../../../types/wantListTypes';

interface EditableWantCardProps {
    wantedCard: CardData;
    wantListId: string;
    onItemUpdated: () => void;
}

const EditableWantCard: React.FC<EditableWantCardProps> = ({ wantedCard, wantListId, onItemUpdated }) => {
    const [updateWantListVariantCount] = useUpdateWantListVariantCountMutation();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [variantToRemove, setVariantToRemove] = useState<{ id: string; type: string } | null>(null);


    const handleQuantityChange = async (variantId: string, newQuantity: number) => {
        try {
            if (newQuantity < 1) return;

            await updateWantListVariantCount({
                variables: {
                    wantListId,
                    cardVariantId: variantId,
                    quantity: newQuantity
                }
            });
            onItemUpdated();
        } catch (error) {
            console.error('Failed to update quantity:', error);
        }
    };

    const handleRemoveClick = (variantId: string, variantType: string) => {
        setVariantToRemove({ id: variantId, type: variantType });
        setShowConfirmModal(true);
    };

    const handleConfirmRemove = async () => {
        if (!variantToRemove) return;

        try {
            await updateWantListVariantCount({
                variables: {
                    wantListId,
                    cardVariantId: variantToRemove.id,
                    quantity: 0
                }
            });
            onItemUpdated();
            setShowConfirmModal(false);
        } catch (error) {
            console.error('Failed to remove variant:', error);
        }
    };

    const getAspectClasses = (aspects: string[] | null | undefined): string => {
        if (!aspects || aspects.length === 0) return 'aspect-none';
        const priorityAspects = aspects.filter(aspect => !['villainy', 'heroism'].includes(aspect.toLowerCase()));
        if (priorityAspects.length > 0) {
            return `aspect-${priorityAspects[0].toLowerCase()}`;
        }
        return `aspect-${aspects[0].toLowerCase()}`;
    };

    return (
        <>

            <div className={`want-card ${getAspectClasses(wantedCard.aspects)}`}>
                <div className="want-card-header">
                    <span className="want-card-number">{wantedCard.standardFullCardNumber}</span>
                    <h3 className="want-card-name">{wantedCard.name}</h3>
                </div>
                <div className="want-card-variants">
                    {Object.entries(wantedCard.variants).map(([variantType, variantData]) => (
                        <div key={variantData.id} className="want-card-variant">
                            <span className="variant-type">
                                {getCardVariantTypeDisplay(variantType as CardVariantTypeEnum)}
                            </span>
                            <div className="want-counts">
                                <div className="want d-flex align-items-center gap-2">
                                    <Button
                                        variant="outline-space-blue"
                                        size="sm"
                                        onClick={() => handleQuantityChange(variantData.id, variantData.wantCount - 1)}
                                        disabled={variantData.wantCount <= 1}
                                    >
                                        <FontAwesomeIcon icon={faMinus} />
                                    </Button>
                                    <span>{variantData.wantCount}</span>
                                    <Button
                                        variant="outline-space-blue"
                                        size="sm"
                                        onClick={() => handleQuantityChange(variantData.id, variantData.wantCount + 1)}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => handleRemoveClick(variantData.id, variantType)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                className="custom-modal"
            >
                <Modal.Header closeButton className="bg-space-darker border-space">
                    <Modal.Title className="text-space-white">Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-space-darker text-space-white">
                    Are you sure you want to remove the {variantToRemove && getCardVariantTypeDisplay(variantToRemove.type as CardVariantTypeEnum)} variant of {wantedCard.name} from your want list?
                </Modal.Body>
                <Modal.Footer className="bg-space-darker border-space">
                    <Button variant="outline-space-blue" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="outline-danger" onClick={handleConfirmRemove}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditableWantCard;
