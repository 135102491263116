import React, { createContext, useState, useContext, useEffect } from 'react';
import Skeleton from '../components/layout/Skeleton';
import { initiateGoogleOAuth } from '../utils/auth';
import { RoleEnum } from '../graphql/generated/graphql';

export interface User {
    id: number;
    name: string
    email: string
    handle: string;
    roles: RoleEnum[];
}

interface AuthContextType {
    isAuthenticated: boolean;
    user: User | null;
    login: () => void;
    logout: () => void;
    checkAuthStatus: () => Promise<void>;
    updateUser: (userData: User) => void;
    authLoading: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const updateUser = (userData: User) => {
        setUser(userData);
    };

    const checkAuthStatus = async () => {
        try {
            const response = await fetch('/api/auth_status', {
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setIsAuthenticated(data.authenticated);
                setUser(data.user);
            } else {
                setIsAuthenticated(false);
                setUser(null);
            }
        } catch (error) {
            console.error('Error checking auth status:', error);
            setIsAuthenticated(false);
            setUser(null);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        checkAuthStatus();
    }, []);

    const login = async (from: string = '/') => {
        try {
            await initiateGoogleOAuth(from);
        } catch (error) {
            console.error('Error during login:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await fetch('/users/sign_out', {
                method: 'DELETE',
                credentials: 'include'
            });
            setIsAuthenticated(false);
            setUser(null);
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };


    if (isLoading) {
        return (
            <div className="container mt-4">
                <Skeleton height="40px" width="30%" className="mb-4" />
                <Skeleton height="20px" width="50%" className="mb-2" />
                <Skeleton height="20px" width="40%" className="mb-2" />
                <Skeleton height="20px" width="60%" className="mb-2" />
            </div>
        );
    }
    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            user,
            login,
            logout,
            checkAuthStatus,
            updateUser,
            authLoading: isLoading
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
