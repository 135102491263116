import React from 'react';
import { Button } from 'react-bootstrap';
import {
    useGetUserOffersQuery,
    useRescindOfferMutation,
    OfferTypeEnum,
    OfferStatusEnum,
    OfferRoleEnum
} from '../../graphql/generated/graphql';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../layout/LoadingSpinner';
import ErrorDisplay from '../layout/ErrorDisplay';
import { formatDateTime } from '../../utils/dateUtils';

interface OfferListProps {
    role: OfferRoleEnum;
    filterByType?: OfferTypeEnum;
}

const OfferList: React.FC<OfferListProps> = ({ role, filterByType }) => {
    const navigate = useNavigate();

    const { data, loading, error } = useGetUserOffersQuery({
        variables: {
            role,
            offerType: filterByType
        },
    });

    const [rescindOffer] = useRescindOfferMutation();

    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorDisplay message={error.message} />;

    const offers = data?.userOffers || [];
    if (offers.length === 0) {
        return (
            <div className="text-center p-4 bg-space-dark rounded-lg">
                <p className="text-space-light">
                    {role === OfferRoleEnum.Initiator
                        ? "You haven't made any offers yet"
                        : "You haven't received any offers yet"
                    }
                </p>
            </div>
        );
    }

    // Sort offers by status: PENDING first, then others
    const sortedOffers = [...offers].sort((a, b) => {
        if (a?.status === OfferStatusEnum.Pending) return -1;
        if (b?.status === OfferStatusEnum.Pending) return 1;
        return 0;
    });

    const getCounterpartyHandle = (offer: typeof offers[0]) => {
        return role === OfferRoleEnum.Initiator
            ? offer?.recipient?.handle
            : offer?.initiator?.handle;
    };

    const handleRescind = async (offerId: string) => {
        try {
            await rescindOffer({ variables: { offerId } });
            // Optionally refetch the offers or update the cache here
        } catch (error) {
            console.error('Error rescinding offer:', error);
        }
    };

    return (
        <div className="space-table-container">
            <table className="space-table">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Total</th>
                        <th>Created</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedOffers.map((offer) => (
                        <tr
                            key={offer?.id}
                            onClick={() => navigate(`/trading/offers/${offer?.id}`)}
                            className="cursor-pointer hover:bg-gray-50"
                        >
                            <td>{getCounterpartyHandle(offer)}</td>
                            <td>{offer?.offerType}</td>
                            <td>{offer?.status}</td>
                            <td>${(offer?.totalPriceInCents / 100).toFixed(2)}</td>
                            <td>{formatDateTime(offer?.createdAt)}</td>
                            <td onClick={(e) => e.stopPropagation()}>
                                {offer?.status === OfferStatusEnum.Pending &&
                                    role === OfferRoleEnum.Initiator && (
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleRescind(offer?.id ?? '')}
                                        >
                                            Rescind
                                        </Button>
                                    )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default OfferList;
