import React from 'react';
import { CardVariantTypeEnum } from '../../../graphql/generated/graphql';
import CardHover from '../../card/CardHover';
import CardVariantCell from './CardVariantCell';
import { CardData } from '../../../types/editSaleListTypes';

interface CardTableRowProps {
    card: CardData;
    variantColumns: CardVariantTypeEnum[];
    saleListId: string;
    showExtrasOnly: boolean;
    onItemUpdated: () => void;
}

const CardTableRow: React.FC<CardTableRowProps> = ({
    card,
    variantColumns,
    saleListId,
    showExtrasOnly,
    onItemUpdated
}) => {
    const isCardExtra = Object.values(card.variants).some(variant => variant.extraCount > 0);

    return (
        <tr className={isCardExtra ? 'extra-card' : ''}>
            <td className="text-center">{card.standardCardNumber}</td>
            <td>
                <CardHover cardId={card.id}>
                    <span className="space-table-link">{card.name}</span>
                </CardHover>
            </td>
            {variantColumns.map((variantType) => (
                <CardVariantCell
                    key={variantType}
                    card={card}
                    variantType={variantType}
                    saleListId={saleListId}
                    showExtrasOnly={showExtrasOnly}
                    onItemUpdated={onItemUpdated}
                />
            ))}
        </tr>
    );
};

export default CardTableRow;
