import React from 'react';

interface TextFilterProps {
    value: string;
    onChange: (value: string) => void;
    disabled?: boolean;
}

const TextFilter: React.FC<TextFilterProps> = ({ value, onChange, disabled }) => (
    <div className="card-filters__section">
        <label className="card-filters__section-title">Search</label>
        <input
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Search cards..."
            className="card-filters__input"
            disabled={disabled}
        />
    </div>
);

export default TextFilter;
