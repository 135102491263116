import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { SortColumn, SortDirection } from '../../hooks/useSortedCardInventory';
import { variantColumns } from '../collection/InventoryCardTable';


interface InventoryCardTableHeaderProps {
    sortColumn: SortColumn;
    sortDirection: SortDirection;
    onSort: (column: SortColumn) => void;
}


export const InventoryCardTableHeader: React.FC<InventoryCardTableHeaderProps> = ({ sortColumn, sortDirection, onSort }) => {
    const renderSortIcon = (column: SortColumn) => {
        if (column === sortColumn) {
            return <FontAwesomeIcon icon={sortDirection === 'asc' ? faSortUp : faSortDown} className="ms-1" />;
        }
        return null;
    };

    return (
        <thead className="space-table-header">
            <tr>
                <th onClick={() => onSort('cardNumber')}>Number {renderSortIcon('cardNumber')}</th>
                <th onClick={() => onSort('name')}>Name {renderSortIcon('name')}</th>
                {variantColumns.map(({ label, countKey }) => (
                    <th key={label} onClick={() => onSort(countKey as SortColumn)}>
                        {label} {renderSortIcon(countKey as SortColumn)}
                    </th>
                ))}
            </tr>
        </thead>
    );
};
