import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faEye, faExclamationTriangle, faShare } from '@fortawesome/free-solid-svg-icons';
import SaleListDisplay from './SaleListDisplay';
import SaleListEditor from './SaleListEditor';
import { useAuth } from '../../../contexts/AuthContext';
import {
    AspectTypeEnum,
    CardVariantTypeEnum,
    useGetUserSaleListsForDisplayQuery,
    useGetSaleListShareSettingsLazyQuery,
    useUpdateSaleListSharingMutation
} from '../../../graphql/generated/graphql';
import ShareCollectionModal from '../ShareListModal';
import { CardData, CardVariantData } from '../../../types/saleListTypes';
import { useShareSettings } from '../../../hooks/useShareSettings';
import CreateBuyOfferModal from '../../trade/CreateBuyOfferModal';
import OfferBanner from './OfferBanner';

const SaleList: React.FC = () => {
    // Hooks and state
    const { handle } = useParams<{ handle: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, user } = useAuth();
    const [isEditMode, setIsEditMode] = useState(location.pathname.endsWith('/edit'));
    const [getSaleListShareSettings] = useGetSaleListShareSettingsLazyQuery();
    const [updateSaleListSharing] = useUpdateSaleListSharingMutation();
    const [showOfferModal, setShowOfferModal] = useState(false);


    // Path and authorization checks
    const isMySaleList = /^\/collections\/my\/sell(\/edit)?$/.test(location.pathname);
    const isOwnSaleList = isMySaleList || (isAuthenticated && user?.handle === handle);
    const effectiveHandle = isMySaleList ? user?.handle : handle;
    const displayHandle = isOwnSaleList ? 'My' : `${effectiveHandle}'s`;
    const pageTitle = isEditMode ? 'Edit Sale List' : `${displayHandle} Sale List`;

    // GraphQL queries and mutations
    const { data, loading, error, refetch } = useGetUserSaleListsForDisplayQuery({
        variables: { userHandle: effectiveHandle },
        skip: !effectiveHandle,
    });

    const saleListId = data?.userSaleLists?.[0]?.id;

    const {
        showShareModal,
        setShowShareModal,
        shareError,
        isSharingLoading,
        shareSettings,
        handleShowShareModal,
        handleSaveSharing
    } = useShareSettings({
        getShareSettings: async (id?: string) => {
            const result = await getSaleListShareSettings({
                variables: { saleListId: id || '' },
                fetchPolicy: 'network-only'
            });
            return {
                data: {
                    sharedWithAll: result.data?.saleList?.shareSettings?.sharedWithAll || false,
                    sharedWithUsers: (result.data?.saleList?.shareSettings?.sharedWithUsers || []).map(user => ({
                        id: String(user.id),
                        handle: user.handle || '',
                        email: user.email
                    }))
                }
            };
        },
        updateSharing: async (variables) => {
            const result = await updateSaleListSharing({
                variables: {
                    input: {
                        saleListId: saleListId!,
                        ...variables.input
                    }
                }
            });
            return { data: result.data?.updateSaleListSharing || { success: false } };
        },
        id: saleListId,
        idFieldName: 'saleListId'
    });

    // Authentication effect
    useEffect(() => {
        if (isMySaleList && !isAuthenticated) {
            navigate('/login', { state: { from: location.pathname } });
        }
        setIsEditMode(location.pathname.endsWith('/edit'));
    }, [isMySaleList, isAuthenticated, navigate, location.pathname]);

    // Event listener effect
    useEffect(() => {
        const handleSaleListUpdated = () => refetch();
        window.addEventListener('sale-list-updated', handleSaleListUpdated);
        return () => window.removeEventListener('sale-list-updated', handleSaleListUpdated);
    }, [refetch]);

    // Event handlers
    const toggleEditMode = () => {
        const newMode = !isEditMode;
        setIsEditMode(newMode);
        navigate(newMode ? `/collections/my/sell/edit` : `/collections/my/sell`);
    };

    const handleItemsUpdated = () => {
        window.dispatchEvent(new Event('sale-list-updated'));
    };

    const handleCreateOffer = () => {
        if (!isAuthenticated) {
            navigate('/login', { state: { from: location.pathname } });
            return;
        }
        setShowOfferModal(true);
    };

    // Data transformation
    const cardsData: CardData[] = useMemo(() => {
        if (!data?.userSaleLists?.[0]?.items) return [];

        const cardMap = new Map<string, CardData>();

        data.userSaleLists[0].items.forEach((item) => {
            if (!item) return;

            const { card, variantType } = item.cardVariant;
            const cardId = card.id;

            if (!cardMap.has(cardId)) {
                cardMap.set(cardId, {
                    id: cardId,
                    name: card.name,
                    standardFullCardNumber: card.standardFullCardNumber || '',
                    variants: {} as Record<CardVariantTypeEnum, CardVariantData>,
                    aspects: card.aspects as AspectTypeEnum[]
                });
            }

            const cardData = cardMap.get(cardId)!;
            if (Object.values(CardVariantTypeEnum).includes(variantType)) {
                cardData.variants[variantType] = {
                    id: item.cardVariant.id,
                    saleCount: item.quantity,
                    fullCardNumber: item.cardVariant.fullCardNumber,
                    priceInCents: item.priceInCents,
                    condition: item.condition,
                };
            }
        });

        return Array.from(cardMap.values());
    }, [data]);

    // Handle required message
    if (isMySaleList && !user?.handle) {
        return (
            <Container className="mt-4 sale-list-container bg-space-dark p-4 rounded">
                <div className="space-alert bg-space-black border border-space-blue p-4 rounded text-center">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="text-space-gold mb-3" size="2x" />
                    <h2 className="text-space-gold">Handle Required</h2>
                    <p className="text-space-white mb-4">You need to create a handle to manage your sale list.</p>
                    <Button
                        variant="outline-space-blue"
                        onClick={() => navigate('/profile')}
                        className="glow-effect"
                    >
                        Create Handle
                    </Button>
                </div>
            </Container>
        );
    }

    return (
        <Container className="mt-4 sale-list-container bg-space-dark p-4 rounded">
            {!isOwnSaleList && data?.userSaleLists?.[0]?.items?.length > 0 && (
                <OfferBanner onCreateOffer={handleCreateOffer} />
            )}
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h1 className="text-space-gold">{pageTitle}</h1>
                <div className="d-flex align-items-center">
                    {isOwnSaleList && (
                        <Button
                            onClick={toggleEditMode}
                            variant="outline-space-blue"
                            className="btn-icon"
                            title={isEditMode ? "View Mode" : "Edit Mode"}
                        >
                            <FontAwesomeIcon icon={isEditMode ? faEye : faPencilAlt} />
                        </Button>
                    )}
                    {isOwnSaleList && !isEditMode && (
                        <Button
                            onClick={handleShowShareModal}
                            disabled={loading}
                            variant="outline-space-blue"
                            className="me-2 btn-icon"
                            title="Share List"
                        >
                            <FontAwesomeIcon icon={faShare} />
                        </Button>
                    )}
                </div>
            </div>

            {isEditMode ? (
                <SaleListEditor
                    effectiveHandle={effectiveHandle}
                    onItemsUpdated={handleItemsUpdated}
                />
            ) : (
                <SaleListDisplay
                    effectiveHandle={effectiveHandle}
                    isOwnSaleList={isOwnSaleList}
                    cardsData={cardsData}
                    loading={loading}
                    error={error}
                />
            )}

            <ShareCollectionModal
                show={showShareModal}
                onHide={() => setShowShareModal(false)}
                initialSharedWithAll={shareSettings.sharedWithAll}
                onSave={handleSaveSharing}
                isSaving={isSharingLoading}
                error={shareError}
                sharedWithUsers={shareSettings.sharedWithUsers}
                shareableLink={`${window.location.origin}/collections/${effectiveHandle}/sell`}
            />
            <CreateBuyOfferModal
                show={showOfferModal}
                onHide={() => setShowOfferModal(false)}
                recipientHandle={effectiveHandle!}
                saleListItems={cardsData.map(card => ({
                    id: card.id,
                    cardDetails: {
                        name: card.name,
                        standardFullCardNumber: card.standardFullCardNumber
                    },
                    variants: Object.entries(card.variants).map(([variantType, variant]) => ({
                        variantId: variant.id,
                        variantType,
                        priceInCents: variant.priceInCents,
                        quantity: variant.saleCount
                    }))
                }))}
            />
        </Container>
    );
};

export default SaleList;
