import React from 'react';
import { useGetSharedCollectionsQuery } from '../../graphql/generated/graphql';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faGlobeAmericas, faUserFriends, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../layout/LoadingSpinner';
import ErrorDisplay from '../layout/ErrorDisplay';

const SharedCollections: React.FC = () => {
    const { data, loading, error } = useGetSharedCollectionsQuery();
    const navigate = useNavigate();

    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorDisplay message={error.message} />;

    if (data?.sharedCollections.length === 0) {
        return (
            <Container className="mt-4">
                <div className="space-alert bg-space-black border border-space-blue p-4 rounded text-center">
                    <FontAwesomeIcon icon={faFolder} className="text-space-gold mb-3" size="2x" />
                    <h2 className="text-space-gold">No Shared Collections</h2>
                    <p className="text-space-white mb-4">No one has shared their collections with you yet.</p>
                </div>
            </Container>
        );
    }

    return (
        <Container className="mt-4 shared-lists-container">
            <h1 className="mb-4 text-space-gold">Shared Collections</h1>
            <div className="shared-list-grid">
                {data?.sharedCollections.map((collection) => (
                    <div key={collection.id} className="shared-list-item">
                        <h2 className="list-name">{collection.user.handle}&apos;s Collection</h2>
                        <p className="list-info">
                            Last updated: {new Date(collection.updatedAt).toLocaleDateString()}
                        </p>
                        <div className="list-actions">
                            <FontAwesomeIcon
                                icon={collection.publiclyShared ? faGlobeAmericas : faUserFriends}
                                title={collection.publiclyShared ? "Shared with everyone" : "Shared privately"}
                                className={`sharing-icon ${collection.publiclyShared ? 'public' : 'private'}`}
                            />
                            <Button
                                variant="outline-space-blue"
                                className="view-button"
                                title="View Collection"
                                onClick={() => navigate(`/collections/${collection.user.handle}`)}
                            >
                                <FontAwesomeIcon icon={faExternalLinkAlt} /> View
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        </Container>
    );
};

export default SharedCollections;
