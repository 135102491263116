import React from 'react';
import { Container } from 'react-bootstrap';
import LoginButton from './LoginButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const Login: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const from = location.state?.from?.pathname || '/';

    React.useEffect(() => {
        if (isAuthenticated) {
            navigate(from, { replace: true });
        }
    }, [isAuthenticated, navigate, from]);

    return (
        <Container className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '50vh' }}>
            <p className="mb-4 text-center">Please sign in with your Google account to continue.</p>
            <LoginButton from={from} />
        </Container>
    );
};

export default Login;
