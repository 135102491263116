import { useState, useMemo, useCallback } from 'react';
import { UserCardInventory } from '../graphql/generated/graphql';

type InventoryKeys = 'standardInventory' | 'foilInventory' | 'hyperspaceInventory' | 'hyperspaceFoilInventory';
type CardDetailKeys = 'cardNumber' | 'name' | 'rarity' | 'cardType';
export type SortColumn = InventoryKeys | CardDetailKeys;
export type SortDirection = 'asc' | 'desc';

const inventoryKeys: InventoryKeys[] = ['standardInventory', 'foilInventory', 'hyperspaceInventory', 'hyperspaceFoilInventory'];

const getSortValue = (card: UserCardInventory, column: SortColumn): string | number => {
    if (inventoryKeys.includes(column as InventoryKeys)) {
        return card[column as InventoryKeys]?.inventory ?? 0;
    }
    return card.cardDetails[column as CardDetailKeys] ?? '';
};

// Type-safe comparison function
const compare = <T extends string | number>(a: T, b: T, direction: SortDirection): number => {
    if (typeof a === 'string' && typeof b === 'string') {
        return direction === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
    }
    return direction === 'asc' ? (a as number) - (b as number) : (b as number) - (a as number);
};

export const useSortedCardInventory = (cards: UserCardInventory[]) => {
    const [sortColumn, setSortColumn] = useState<SortColumn>('cardNumber');
    const [sortDirection, setSortDirection] = useState<SortDirection>('asc');

    const handleSort = useCallback((column: SortColumn) => {
        setSortColumn(column);
        setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    }, []);

    const sortedCards = useMemo(() => {
        return [...cards].sort((a, b) => {
            const aValue = getSortValue(a, sortColumn);
            const bValue = getSortValue(b, sortColumn);
            return compare(aValue, bValue, sortDirection);
        });
    }, [cards, sortColumn, sortDirection]);

    return { sortedCards, sortColumn, sortDirection, handleSort };
};
