import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { AspectTypeEnum } from '../../graphql/generated/graphql';
import AspectFilter from './AspectFilter';
import RarityFilter from './RarityFilter';
import TextFilter from './TextFilter';
import CostFilter from './CostFilter';
import { FilterOptions, FilterValue } from '../../types/cardFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface CardFiltersProps {
    filters: FilterOptions;
    updateFilter: (key: keyof FilterOptions, value: FilterValue) => void;
    rarities: string[];
    costs: number[];
    disabled?: boolean;
    isOpen: boolean;
    onClose: () => void;
}

const CardFilters: React.FC<CardFiltersProps> = ({ filters, updateFilter, rarities, costs, disabled, isOpen, onClose }) => {
    const handleAspectChange = (selected: AspectTypeEnum[], matchAll: boolean) => {
        updateFilter('selectedAspects', selected);
        updateFilter('matchAllAspects', matchAll);
    };

    return (
        <>
            <div className={`filter-backdrop ${isOpen ? 'open' : ''}`} onClick={onClose} />
            <div className={`card-filters ${isOpen ? 'open' : ''}`}>
                <Form className="card-filters__form" onSubmit={(e) => e.preventDefault()}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h3 className="card-filters__title mb-0">Filter Cards</h3>
                        <Button
                            variant="link"
                            className="card-filters__close-btn"
                            onClick={onClose}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </div>
                    <TextFilter
                        value={filters.searchText || ''}
                        onChange={(value) => updateFilter('searchText', value)}
                        disabled={disabled}
                    />
                    <RarityFilter
                        rarities={rarities || []}
                        selected={filters.selectedRarities || []}
                        onChange={(selected) => updateFilter('selectedRarities', selected)}
                        disabled={disabled}
                    />
                    <AspectFilter
                        selected={filters.selectedAspects || []}
                        matchAll={filters.matchAllAspects || false}
                        onChange={handleAspectChange}
                        disabled={disabled}
                    />
                    <CostFilter
                        costs={costs || []}
                        selectedCosts={filters.selectedCosts || []}
                        onChange={(selectedCosts) => updateFilter('selectedCosts', selectedCosts)}
                        disabled={disabled}
                    />
                </Form>
            </div>
        </>
    );
};

export default CardFilters;
