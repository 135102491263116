import React, { useState } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CardSetCodeEnum, useGetUserWantListsForEditQuery } from '../../../graphql/generated/graphql';
import CardSetSelector from './CardSetSelector';
import CardSearch from './CardSearch';
import CardTable from './CardTable';
import WantListItemsTable from './WantListItemsTable';

interface WantListEditorProps {
    effectiveHandle: string | undefined;
    onItemsUpdated?: () => void;
}

const WantListEditor: React.FC<WantListEditorProps> = ({
    effectiveHandle,
    onItemsUpdated
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedSet, setSelectedSet] = useState<CardSetCodeEnum | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showMissingOnly, setShowMissingOnly] = useState(true);

    const { data, loading, refetch } = useGetUserWantListsForEditQuery({
        variables: { userHandle: effectiveHandle || '' },
        skip: !effectiveHandle
    });

    const handleItemsUpdated = () => {
        refetch();
        onItemsUpdated?.();
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedSet(null);
        setSearchTerm('');
        setShowMissingOnly(false);
    };

    if (loading || !data?.userWantLists?.[0]) return null;

    const wantList = data.userWantLists[0];

    return (
        <Container className="want-list-editor">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <Button
                    variant="outline-space-blue"
                    onClick={() => setShowModal(true)}
                >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add Cards
                </Button>
            </div>

            <WantListItemsTable
                items={(wantList.items?.nodes?.filter((item): item is NonNullable<typeof item> => item !== null) ?? []).map(item => ({
                    id: item.id,
                    wantListId: wantList.id,
                    quantity: item.quantity,
                    cardVariant: {
                        id: item.cardVariant.id,
                        cardNumber: item.cardVariant.cardNumber,
                        variantType: item.cardVariant.variantType,
                        card: {
                            id: item.cardVariant.card.id,
                            name: item.cardVariant.card.name,
                            standardFullCardNumber: item.cardVariant.card.standardFullCardNumber,
                            aspects: item.cardVariant.card.aspects
                        }
                    }
                }))}
                wantListId={wantList.id}
                onItemUpdated={handleItemsUpdated}
            />

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size="xl"
                className="custom-modal"
            >
                <Modal.Header closeButton className="bg-space-darker border-space">
                    <Modal.Title className="text-space-white">Add Cards to Want List</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-space-dark p-4">
                    <div className="mb-4">
                        <CardSetSelector
                            selectedSet={selectedSet ?? CardSetCodeEnum.Sor}
                            onSelectSet={setSelectedSet}
                        />
                    </div>
                    <div className="mb-4">
                        <CardSearch
                            selectedSet={selectedSet ?? CardSetCodeEnum.Sor}
                            searchTerm={searchTerm}
                            onSearchChange={setSearchTerm}
                            showMissingOnly={showMissingOnly}
                            onShowMissingOnlyChange={setShowMissingOnly}
                        />
                    </div>
                    <div className="space-table-container">
                        <CardTable
                            selectedSet={selectedSet ?? CardSetCodeEnum.Sor}
                            searchTerm={searchTerm}
                            showMissingOnly={showMissingOnly}
                            effectiveHandle={effectiveHandle}
                            onItemUpdated={handleItemsUpdated}
                            existingWantList={wantList}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default WantListEditor;
