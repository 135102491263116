import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faGlobeAmericas, faUserFriends, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useGetSharedSaleListsQuery } from '../../graphql/generated/graphql';
import LoadingSpinner from '../layout/LoadingSpinner';
import ErrorDisplay from '../layout/ErrorDisplay';

const SharedSaleLists: React.FC = () => {
    const { data, loading, error } = useGetSharedSaleListsQuery();
    const navigate = useNavigate();

    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorDisplay message={error.message} />;

    if (data?.sharedSaleLists?.length === 0) {
        return (
            <Container className="mt-4">
                <div className="space-alert bg-space-black border border-space-blue p-4 rounded text-center">
                    <FontAwesomeIcon icon={faFolder} className="text-space-gold mb-3" size="2x" />
                    <h2 className="text-space-gold">No Shared Sale Lists</h2>
                    <p className="text-space-white mb-4">No one has shared their sale lists yet.</p>
                </div>
            </Container>
        );
    }

    return (
        <Container className="mt-4 shared-lists-container">
            <div className="shared-list-grid">
                {data?.sharedSaleLists?.map((sharedSaleList) => (
                    <div key={sharedSaleList.id} className="shared-list-item">
                        <h2 className="list-name">{sharedSaleList.user.handle}&apos;s Sell List</h2>
                        <p className="list-info">
                            Last updated: {new Date(sharedSaleList.updatedAt).toLocaleDateString()}
                        </p>
                        <div className="list-actions">
                            <FontAwesomeIcon
                                icon={sharedSaleList.publiclyShared ? faGlobeAmericas : faUserFriends}
                                title={sharedSaleList.publiclyShared ? "Shared with everyone" : "Shared privately"}
                                className={`sharing-icon ${sharedSaleList.publiclyShared ? 'public' : 'private'}`}
                            />
                            <Button
                                variant="outline-space-blue"
                                className="view-button"
                                title="View Sell List"
                                onClick={() => navigate(`/collections/${sharedSaleList.user.handle}/sell`)}
                            >
                                <FontAwesomeIcon icon={faExternalLinkAlt} /> View
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        </Container>
    );
};


export default SharedSaleLists;
