import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { AspectTypeEnum, CardVariantTypeEnum, SaleListItemConditionEnum } from '../../../graphql/generated/graphql';
import EditableSaleCard from './EditableSaleCard';
import { compareCardIds } from '../../../utils/cardUtils';

interface SaleListTableItem {
    id: string;
    saleListId: string;
    quantity: number;
    priceInCents: number;
    condition: SaleListItemConditionEnum;
    extraCount: number;
    cardVariant: {
        id: string;
        cardNumber: string;
        variantType: CardVariantTypeEnum;
        card: {
            id: string;
            name: string;
            standardFullCardNumber?: string | null;
            aspects: AspectTypeEnum[];
        };
    };
}

interface SaleListItemsTableProps {
    items: Array<SaleListTableItem>;
    saleListId: string;
    onItemUpdated?: () => void;
}

const SaleListItemsTable: React.FC<SaleListItemsTableProps> = ({
    items,
    saleListId,
    onItemUpdated
}) => {
    if (items.length === 0) {
        return (
            <div className="text-space-white text-center p-4">
                No cards in sale list yet.
            </div>
        );
    }

    // Group items by card ID
    const groupedCards = items.reduce((acc, item) => {
        const cardId = item.cardVariant.card.id;
        if (!acc.has(cardId)) {
            acc.set(cardId, {
                id: cardId,
                name: item.cardVariant.card.name,
                standardFullCardNumber: item.cardVariant.card.standardFullCardNumber,
                variants: {},
                aspects: item.cardVariant.card.aspects
            });
        }

        const cardData = acc.get(cardId)!;
        cardData.variants[item.cardVariant.variantType] = {
            id: item.cardVariant.id,
            saleCount: item.quantity,
            priceInCents: item.priceInCents,
            condition: item.condition,
            extraCount: item.extraCount || 0
        };

        return acc;
    }, new Map());

    return (
        <Row xs={1} md={2} lg={3} className="g-4">
            {Array.from(groupedCards.values())
                .sort((a, b) => compareCardIds(a.id, b.id))
                .map((card) => (
                    <Col key={card.id}>
                        <EditableSaleCard
                            saleCard={card}
                            saleListId={saleListId}
                            onItemUpdated={onItemUpdated || (() => { })}
                        />
                    </Col>
                ))}
        </Row>
    );
};

export default SaleListItemsTable;
