import React, { useState, useEffect } from 'react';
import { Container, Alert, Card, Row, Col } from 'react-bootstrap';
import { useGetUserProfileQuery, useUpdateUserProfileMutation, UpdateUserProfileErrorCode } from '../../graphql/generated/graphql';
import Skeleton from '../layout/Skeleton';
import HandleSection from './HandleSection';
import { useAuth } from '../../contexts/AuthContext';

const ERROR_MESSAGES: Record<UpdateUserProfileErrorCode, string> = {
    [UpdateUserProfileErrorCode.HandleTaken]: "This handle is already taken. Please choose a different one.",
    [UpdateUserProfileErrorCode.HandleBlank]: "Handle cannot be empty.",
    [UpdateUserProfileErrorCode.HandleInvalid]: "Handle is invalid. It must contain only lowercase letters, numbers, dash, or underscore.",
    [UpdateUserProfileErrorCode.HandleReserved]: "This handle is reserved and cannot be used.",
    [UpdateUserProfileErrorCode.Immutable]: "Handle cannot be changed once set.",
    [UpdateUserProfileErrorCode.NotFound]: "User not found.",
    [UpdateUserProfileErrorCode.UnknownError]: "An unknown error occurred. Please try again.",
    [UpdateUserProfileErrorCode.Forbidden]: "You don't have permission to perform this action."
};

const UserProfile: React.FC = () => {
    const { updateUser } = useAuth();
    const { data, loading, error } = useGetUserProfileQuery();
    const [updateUserProfile] = useUpdateUserProfileMutation();
    const [handle, setHandle] = useState('');
    const [updateError, setUpdateError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (data?.user?.handle) {
            setHandle(data.user.handle);
        }
    }, [data]);

    useEffect(() => {
        if (successMessage) {
            setShowAlert(true);
            const timer = setTimeout(() => {
                setShowAlert(false);
                setSuccessMessage('');
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    const handleSave = async () => {
        try {
            const result = await updateUserProfile({
                variables: { input: { handle } }
            });

            if (result.data?.updateUserProfile?.errors?.length) {
                const errors = result.data.updateUserProfile.errors;
                const errorMessages = errors.map(err =>
                    ERROR_MESSAGES[err.code as UpdateUserProfileErrorCode] || err.message
                );
                setUpdateError(errorMessages.join(', '));
                setSuccessMessage('');
            } else {
                setUpdateError('');
                setSuccessMessage('Handle successfully updated');
                if (data?.user) {
                    updateUser({
                        ...data.user,
                        handle
                    });
                }
            }
        } catch (err) {
            setUpdateError('An error occurred while updating your profile');
            setSuccessMessage('');
            console.error('Error updating profile:', err);
        }
    };

    if (loading) return <Skeleton />;
    if (error) {
        return (
            <Container className="mt-4">
                <Alert variant="danger">
                    Failed to load profile: {error.message}
                </Alert>
            </Container>
        );
    }

    return (
        <Container className="mt-4">
            {showAlert && (
                <Alert
                    variant="success"
                    className="fade-out-alert mb-4"
                    onAnimationEnd={() => {
                        if (!showAlert) setSuccessMessage('');
                    }}
                >
                    {successMessage}
                </Alert>
            )}

            <Card className="bg-space-dark text-space-white">
                <Card.Body>
                    <Card.Title className="text-space-gold mb-4">Profile Settings</Card.Title>

                    <Row className="mb-4">
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="fw-bold mb-2">Name</label>
                                <p className="mb-0">{data?.user?.name || 'N/A'}</p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label className="fw-bold mb-2">Email</label>
                                <p className="mb-0">{data?.user?.email || 'N/A'}</p>
                            </div>
                        </Col>
                    </Row>

                    <HandleSection
                        handle={handle}
                        savedHandle={data?.user?.handle || ''}
                        onHandleChange={setHandle}
                        onHandleSave={handleSave}
                        error={updateError}
                        isLoading={loading}
                    />
                </Card.Body>
            </Card>
        </Container>
    );
};

export default UserProfile;
