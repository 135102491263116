import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const AdminDashboard: React.FC = () => {
    return (
        <>
            <h1 className="text-space-gold mb-4">Admin Dashboard</h1>
            <Row>
                <Col md={4}>
                    <Card className="bg-space-dark">
                        <Card.Body>
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faUsers} className="text-space-gold fa-2x me-3" />
                                <div>
                                    <h3 className="text-space-white mb-0">Users</h3>
                                    <Link to="/admin/users" className="text-space-blue">
                                        Manage Users →
                                    </Link>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default AdminDashboard;
