import React from 'react';
import CardHover from '../../card/CardHover';
import { CardVariantTypeEnum } from '../../../graphql/generated/graphql';
import { getCardVariantTypeDisplay } from '../../../utils/cardUtils';
import { CardData } from '../../../types/wantListTypes';


interface WantCardProps {
    wantedCard: CardData;
    isOwnWantList: boolean;
}

const WantCard: React.FC<WantCardProps> = ({ wantedCard }) => {
    const getAspectClasses = (aspects: string[] | null | undefined): string => {
        if (!aspects || aspects.length === 0) return 'aspect-none';
        const priorityAspects = aspects.filter(aspect => !['villainy', 'heroism'].includes(aspect.toLowerCase()));
        if (priorityAspects.length > 0) {
            return `aspect-${priorityAspects[0].toLowerCase()}`;
        }
        return `aspect-${aspects[0].toLowerCase()}`;
    };

    return (
        <CardHover cardId={wantedCard.id}>
            <div className={`want-card ${getAspectClasses(wantedCard.aspects)}`}>
                <div className="want-card-header">
                    <span className="want-card-number">{wantedCard.standardFullCardNumber}</span>
                    <h3 className="want-card-name">{wantedCard.name}</h3>
                </div>
                <div className="want-card-variants">
                    {Object.entries(wantedCard.variants).map(([variantType, variantData]) => (
                        <div key={variantData.id} className="want-card-variant">
                            <span className="variant-type">{getCardVariantTypeDisplay(variantType as CardVariantTypeEnum)}</span>
                            <div className="want-counts">
                                <div className="want" title="Wanted">
                                    <span>{variantData.wantCount}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </CardHover>
    );
};

export default WantCard;
