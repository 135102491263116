import React, { useState, useMemo } from 'react';
import { AspectTypeEnum, Card, useGetCardQuery } from '../../graphql/generated/graphql';
import CardHover from './CardHover';
import CardModal from './CardModal';
import { getFormattedImageUrl } from '../../utils/imageUtils';
import { useAspects } from "../../hooks/useAspects";


const columnMap: Record<string, keyof Card> = {
    'Number': 'standardCardNumber',
    'Name': 'name',
    'Rarity': 'rarity',
    'Cost': 'cost',
    'Power': 'power',
    'HP': 'hp',
    'Type': 'cardType',
    'Arena': 'arena',
    'Aspects': 'aspects',
    'Traits': 'traits',
    'Keywords': 'keywords'
};

interface CardTableProps {
    cards: Card[];
}

const CardTable: React.FC<CardTableProps> = ({ cards }) => {
    const [sortColumn, setSortColumn] = useState<keyof typeof columnMap>('Number');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
    const { aspects } = useAspects();

    const getAspectImageUrl = (aspectType: AspectTypeEnum) => {
        const aspect = aspects.find(a => a.value === aspectType);
        return aspect ? getFormattedImageUrl(aspect.image, 20) : '';
    };

    const handleCardClick = (card: Card) => {
        setSelectedCardId(card.id);
    };

    const handleCloseModal = () => {
        setSelectedCardId(null);
    };

    const { data: cardData, loading: cardLoading } = useGetCardQuery({
        variables: { id: selectedCardId || '' },
        skip: !selectedCardId,
    });

    const handleSort = (column: keyof Card) => {
        if (column === sortColumn) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const renderSortIcon = (column: keyof Card) => {
        if (column !== sortColumn) return null;
        return sortDirection === 'asc' ? '▲' : '▼';
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any


    const sortedCards = useMemo(() => {
        const compareValues = (a: string | number | null, b: string | number | null, direction: 'asc' | 'desc'): number => {
            if (sortColumn === 'standardCardNumber') {
                const aNum = parseInt(a as string) || 0;
                const bNum = parseInt(b as string) || 0;
                return direction === 'asc' ? aNum - bNum : bNum - aNum;
            }

            // Convert to strings for comparison if not numbers
            const aStr = String(a ?? '');
            const bStr = String(b ?? '');
            return direction === 'asc' ? aStr.localeCompare(bStr) : bStr.localeCompare(aStr);
        };

        return [...cards].sort((a, b) => {
            const aValue = a[sortColumn] ?? '';
            const bValue = b[sortColumn] ?? '';
            return compareValues(aValue, bValue, sortDirection);
        });
    }, [cards, sortColumn, sortDirection]);

    return (
        <div className="space-table-container">
            <table className="space-table">
                <thead className="space-table-header">
                    <tr>
                        {Object.keys(columnMap).map((header) => (
                            <th key={header} onClick={() => handleSort(columnMap[header])} role="button" tabIndex={0}>
                                {header} {renderSortIcon(columnMap[header])}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedCards.map((card) => (
                        <tr key={card.id} onClick={() => handleCardClick(card)} className="card-row-clickable">
                            <td>{card.standardCardNumber}</td>
                            <td>
                                <CardHover cardId={card.id}>
                                    <span className="space-table-link">{card.name}</span>
                                </CardHover>
                            </td>
                            <td>{card.rarity}</td>
                            <td>{card.cost}</td>
                            <td>{card.power}</td>
                            <td>{card.hp}</td>
                            <td>{card.cardType}</td>
                            <td>{card.arena}</td>
                            <td>
                                <div className="aspect-icons-container">
                                    {card.aspects?.map((aspect, index) => (
                                        <div key={index} className="aspect-icon-wrapper">
                                            <img
                                                key={index}
                                                src={getAspectImageUrl(aspect)}
                                                alt={aspect}
                                                title={aspect}
                                                className="aspect-icon"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </td>
                            <td>{card.traits?.join(', ')}</td>
                            <td>{card.keywords?.join(', ')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {selectedCardId && (
                <CardModal
                    card={cardData?.card}
                    loading={cardLoading}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

export default CardTable;
