import { CardVariantTypeEnum, SaleListItemConditionEnum } from '../graphql/generated/graphql';

export function getCardVariantTypeDisplay(variantType: CardVariantTypeEnum): string {
    switch (variantType) {
        case CardVariantTypeEnum.Standard:
            return 'Standard';
        case CardVariantTypeEnum.Foil:
            return 'Foil';
        case CardVariantTypeEnum.Hyperspace:
            return 'Hyperspace';
        case CardVariantTypeEnum.HyperspaceFoil:
            return 'Hyperspace Foil';
        case CardVariantTypeEnum.Showcase:
            return 'Showcase';
        default:
            return 'Unknown';
    }
}

export const orderedVariantTypes: CardVariantTypeEnum[] = [
    CardVariantTypeEnum.Standard,
    CardVariantTypeEnum.Foil,
    CardVariantTypeEnum.Hyperspace,
    CardVariantTypeEnum.HyperspaceFoil,
    CardVariantTypeEnum.Showcase
];

/**
 * Extracts the numeric value from a card ID string
 * Example: "42" -> 42
 */
export const getCardIdValue = (cardId: string): number => {
    return parseInt(cardId, 10);
};

/**
 * Compares two card IDs for sorting
 * Returns negative if a should come before b, positive if b should come before a
 */
export const compareCardIds = (a: string, b: string): number => {
    return getCardIdValue(a) - getCardIdValue(b);
};

export const getShortCondition = (condition: SaleListItemConditionEnum): string => {
    const conditionMap: Record<SaleListItemConditionEnum, string> = {
        [SaleListItemConditionEnum.Excellent]: 'EX',
        [SaleListItemConditionEnum.Mint]: 'M',
        [SaleListItemConditionEnum.NearMint]: 'NM',
        [SaleListItemConditionEnum.LightlyPlayed]: 'LP',
        [SaleListItemConditionEnum.Good]: 'G',
        [SaleListItemConditionEnum.Played]: 'P',
        [SaleListItemConditionEnum.Poor]: 'POOR'
    };

    return conditionMap[condition] || condition;
};


