import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

export const useUser = () => {
    const { user, isAuthenticated } = useAuth();
    const [userData, setUserData] = useState(user);
    const [hasHandle, setHasHandle] = useState(!!user?.handle);

    useEffect(() => {
        setUserData(user);
        setHasHandle(!!user?.handle);
    }, [user]);

    return { user: userData, isAuthenticated, hasHandle };
};
