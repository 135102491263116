import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faSignInAlt, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { useUser } from '../../../hooks/useUser';

interface OfferBannerProps {
    onCreateOffer: () => void;
}

const OfferBanner: React.FC<OfferBannerProps> = ({ onCreateOffer }) => {
    const { isAuthenticated, hasHandle } = useUser();

    return (
        <Card className="mb-4 bg-space-dark text-space-light border-space-blue">
            <Card.Body>
                <Card.Title as="h3" className="text-space-gold">
                    {isAuthenticated
                        ? hasHandle
                            ? "Ready to Make an Offer?"
                            : "Set Your Handle to Start Trading!"
                        : "Start Your Trading Journey!"}
                </Card.Title>
                <Card.Text>
                    {isAuthenticated
                        ? hasHandle
                            ? "Want to send an offer for the cards this user is selling? Create an offer now!"
                            : "You're almost there! Set your handle to start creating offers for the cards users are selling."
                        : "Want to send offers for the cards users are selling? Log in to get started!"}
                </Card.Text>
                {isAuthenticated ? (
                    hasHandle ? (
                        <Button
                            variant="outline-space-gold"
                            onClick={onCreateOffer}
                            className="mt-2 hover-card"
                        >
                            <FontAwesomeIcon icon={faHandshake} className="me-2" />
                            Create Offer
                        </Button>
                    ) : (
                        <Link
                            to="/profile"
                            className="btn btn-outline-space-gold mt-2 hover-card"
                        >
                            <FontAwesomeIcon icon={faUserEdit} className="me-2" />
                            Set Handle
                        </Link>
                    )
                ) : (
                    <Link
                        to="/login"
                        className="btn btn-outline-space-gold mt-2 hover-card"
                    >
                        <FontAwesomeIcon icon={faSignInAlt} className="me-2" />
                        Log In
                    </Link>
                )}
            </Card.Body>
        </Card>
    );
};

export default OfferBanner;
