import React, { useState, useEffect, useRef, useCallback } from 'react';
import { OrientationTypeEnum, useGetCardQuery } from '../../graphql/generated/graphql';
import CardImage from './CardImage';

interface CardHoverData {
    id: string;
    name: string;
    images: {
        front: {
            orientation: OrientationTypeEnum;
            url?: string | null | undefined;
            id: string;
        };
    };
}

interface Position {
    top: number;
    left: number;
}

interface CardHoverProps {
    cardId: string;
    children: React.ReactNode;
}

const CardHover: React.FC<CardHoverProps> = ({ cardId, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [position, setPosition] = useState<Position>({ top: 0, left: 0 });
    const containerRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const { data, loading, error } = useGetCardQuery({
        variables: { id: cardId },
        skip: !showTooltip,
    });

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const calculatePosition = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const tooltipWidth = 300;
        const tooltipHeight = 418;
        let left = e.clientX;
        let top = e.clientY + 20;

        if (left + tooltipWidth > window.innerWidth) {
            left = window.innerWidth - tooltipWidth - 10;
        }
        if (top + tooltipHeight > window.innerHeight) {
            top = e.clientY - tooltipHeight - 10;
        }

        return { top, left };
    }, []);

    const handleMouseEnter = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        if (window.innerWidth > 768) {
            setPosition(calculatePosition(e));
            timeoutRef.current = setTimeout(() => setShowTooltip(true), 300);
        }
    }, [calculatePosition]);

    const handleMouseLeave = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setShowTooltip(false);
    }, []);


    const renderCardContent = useCallback(() => {
        if (loading) return;
        if (error) return <div>Error loading card details</div>;
        if (!data?.card) return <div>No card data available</div>;
        return <CardImageContent card={data.card} />;
    }, [loading, error, data]);

    return (
        <div
            ref={containerRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {showTooltip && (
                <div
                    className={`card-hover-tooltip visible image-only`}
                    style={{ top: `${position.top}px`, left: `${position.left}px` }}
                >
                    {renderCardContent()}
                </div>
            )}
        </div>
    );
};

const CardImageContent: React.FC<{ card: CardHoverData }> = ({ card }) => {
    const image = card.images.front;
    const isLandscape = image.orientation === OrientationTypeEnum.Landscape;

    return (
        <div className={`card-hover-image-container ${isLandscape ? 'landscape' : 'portrait'}`}>
            <CardImage
                image={image}
                name={card.name}
            />
        </div>
    );
};

export default CardHover;
