import React from 'react';
import ErrorDisplay from '../../layout/ErrorDisplay';
import LoadingSkeleton from '../../layout/Skeleton';
import WantCard from './WantCard';
import EmptyWantList from './EmptyWantList';
import { useAuth } from '../../../contexts/AuthContext';
import { CardData } from '../../../types/wantListTypes';
import { ApolloError } from '@apollo/client';
import { compareCardIds } from '../../../utils/cardUtils';

interface WantListDisplayProps {
    effectiveHandle: string | undefined;
    isOwnWantList: boolean;
    cardsData: CardData[];
    loading: boolean;
    error: ApolloError | undefined;
}

const WantListDisplay: React.FC<WantListDisplayProps> = ({ effectiveHandle, isOwnWantList, cardsData, loading, error }) => {
    const { isAuthenticated } = useAuth();

    if (loading) return <LoadingSkeleton />;
    if (error) return <ErrorDisplay message={error.message} />;

    if (cardsData.length === 0) {
        return (
            <EmptyWantList
                isOwnProfile={isOwnWantList}
                user={effectiveHandle}
                isAuthenticated={isAuthenticated}
            />
        );
    }

    return (
        <div>
            <div className="want-list-grid">
                {cardsData
                    .sort((a, b) => compareCardIds(a.id, b.id))
                    .map((card) => (
                        <WantCard
                            key={card.id}
                            wantedCard={card}
                            isOwnWantList={isOwnWantList}
                        />
                    ))}
            </div>
        </div>
    );
};

export default WantListDisplay;
