import { useState, useCallback } from "react";
import { ShareInput, ShareResponse, SharedUser } from '../types/sharingTypes';

interface UseShareSettingsProps {
    getShareSettings: (id?: string) => Promise<{
        data: {
            sharedWithAll: boolean;
            sharedWithUsers: SharedUser[];
        }
    }>;
    updateSharing: (variables: { input: ShareInput }) => Promise<{
        data: ShareResponse
    }>;
    id?: string;
    idFieldName: string;
}

interface UseShareSettingsReturn {
    showShareModal: boolean;
    setShowShareModal: (show: boolean) => void;
    shareError: string | null;
    isSharingLoading: boolean;
    shareSettings: {
        sharedWithAll: boolean;
        sharedWithUsers: SharedUser[];
    };
    handleShowShareModal: () => Promise<void>;
    handleSaveSharing: (shareWithAll: boolean, emails: string[]) => Promise<void>;
}

export function useShareSettings({
    getShareSettings,
    updateSharing,
    id,
    idFieldName
}: UseShareSettingsProps): UseShareSettingsReturn {
    const [showShareModal, setShowShareModal] = useState(false);
    const [shareError, setShareError] = useState<string | null>(null);
    const [isSharingLoading, setIsSharingLoading] = useState(false);
    const [shareSettings, setShareSettings] = useState<{
        sharedWithAll: boolean;
        sharedWithUsers: SharedUser[];
    }>({
        sharedWithAll: false,
        sharedWithUsers: []
    });

    const handleShowShareModal = useCallback(async () => {
        setIsSharingLoading(true);
        setShareError(null);

        try {
            const { data } = await getShareSettings(id);
            setShareSettings({
                sharedWithAll: data?.sharedWithAll || false,
                sharedWithUsers: data?.sharedWithUsers || []
            });
            setShowShareModal(true);
        } catch (err) {
            console.error('Error fetching share settings:', err);
            setShareError('Failed to load sharing settings');
        } finally {
            setIsSharingLoading(false);
        }
        setShowShareModal(true);
    }, [id, getShareSettings]);

    const handleSaveSharing = async (shareWithAll: boolean, emails: string[]) => {
        setIsSharingLoading(true);
        try {
            const shareTargets = emails.map(email => ({ email }));
            const result = await updateSharing({
                input: {
                    [idFieldName]: id,
                    shareWithAll,
                    shareWith: shareTargets
                }
            });

            const success = result.data.success;

            if (success) {
                setShowShareModal(false);
                setShareError(null);
            } else {
                const errorMessage = result.data?.errors?.[0]?.message;
                setShareError(errorMessage || 'Failed to update sharing settings');
            }
        } catch (err) {
            console.error('Error updating sharing:', err);
            setShareError('An unexpected error occurred');
        } finally {
            setIsSharingLoading(false);
        }
    };

    return {
        showShareModal,
        setShowShareModal,
        shareError,
        isSharingLoading,
        shareSettings,
        handleShowShareModal,
        handleSaveSharing
    };
}
