import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import {
    CardVariantTypeEnum,
    useUpdateWantListVariantCountMutation
} from '../../../graphql/generated/graphql';
import { CardData } from '../../../types/editWantListTypes';
import MissingBadge from '../shared/MissingBadge';

interface CardVariantCellProps {
    card: CardData;
    variantType: CardVariantTypeEnum;
    wantListId: string;
    showMissingOnly: boolean;
    onItemUpdated: () => void;
}

const CardVariantCell: React.FC<CardVariantCellProps> = ({
    card,
    variantType,
    wantListId,
    showMissingOnly,
    onItemUpdated
}) => {
    const [updateWantListVariantCount] = useUpdateWantListVariantCountMutation();
    const cardVariant = card.variants[variantType];
    const initialWantCount = cardVariant ? cardVariant.wantCount || 0 : 0;
    const [localWantCount, setLocalWantCount] = useState(initialWantCount);
    const missingCount = cardVariant ? cardVariant.missingCount : 0;
    const [addAll, setAddAll] = useState(false);

    if (!cardVariant || (showMissingOnly && missingCount === 0)) {
        return <td className="text-center">-</td>;
    }

    if (localWantCount > 0) {
        return <td className="text-center text-space-muted">Already added</td>;
    }

    const handleAddToWantList = async (quantity: number) => {
        const newCount = Math.max(0, localWantCount + quantity);
        try {
            const variantId = cardVariant.id;
            if (!variantId) throw new Error('Variant ID not found');

            const response = await updateWantListVariantCount({
                variables: {
                    wantListId,
                    cardVariantId: variantId,
                    quantity: newCount
                }
            });

            if (newCount === 0 || !response.data?.updateWantListVariantCount?.wantListItem) {
                setLocalWantCount(0);
            } else {
                setLocalWantCount(response.data.updateWantListVariantCount.wantListItem.quantity);
            }
            onItemUpdated();
        } catch (error) {
            console.error('Failed to update want list:', error);
        }
    };

    return (
        <td className="text-center">
            <div className="inventory-details d-flex align-items-center justify-content-center">
                <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => {
                        if (missingCount > 0) {
                            setAddAll(!addAll);
                        } else {
                            handleAddToWantList(1);
                        }
                    }}
                    onDoubleClick={() => missingCount > 0 && handleAddToWantList(addAll ? missingCount : 1)}
                    title={missingCount > 0 ? `Click to toggle amount, double click to add ${addAll ? 'all' : 'one'}` : 'Add to want list'}
                >
                    Add {missingCount > 0 && (addAll ? missingCount : '1')} {missingCount > 0 && <MissingBadge count={missingCount} />}
                </Button>
            </div>
        </td>
    );
}

export default CardVariantCell;
