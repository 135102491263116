import React from 'react';
import { CardVariantTypeEnum } from '../../../graphql/generated/graphql';
import CardHover from '../../card/CardHover';
import CardVariantCell from './CardVariantCell';
import { CardData } from '../../../types/editWantListTypes';

interface CardTableRowProps {
    card: CardData;
    variantColumns: CardVariantTypeEnum[];
    wantListId: string;
    showMissingOnly: boolean;
    onItemUpdated: () => void;
}

const CardTableRow: React.FC<CardTableRowProps> = ({
    card,
    variantColumns,
    wantListId,
    showMissingOnly,
    onItemUpdated
}) => {
    const isCardMissing = Object.values(card.variants).some(variant => variant.missingCount > 0);

    return (
        <tr className={isCardMissing ? 'missing-card' : ''}>
            <td className="text-center">{card.standardCardNumber}</td>
            <td>
                <CardHover cardId={card.id}>
                    <span className="space-table-link">{card.name}</span>
                </CardHover>
            </td>
            {variantColumns.map((variantType) => (
                <CardVariantCell
                    key={variantType}
                    card={card}
                    variantType={variantType}
                    wantListId={wantListId}
                    showMissingOnly={showMissingOnly}
                    onItemUpdated={onItemUpdated}
                />
            ))}
        </tr>
    );
};

export default CardTableRow;
