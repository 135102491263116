import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import {
    CardVariantTypeEnum,
    SaleListItemConditionEnum,
    useUpsertSaleListVariantMutation
} from '../../../graphql/generated/graphql';
import { CardData } from '../../../types/editSaleListTypes';

interface CardVariantCellProps {
    card: CardData;
    variantType: CardVariantTypeEnum;
    saleListId: string;
    showExtrasOnly: boolean;
    onItemUpdated: () => void;
}

const MIN_PRICE_CENTS = 1; // 1 cent minimum

const CardVariantCell: React.FC<CardVariantCellProps> = ({
    card,
    variantType,
    saleListId,
    showExtrasOnly,
    onItemUpdated
}) => {
    const [updateSaleListVariantCount] = useUpsertSaleListVariantMutation();
    const [showModal, setShowModal] = useState(false);
    const [price, setPrice] = useState('');
    const [condition, setCondition] = useState(SaleListItemConditionEnum.NearMint);
    const [localSaleCount, setLocalSaleCount] = useState(card.variants[variantType]?.saleCount || 0);
    const [priceError, setPriceError] = useState('');

    const cardVariant = card.variants[variantType];

    if (!cardVariant || (showExtrasOnly && cardVariant.extraCount === 0)) {
        return <td className="text-center">-</td>;
    }

    if (localSaleCount > 0) {
        return <td className="text-center text-space-muted">Already added</td>;
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const priceFloat = parseFloat(price);
        const priceInCents = Math.round(priceFloat * 100);

        if (isNaN(priceFloat) || priceInCents < MIN_PRICE_CENTS) {
            setPriceError('Price must be at least $0.01');
            return;
        }

        try {
            const response = await updateSaleListVariantCount({
                variables: {
                    saleListId,
                    cardVariantId: cardVariant.id,
                    quantity: 1,
                    priceInCents,
                    condition
                }
            });

            if (response.data?.upsertSaleListVariant?.saleListItem) {
                setLocalSaleCount(1);
                onItemUpdated();
                setShowModal(false);
            }
        } catch (error) {
            console.error('Failed to update sale list:', error);
        }
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPriceError('');
        // Allow empty string, digits, and up to one decimal point with up to 2 decimal places
        if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
            setPrice(value);
        }
    };

    return (
        <td className="text-center">
            <Button
                variant="outline-success"
                size="sm"
                onClick={() => {
                    setShowModal(true);
                    setPrice('');
                    setPriceError('');
                }}
            >
                Add
            </Button>

            <Modal show={showModal} onHide={() => setShowModal(false)} className="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Add Card to Sale List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label className="text-space-white">Price ($)</Form.Label>
                            <Form.Control
                                type="text"
                                value={price}
                                onChange={handlePriceChange}
                                placeholder="0.00"
                                required
                                isInvalid={!!priceError}
                                className="space-input"
                            />
                            <Form.Control.Feedback type="invalid">
                                {priceError}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label className="text-space-white">Condition</Form.Label>
                            <Form.Select
                                value={condition}
                                onChange={(e) => setCondition(e.target.value as SaleListItemConditionEnum)}
                                className="bg-space-dark text-space-white border-space-blue"
                            >
                                {Object.values(SaleListItemConditionEnum).map((condition) => (
                                    <option key={condition} value={condition}>
                                        {condition.replace('_', ' ')}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <div className="d-flex justify-content-end gap-2">
                            <Button variant="outline-space-blue" onClick={() => setShowModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="outline-space-gold" type="submit">
                                Add to Sale List
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </td>
    );
};

export default CardVariantCell;
