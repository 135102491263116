import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { AspectTypeEnum, CardVariantTypeEnum } from '../../../graphql/generated/graphql';
import EditableWantCard from './EditableWantCard';
import { compareCardIds } from '../../../utils/cardUtils';

interface WantListTableItem {
    id: string;
    wantListId: string;
    quantity: number;
    cardVariant: {
        id: string;
        cardNumber: string;
        variantType: CardVariantTypeEnum;
        card: {
            id: string;
            name: string;
            standardFullCardNumber?: string | null;
            aspects: AspectTypeEnum[];
        };
    };
}

interface WantListItemsTableProps {
    items: Array<WantListTableItem>;
    wantListId: string;
    onItemUpdated?: () => void;
}

const WantListItemsTable: React.FC<WantListItemsTableProps> = ({
    items,
    wantListId,
    onItemUpdated
}) => {
    if (items.length === 0) {
        return (
            <div className="text-space-white text-center p-4">
                No cards in want list yet.
            </div>
        );
    }

    // Group items by card ID
    const groupedCards = items.reduce((acc, item) => {
        const cardId = item.cardVariant.card.id;
        if (!acc.has(cardId)) {
            acc.set(cardId, {
                id: cardId,
                name: item.cardVariant.card.name,
                standardFullCardNumber: item.cardVariant.card.standardFullCardNumber,
                variants: {},
                aspects: item.cardVariant.card.aspects
            });
        }

        const cardData = acc.get(cardId)!;
        cardData.variants[item.cardVariant.variantType] = {
            id: item.cardVariant.id,
            wantCount: item.quantity,
        };

        return acc;
    }, new Map());

    return (
        <Row xs={1} md={2} lg={3} className="g-4">
            {Array.from(groupedCards.values())
                .sort((a, b) => compareCardIds(a.id, b.id))
                .map((card) => (
                    <Col key={card.id}>
                        <EditableWantCard
                            wantedCard={card}
                            wantListId={wantListId}
                            onItemUpdated={onItemUpdated || (() => { })}
                        />
                    </Col>
                ))}
        </Row>
    );
};

export default WantListItemsTable;
