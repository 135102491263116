import React, { useState } from 'react';
import { Form, Button, Alert, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface HandleSectionProps {
    handle: string;
    savedHandle: string;
    onHandleChange: (newHandle: string) => void;
    onHandleSave: () => Promise<void>;
    error: string;
    isLoading: boolean;
}

const HandleSection: React.FC<HandleSectionProps> = ({
    handle,
    savedHandle,
    onHandleChange,
    onHandleSave,
    error,
    isLoading
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [validationError, setValidationError] = useState<string>('');


    const isHandleSet = Boolean(savedHandle);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setValidationError('');

        if (!handle) {
            setValidationError('Handle cannot be empty.');
            return;
        }

        // Check for valid format (lowercase letters, numbers, dash, underscore)
        const handleRegex = /^[a-z0-9_-]+$/;
        if (!handleRegex.test(handle)) {
            setValidationError('Handle must contain only lowercase letters, numbers, dash, or underscore.');
            return;
        }

        setShowConfirmModal(true);
    };

    const handleConfirmSave = async () => {
        setShowConfirmModal(false);
        await onHandleSave();
        if (!error) {
            setIsEditing(false);
        }
    };

    const renderHandleDisplay = () => (
        <div className="d-flex align-items-center">
            {isHandleSet ? (
                <p className="fs-5 text-primary mb-0">{savedHandle}</p>
            ) : (
                <Button
                    variant="outline-space-blue"
                    className="px-3"
                    onClick={() => setIsEditing(true)}
                >
                    Set Handle
                </Button>
            )}
        </div>
    );

    const renderHandleInput = () => (
        <div className="mt-2">
            <Form.Control
                type="text"
                value={handle}
                onChange={(e) => onHandleChange(e.target.value.toLowerCase())}
                placeholder="Enter handle"
                className="space-input bg-space-dark text-space-white"
            />
            {(validationError || error) && (
                <Form.Text className="text-danger">
                    {validationError || error}
                </Form.Text>
            )}
        </div>
    );

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <div className="d-flex align-items-center mb-2">
                        <Form.Label className="fw-bold mb-0 me-2">Handle</Form.Label>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="text-space-blue"
                            title="Your handle is used to identify you across the platform"
                        />
                    </div>
                    {!isHandleSet && (
                        <Alert variant="info" className="mb-3">
                            <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                            You need to set a handle to share your collections. Choose carefully as it can only be set once!
                        </Alert>
                    )}
                    {isEditing ? renderHandleInput() : renderHandleDisplay()}
                </Form.Group>
                {isEditing && !isHandleSet && (
                    <div>
                        <Button
                            variant="outline-space-blue"
                            type="submit"
                            className="me-2"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Saving...' : 'Save Handle'}
                        </Button>
                        <Button
                            variant="outline-space-light-red"
                            onClick={() => {
                                setIsEditing(false);
                                onHandleChange('');
                            }}
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                    </div>
                )}
            </Form>

            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                className="custom-modal"
            >
                <Modal.Header closeButton className="bg-space-darker border-space">
                    <Modal.Title className="text-space-white">Confirm Handle Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-space-darker text-space-white">
                    <p>You are about to set your handle to: <strong>{handle}</strong></p>
                    <p className="text-warning mb-0">Please note that your handle can only be set once and cannot be changed later. Are you sure you want to proceed?</p>
                </Modal.Body>
                <Modal.Footer className="bg-space-darker border-space">
                    <Button variant="outline-space-blue" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="outline-space-gold" onClick={handleConfirmSave}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default HandleSection;
