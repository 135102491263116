import React, { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Container, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faGauge, faGear } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthContext';
import { RoleEnum } from '../../graphql/generated/graphql';

const AdminLayout: React.FC = () => {
    const { isAuthenticated, user } = useAuth();
    const location = useLocation();
    const [isNavOpen, setIsNavOpen] = useState(false);

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!user?.roles?.includes(RoleEnum.Admin)) {
        return <Navigate to="/" replace />;
    }

    const NavLinks = () => (
        <>
            <Nav.Link
                as={Link}
                to="/admin"
                className={location.pathname === '/admin' ? 'active' : ''}
                onClick={() => setIsNavOpen(false)}
            >
                <FontAwesomeIcon icon={faGauge} className="me-2" />
                Dashboard
            </Nav.Link>
            <Nav.Link
                as={Link}
                to="/admin/users"
                className={location.pathname === '/admin/users' ? 'active' : ''}
                onClick={() => setIsNavOpen(false)}
            >
                <FontAwesomeIcon icon={faUsers} className="me-2" />
                Users
            </Nav.Link>
        </>
    );

    return (
        <div className="admin-wrapper">
            {/* Mobile Header */}
            <div className="admin-header d-sm-none">
                <Button
                    variant="outline-space-blue"
                    onClick={() => setIsNavOpen(!isNavOpen)}
                    className="admin-nav-toggle"
                >
                    <FontAwesomeIcon icon={faGear} /> {/* or faShieldHalved, faScrewdriverWrench, faToolbox */}
                </Button>
                <span className="text-space-white fw-semibold">Admin Panel</span>
            </div>

            {/* Mobile Navigation */}
            <div className="d-sm-none">
                {isNavOpen && (
                    <Nav className="admin-nav-mobile flex-column bg-space-black">
                        <NavLinks />
                    </Nav>
                )}
            </div>

            {/* Desktop Sidebar */}
            <nav className="admin-sidebar d-none d-sm-block">
                <Nav className="admin-nav flex-column">
                    <NavLinks />
                </Nav>
            </nav>

            {/* Main Content */}
            <main className="admin-main">
                <Container fluid>
                    <Outlet />
                </Container>
            </main>
        </div>
    );
};

export default AdminLayout;
