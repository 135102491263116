import React from 'react';
import { Button, Container, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

interface ErrorDisplayProps {
    message: string;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ message }) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Container className="mt-5">
            <Card className="shadow-sm">
                <Card.Body className="text-center">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="3x" className="mb-3 text-danger" />
                    <Card.Title as="h2">Oops! Something went wrong</Card.Title>
                    <Card.Text className="mb-4">{message}</Card.Text>
                    <Button variant="outline-primary" onClick={handleGoBack}>
                        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                        Go Back
                    </Button>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default ErrorDisplay;
