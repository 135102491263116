import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InventoryEdit from './inventory_edit/InventoryEdit';
import { VariantColumn } from '../collection/InventoryCardTable';
import { UserCardInventory, CollectionItemInventory } from '../../graphql/generated/graphql';
import CardHover from '../card/CardHover';
import { UpdateCollectionVariantInventoryMutation } from '../../graphql/generated/graphql';
import MissingBadge from './shared/MissingBadge';


interface InventoryCardTableRowProps {
    cardInventory: UserCardInventory;
    isEditMode: boolean;
    isOwner: boolean;
    onInventoryUpdate: (variantId: number, field: 'inventory' | 'missing', newValue: number | null, oldValue: number | null) => Promise<UpdateCollectionVariantInventoryMutation>;
    onCardSelect?: (card: UserCardInventory) => void;
    variantColumns: VariantColumn[];
    className?: string;
}

export const InventoryCardTableRow: React.FC<InventoryCardTableRowProps> = ({
    cardInventory,
    isEditMode,
    isOwner,
    onInventoryUpdate,
    onCardSelect,
    variantColumns,
    className
}) => {
    const handleInventoryChange = async (
        variantId: number,
        field: "inventory" | "missing",
        newValue: number | null,
        oldValue: number | null
    ): Promise<UpdateCollectionVariantInventoryMutation> => {
        return onInventoryUpdate(variantId, field, newValue, oldValue);
    };

    const handleCardClick = () => {
        if (!isEditMode && onCardSelect) {
            onCardSelect(cardInventory);
        }
    };

    const renderInventoryDetails = (inventoryDetails: CollectionItemInventory | null) => {
        if (!inventoryDetails) return '-';

        if (isOwner && isEditMode) {
            return (
                <div className="inventory-edit">
                    <InventoryEdit
                        variantId={inventoryDetails.variantId}
                        initialInventory={inventoryDetails.inventory}
                        initialMissing={inventoryDetails.missing}
                        onInventoryUpdate={handleInventoryChange}
                    />
                </div>
            );
        } else {
            return (
                <div className="inventory-details d-flex align-items-center">
                    <span className="inventory-count">
                        {inventoryDetails.inventory}
                    </span>
                    {isOwner && (inventoryDetails.missing.count > 0) && (
                        <>
                            <div className="inventory-divider mx-2"></div>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`missing-${inventoryDetails.variantId}`}>Missing</Tooltip>}
                            >
                                <MissingBadge
                                    count={inventoryDetails.missing.count}
                                />
                            </OverlayTrigger>
                        </>
                    )}
                </div>
            );
        }
    };

    return (
        <tr
            onClick={isEditMode ? undefined : handleCardClick}
            className={`${className} ${isEditMode ? 'no-hover' : ''}`}
        >
            <td>{cardInventory.cardDetails.cardNumber}</td>
            <td>
                <CardHover cardId={cardInventory.id}>
                    <span>{cardInventory.cardDetails.name}</span>
                </CardHover>
            </td>
            {variantColumns.map(({ type, countKey }) => {
                const inventoryDetails = cardInventory[countKey as keyof UserCardInventory] as CollectionItemInventory | null;
                return (
                    <td key={type}>
                        {renderInventoryDetails(inventoryDetails)}
                    </td>
                );
            })}
        </tr>
    );
};
