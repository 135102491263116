import React from 'react';
import CardHover from '../../card/CardHover';
import { CardVariantTypeEnum } from '../../../graphql/generated/graphql';
import { getCardVariantTypeDisplay, getShortCondition } from '../../../utils/cardUtils';
import { CardData } from '../../../types/saleListTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

interface SaleCardProps {
    saleCard: CardData;
    isOwnSaleList: boolean;
}

const SaleCard: React.FC<SaleCardProps> = ({ saleCard }) => {
    const getAspectClasses = (aspects: string[] | null | undefined): string => {
        if (!aspects || aspects.length === 0) return 'aspect-none';
        const priorityAspects = aspects.filter(aspect => !['villainy', 'heroism'].includes(aspect.toLowerCase()));
        if (priorityAspects.length > 0) {
            return `aspect-${priorityAspects[0].toLowerCase()}`;
        }
        return `aspect-${aspects[0].toLowerCase()}`;
    };

    const formatPrice = (priceInCents: number): string => {
        return (priceInCents / 100).toFixed(2);
    };

    return (
        <CardHover cardId={saleCard.id}>
            <div className={`sale-card ${getAspectClasses(saleCard.aspects)}`}>
                <div className="sale-card-header">
                    <span className="sale-card-number">{saleCard.standardFullCardNumber}</span>
                    <h3 className="sale-card-name">{saleCard.name}</h3>
                </div>
                <div className="sale-card-variants">
                    {Object.entries(saleCard.variants).map(([variantType, variantData]) => (
                        <div key={variantData.id} className="sale-card-variant mb-2">
                            <div className="variant-header">
                                <span className="variant-type">{getCardVariantTypeDisplay(variantType as CardVariantTypeEnum)}</span>
                            </div>
                            <div className="sale-details">
                                <div className="price-section">
                                    <FontAwesomeIcon icon={faDollarSign} className="price-icon" />
                                    <span className="price-amount">{formatPrice(variantData.priceInCents)}</span>
                                </div>
                                <div className="details-section">
                                    <span className="quantity" title="Quantity">{variantData.saleCount}x</span>
                                    <span className="condition" title={variantData.condition}>{getShortCondition(variantData.condition)}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </CardHover>
    );
};

export default SaleCard;
