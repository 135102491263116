import React from 'react';
import Select from 'react-select';

interface RarityFilterProps {
    rarities: string[];
    selected: string[];
    onChange: (selected: string[]) => void;
    disabled?: boolean;
}

const RarityFilter: React.FC<RarityFilterProps> = ({ rarities, selected, onChange, disabled }) => {
    const options = rarities.map(rarity => ({ value: rarity, label: rarity }));
    const value = selected.map(rarity => ({ value: rarity, label: rarity }));

    return (
        <div className="card-filters__section">
            <label className="card-filters__section-title">Rarity</label>
            <Select
                isMulti
                options={options}
                value={value}
                onChange={(selected) => onChange(selected ? selected.map(option => option.value) : [])}
                placeholder="Filter by rarity..."
                className="card-filters__select"
                classNamePrefix="card-filters__select"
                isDisabled={disabled}
            />
        </div>
    );
};

export default RarityFilter;
