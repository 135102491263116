import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { useGetCardSetsQuery } from '../../graphql/generated/graphql';
import ErrorBoundary from '../layout/ErrorBoundary';
import Skeleton from '../layout/Skeleton';
import { getFormattedImageUrl } from '../../utils/imageUtils';

const CardSets: React.FC = () => {
    const { loading, error, data } = useGetCardSetsQuery();

    const renderCardSetSkeleton = () => (
        <Col lg={4} className="mb-4">
            <Card className="h-100 shadow-sm hover-card card-set-item">
                <Skeleton height="200px" className="card-img-top" />
                <Card.Body className="card-content">
                    <Skeleton height="24px" width="70%" className="mb-2" />
                    <Skeleton height="60px" className="mb-3" />
                    <Skeleton height="38px" width="50%" />
                </Card.Body>
            </Card>
        </Col>
    );

    if (error) return <p>Error: {error.message}</p>;

    return (
        <ErrorBoundary>
            <Container>
                <Row className="mt-5 mb-4">
                    <Col>
                        <h2 className="section-title">
                            <FontAwesomeIcon icon={faLayerGroup} className="me-2" />
                            Explore Card Sets
                        </h2>
                    </Col>
                </Row>

                {loading ? (
                    <Row>
                        {[...Array(6)].map(() => renderCardSetSkeleton())}
                    </Row>
                ) : (
                    <Row>
                        {data?.cardSets?.nodes?.map((set) => (
                            <Col key={set?.id} lg={4} className="mb-4">
                                <Card className="h-100 shadow-sm hover-card card-set-item gradient-card">
                                    <Card.Img
                                        variant="top"
                                        src={getFormattedImageUrl(set?.imageUrl, 300)}
                                        alt={set?.name}
                                        className="card-img-fixed-height"
                                    />
                                    <Card.Body className="d-flex flex-column">
                                        <Card.Title>{set?.name}</Card.Title>
                                        <Card.Text className="flex-grow-1">{set?.description?.slice(0, 100)}</Card.Text>
                                        <Link to={`/card-sets/${set?.code || set?.id}`} className="btn btn-outline-primary mt-auto">
                                            <FontAwesomeIcon icon={faEye} className="me-2" /> View Set
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}
            </Container>
        </ErrorBoundary>
    );
}

export default CardSets;
