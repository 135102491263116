import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ContactUs: React.FC = () => {
    const email = 'swudash.feedback@gmail.com';

    const handleEmailClick = () => {
        window.location.href = `mailto:${email}`;
    };

    return (
        <Container className="mt-4 bg-space-dark p-4 rounded">
            <h1 className="text-space-gold mb-4">Contact Us</h1>
            <div className="text-space-white mb-4">
                <p>We&apos;d love to hear from you! Whether you have suggestions, found a bug, or just want to say hello, please don&apos;t hesitate to reach out.</p>
                <p>You can contact us at:</p>
            </div>
            <Button
                variant="outline-space-blue"
                onClick={handleEmailClick}
                className="d-inline-flex align-items-center"
            >
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                {email}
            </Button>
        </Container>
    );
};

export default ContactUs;
