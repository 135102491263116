export const getFormattedImageUrl = (imageUrl: string | null | undefined, width: number): string => {
    if (!imageUrl) return '/images/card_sets/default.jpg';

    // Check if the URL already has an extension
    const hasExtension = /\.[^/.]+$/.test(imageUrl);

    if (!hasExtension) {
        return `${imageUrl}/width=${width}`
    }

    return imageUrl;
};
